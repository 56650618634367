import {
  AdminAuthorizationRoles,
  AdminOrRootAuthorizationRoles,
  AdminOrTeacherAuthorizationRoles,
  AllAuthorizationRoles,
  EmployeeAuthorizationRoles,
  RootAdminRoles,
  RootRoles,
  StudyoRoles
} from '@shared/models/types';
import { Navigate, Outlet, createBrowserRouter } from 'react-router';
import App from './App.tsx';
import {
  AutomatedImportDetailsScreen,
  BlackbaudSkyAuthorizationRedirectScreen,
  CreateSchoolScreen,
  Dashboard,
  EditableAccounts,
  ExternalAccountsScreen,
  ExternalAssociationsScreen,
  Features,
  GlobalSettings,
  ImportDataDetailsScreen,
  ImportSessionDetailsScreen,
  ImportSessionSourceDataScreen,
  ImportSessionTransformationDetailsScreen,
  ImportSessionsScreen,
  Landing,
  Login,
  Logout,
  ManageLayout,
  MetricsLayout,
  MicrosoftTeamsAuthorizationRedirectScreen,
  NoSchoolConfigurations,
  NotFound,
  OnboardingProcessesScreen,
  OnboardingStepScreen,
  SchoolCalendarScreen,
  SchoolConfigurations,
  SchoolYearConfigurationScreen,
  SectionDetails,
  Sections,
  StudentDetails,
  Students,
  TeacherDetails,
  Teachers,
  Users,
  Workload,
  WorkloadEmbedded
} from './views';
import { InsightsRoute } from './views/InsightsRoute.tsx';
import { AuthenticatedLayout } from './views/authenticated/AuthenticatedLayout.tsx';
import { OnboardingCommentsDashboard } from './views/authenticated/OnboardingCommentsDashboard.tsx';
import { OnboardingDashboard } from './views/authenticated/OnboardingDashboard.tsx';

export const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: 'login',
        element: (
          <InsightsRoute>
            <Login sx={{ width: '100%', height: '100%' }} />{' '}
          </InsightsRoute>
        )
      },
      {
        path: 'logout',
        element: (
          <InsightsRoute>
            <Logout sx={{ width: '100%', height: '100%' }} />{' '}
          </InsightsRoute>
        )
      },
      {
        path: 'configs/:configId/embedded/workload/:teacherId',
        element: (
          <InsightsRoute authenticatedRoute>
            <WorkloadEmbedded sx={{ width: '100%', height: '100%' }} />
          </InsightsRoute>
        )
      },
      {
        path: '',
        element: (
          <InsightsRoute authenticatedRoute>
            <Landing />
          </InsightsRoute>
        )
      },
      {
        path: '*',
        element: (
          <InsightsRoute authenticatedRoute>
            <AuthenticatedLayout sx={{ width: '100%', height: '100%' }} />
          </InsightsRoute>
        ),
        children: [
          {
            path: 'configs',
            children: [
              {
                path: ':configId',
                children: [
                  { path: '', element: <Navigate to="metrics/dashboard" replace /> },
                  {
                    path: 'metrics',
                    element: (
                      <InsightsRoute authenticatedRoute allowedRoles={AllAuthorizationRoles}>
                        <MetricsLayout sx={{ height: '100%', width: '100%' }} />
                      </InsightsRoute>
                    ),
                    children: [
                      {
                        path: 'dashboard',
                        element: (
                          <InsightsRoute hasSidenavBar>
                            <Dashboard sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'workload',
                        element: (
                          <InsightsRoute hasSidenavBar>
                            <Workload sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'teachers',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          { path: ':teacherId', element: <TeacherDetails sx={{ height: '100%', width: '100%' }} /> },
                          { path: '', element: <Teachers sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      {
                        path: 'students',
                        element: (
                          <InsightsRoute authenticatedRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          { path: ':studentId', element: <StudentDetails sx={{ height: '100%', width: '100%' }} /> },
                          { path: '', element: <Students sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      {
                        path: 'sections',
                        element: (
                          <InsightsRoute authenticatedRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          { path: ':sectionId', element: <SectionDetails sx={{ height: '100%', width: '100%' }} /> },
                          { path: '', element: <Sections sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      { path: '', element: <Navigate to="dashboard" replace /> },
                      {
                        path: '*',
                        element: (
                          <InsightsRoute>
                            <NotFound sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      }
                    ]
                  },
                  {
                    path: 'manage',
                    element: (
                      <InsightsRoute authenticatedRoute hasSidenavBar>
                        <ManageLayout sx={{ height: '100%', width: '100%' }} />
                      </InsightsRoute>
                    ),
                    children: [
                      {
                        path: 'general',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <SchoolYearConfigurationScreen sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'onboarding',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          {
                            path: ':processName',
                            children: [
                              {
                                path: ':templateName',
                                element: <OnboardingStepScreen sx={{ height: '100%', width: '100%' }} />
                              },
                              {
                                path: '',
                                // For now, schools can't have more than one process, so we reuse the processes screen.
                                element: <OnboardingProcessesScreen sx={{ height: '100%', width: '100%' }} />
                              }
                            ]
                          },
                          { path: '', element: <OnboardingProcessesScreen sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      {
                        path: 'sections',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <Sections sx={{ height: '100%', width: '100%' }} canEdit />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'teachers',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <EditableAccounts sx={{ height: '100%', width: '100%' }} kind="teachers" />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'students',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <EditableAccounts sx={{ height: '100%', width: '100%' }} kind="students" />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'parents',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <EditableAccounts sx={{ height: '100%', width: '100%' }} kind="parents" />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'staff',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={EmployeeAuthorizationRoles}>
                            <EditableAccounts sx={{ height: '100%', width: '100%' }} kind="staff" />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'calendar',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={AdminOrRootAuthorizationRoles}>
                            <SchoolCalendarScreen sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      },
                      {
                        path: 'externalaccounts',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={AdminOrTeacherAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          {
                            path: 'associations/:externalAccountId',
                            element: <ExternalAssociationsScreen sx={{ height: '100%', width: '100%' }} />
                          },
                          { path: '', element: <ExternalAccountsScreen sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      {
                        path: 'import',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={AdminAuthorizationRoles}>
                            <Outlet />
                          </InsightsRoute>
                        ),
                        children: [
                          {
                            path: ':sessionId',
                            children: [
                              {
                                path: 'source/:label',
                                element: <ImportSessionSourceDataScreen sx={{ height: '100%', width: '100%' }} />
                              },
                              {
                                path: 'data/:label',
                                element: <ImportDataDetailsScreen sx={{ height: '100%', width: '100%' }} />
                              },
                              {
                                path: 'transformations/:label',
                                element: (
                                  <InsightsRoute allowedRoles={RootAdminRoles}>
                                    <ImportSessionTransformationDetailsScreen sx={{ height: '100%', width: '100%' }} />
                                  </InsightsRoute>
                                )
                              },
                              {
                                path: 'automated/:automatedImportId',
                                children: [
                                  {
                                    path: 'result/:resultIndex',
                                    element: <AutomatedImportDetailsScreen sx={{ height: '100%', width: '100%' }} />
                                  },
                                  {
                                    path: '',
                                    element: <AutomatedImportDetailsScreen sx={{ height: '100%', width: '100%' }} />
                                  }
                                ]
                              },
                              {
                                path: '',
                                element: <ImportSessionDetailsScreen sx={{ height: '100%', width: '100%' }} />
                              }
                            ]
                          },
                          { path: '', element: <ImportSessionsScreen sx={{ height: '100%', width: '100%' }} /> }
                        ]
                      },
                      {
                        path: 'features',
                        element: (
                          <InsightsRoute hasSidenavBar allowedRoles={RootAdminRoles}>
                            <Features sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      },
                      { path: '', element: <Navigate to="general" replace /> },
                      {
                        path: '*',
                        element: (
                          <InsightsRoute>
                            <NotFound sx={{ height: '100%', width: '100%' }} />
                          </InsightsRoute>
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: '',
                element: (
                  <InsightsRoute allowedRoles={RootRoles} allowRootAdmins authenticatedRoute>
                    <SchoolConfigurations sx={{ height: '100%', width: '100%' }} />
                  </InsightsRoute>
                )
              }
            ]
          },
          {
            path: 'new',
            element: (
              <InsightsRoute allowedRoles={RootAdminRoles}>
                <CreateSchoolScreen sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            ),
            children: [
              {
                path: ':sourceConfigId'
              }
            ]
          },
          {
            path: 'onboarding',
            element: (
              <InsightsRoute allowedRoles={StudyoRoles}>
                <OnboardingDashboard sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'onboardingcomments',
            element: (
              <InsightsRoute allowedRoles={StudyoRoles}>
                <OnboardingCommentsDashboard sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'users',
            element: (
              <InsightsRoute allowedRoles={RootAdminRoles}>
                <Users sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'settings',
            element: (
              <InsightsRoute allowedRoles={RootAdminRoles}>
                <GlobalSettings sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'teamsconsent',
            element: (
              <InsightsRoute allowedRoles={EmployeeAuthorizationRoles}>
                <MicrosoftTeamsAuthorizationRedirectScreen sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'blackbaud/callback',
            element: (
              <InsightsRoute allowedRoles={EmployeeAuthorizationRoles}>
                <BlackbaudSkyAuthorizationRedirectScreen sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: 'noschoolconfigurations',
            element: (
              <InsightsRoute allowedRoles={AllAuthorizationRoles}>
                <NoSchoolConfigurations sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          },
          {
            path: '*',
            element: (
              <InsightsRoute>
                <NotFound sx={{ height: '100%', width: '100%' }} />
              </InsightsRoute>
            )
          }
        ]
      }
    ]
  }
]);
