import { SectionUtils } from '@insights/utils';
import { AccountEditionViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { SectionName } from '../SectionName';

const TableStateKey = 'AccountSectionList';

export interface AccountSectionListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  account: AccountEditionViewModel;
}

export const AccountSectionList = observer((props: AccountSectionListProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, account } = props;
  const tableStateKey = `${TableStateKey}-${props.account.editableAccount.role}`;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  const table = useInsightsTable(
    tableStateKey,
    account.selectedSections,
    '',
    () => [
      {
        header: strings.section,
        accessorFn: (row) =>
          accessorForSearch([
            row.section?.title,
            row.section?.importId,
            AccountUtils.getDisplayLastFirstName(account.teachersById[row.section?.defaultTeacherId ?? ''], '')
          ]),
        id: 'title',
        Cell: ({ row }) => (
          <SectionName
            title={SectionUtils.formatTitle(row.original.section, strings.unknownSection)}
            color={row.original.section?.color}
            subInformation1={row.original.section?.importId}
            subInformation2={AccountUtils.getDisplayLastFirstName(
              account.teachersById[row.original.section?.defaultTeacherId ?? ''],
              ''
            )}
          />
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <DeleteIcon fontSize="small" />,
          tooltip: row.original.isAutoEnrolled ? strings.autoEnrolled : '',
          disabled: row.original.isAutoEnrolled,
          onClick: () => account.unselectSection(row.original.sectionId)
        }
      ],
      defaultSorting: { id: 'title', desc: false }
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
    </Box>
  );
});
