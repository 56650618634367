import { AutomatedImportHistoryViewModel } from '@insights/viewmodels';
import SuccessIcon from '@mui/icons-material/Check';
import FailureIcon from '@mui/icons-material/Close';
import PlayIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { RootAdminRoles } from '@shared/models/types';
import { differenceInMilliseconds, format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';

export interface AutomatedImportHistoryProps {
  viewModel: AutomatedImportHistoryViewModel;
  sx?: SxProps;
  className?: string;
}

export const AutomatedImportHistory = observer((props: AutomatedImportHistoryProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const dateStrings = localizationService.localizedStrings.models.dateFormats;

  return (
    <Box sx={sx} className={className} flexDirection="column">
      <CardHeader
        title={strings.automatedImportHistoryTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
            {viewModel.isExecuting ? (
              <CircularProgress />
            ) : (
              <Tooltip title={strings.executeAutomatedImportManuallyTooltip}>
                <IconButton onClick={() => void viewModel.executeManually()}>
                  <PlayIcon />
                </IconButton>
              </Tooltip>
            )}
          </AuthorizationRoleCondition>
        }
      />

      <CardContent>
        {viewModel.history.map((result, index) => (
          <Fragment key={`auto-import-history-${result.startTime.toISOString()}`}>
            <Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
              {result.isFullySuccessful ? (
                <SuccessIcon color="success" sx={{ mr: 2, mt: 1 }} />
              ) : (
                <FailureIcon color="error" sx={{ mr: 2, mt: 1 }} />
              )}

              <Box flex={1} flexDirection="column" px={1}>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant="text"
                  onClick={() => void viewModel.viewResultDetails(result)}
                >
                  <Typography variant="subtitle1">
                    {format(result.startTime, dateStrings.mediumUnabridgedWithLongTime)}&nbsp;
                  </Typography>
                  <Typography variant="body2">
                    ({strings.importDuration(differenceInMilliseconds(result.endTime, result.startTime))})
                  </Typography>
                </Button>
                {result.transformationResults.length === 0 ? (
                  <Typography pl={1}>{strings.noAutomatedTransformationImportResults}</Typography>
                ) : (
                  <Box pl={1}>
                    {result.transformationResults.map((tr, ti) => (
                      <Box
                        key={`automated-import-result-${ti}`}
                        flexDirection="row"
                        display="flex"
                        alignItems="flex-start"
                      >
                        {tr.isSuccessful ? (
                          <SuccessIcon fontSize="small" sx={{ mr: 2 }} />
                        ) : (
                          <FailureIcon fontSize="small" sx={{ mr: 2 }} />
                        )}
                        <Typography variant="caption" fontWeight={700} mr={2}>
                          {tr.label}
                        </Typography>
                        {tr.isSkipped ? (
                          <Typography variant="caption">{strings.skippedAutomatedTransformation}</Typography>
                        ) : (
                          <Typography variant="caption">
                            {strings.importDuration(differenceInMilliseconds(tr.endTime, tr.startTime))}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            {index < viewModel.history.length - 1 && <Divider />}
          </Fragment>
        ))}
        {viewModel.history.length === 0 && (
          <Box flexDirection="row" display="flex">
            <Typography flex={1}>{strings.noAutomatedImports}</Typography>
          </Box>
        )}
      </CardContent>
    </Box>
  );
});
