import { EditableAccountInfo, SectionInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { AppSectionsViewModel } from '../metrics';
import { AppEditableAccountsScreenViewModel } from './EditableAccountsScreenViewModel';

export class AppEditableStudentsScreenViewModel extends AppEditableAccountsScreenViewModel {
  constructor(
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    accountService: AccountService,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    configId: string,
    shouldLimitAccess?: boolean
  ) {
    super(
      schoolYearConfigurationStore,
      accountService,
      navigationService,
      localizationService,
      configId,
      'student',
      shouldLimitAccess
    );
  }

  protected async getLimitedAccounts(): Promise<AccountModel[]> {
    if (this._accountService.isAllowed(['studyo-staff', 'school-staff'])) {
      // School and Studyo staff can view all students. They'll get limited access to changes.
      return await this._schoolYearConfigurationStore.getStudents(this.configId, false);
    } else {
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        // Teachers see all students in their sections.
        const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
          this.configId,
          teacherId
        );
        const sectionIds = sections.map((s) => s.id);
        return await this._schoolYearConfigurationStore.getStudentsForSectionIds(this.configId, sectionIds, false);
      } else {
        return await super.getLimitedAccounts();
      }
    }
  }

  protected async loadAccountInfos(accounts: AccountModel[]): Promise<EditableAccountInfo[]> {
    // We must include deleted parent accounts, to see matches between non-deleted students and deleted parents.
    const parentsByChildId = await this._schoolYearConfigurationStore.getParentsByChildId(this.configId, true);

    return await Promise.all(
      accounts.map<Promise<EditableAccountInfo>>(async (account) => {
        const [sections, autoEnrolledSections] = await Promise.all([
          this._schoolYearConfigurationStore.getSectionsForStudent(this.configId, account),
          this._schoolYearConfigurationStore.getAutoEnrolledSectionsForStudent(this.configId, account)
        ]);

        const parents = parentsByChildId[account.id] || [];

        return {
          account,
          sections: new AppSectionsViewModel(
            this._navigationService,
            this._localizationService,
            this.configId,
            sections
              .map<SectionInfo>((section) => ({ sectionId: section.id, section, isLockedAsStudent: section.isLocked }))
              .concat(
                autoEnrolledSections.map<SectionInfo>((section) => ({
                  sectionId: section.id,
                  section,
                  isAutoEnrolled: true
                }))
              )
          ),
          parents,
          children: [],
          pendingChildren: []
        };
      })
    );
  }
}
