import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Stack, SxProps, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';

export interface SchoolConfigurationSctivityProps {
  sx?: SxProps;
  className?: string;
  variation: number;
  ratioLabel: string;
}

export const SchoolConfigurationActivity = observer(
  ({ sx, className, variation, ratioLabel }: SchoolConfigurationSctivityProps) => {
    const icon =
      variation > 0 ? (
        <TrendingUp sx={{ fontSize: 18, lineHeight: 'normal', color: green[500] }} />
      ) : variation < 0 ? (
        <TrendingDown sx={{ fontSize: 18, lineHeight: 'normal', color: red[500] }} />
      ) : undefined;

    return (
      <Stack sx={sx} className={className} direction="row" alignItems="center">
        <Typography variant="body1">{ratioLabel}</Typography>
        {icon && (
          <>
            {icon}
            <Typography variant="body2">{`${Math.abs(variation)}%`}</Typography>
          </>
        )}
      </Stack>
    );
  }
);
