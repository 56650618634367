import { AccountInfo, accountInfoFromModel } from '@insights/models';
import { NavigationService } from '@insights/services';
import { AccountModel, EditableAccount } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ChildAccountInfo {
  readonly accountId: string;
  readonly account: AccountModel | undefined;
  readonly isPending: boolean;
}

export interface LoadingParentChildrenEditionViewModel {
  readonly configId: string;
  readonly parentId: string;

  readonly data: IPromiseBasedObservable<ParentChildrenEditionViewModel>;

  close(): void;
}

export interface ParentChildrenEditionViewModel {
  readonly parent: AccountModel;
  readonly selectedChildren: ChildAccountInfo[];
  readonly availableStudents: AccountInfo[];

  readonly hasChanges: boolean;
  readonly isSaving: boolean;
  readonly messages: string[];

  addAccount(accountId: string, asPending: boolean): void;
  removeAccount(accountId: string): void;
  confirmAccount(accountId: string): void;
  apply(): Promise<void>;
}

export class AppLoadingParentChildrenEditionViewModel implements LoadingParentChildrenEditionViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly parentId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ParentChildrenEditionViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData(): Promise<ParentChildrenEditionViewModel> {
    const [parent, students] = await Promise.all([
      this._schoolYearConfigurationStore.getAccount(this.configId, this.parentId, false),
      this._schoolYearConfigurationStore.getStudents(this.configId, false)
    ]);

    return new AppParentChildrenEditionViewModel(
      this._localizationService,
      this._navigationService,
      this._schoolYearConfigurationStore,
      parent,
      students,
      this._onSuccess,
      this._onCancel,
      this.configId
    );
  }
}

export class AppParentChildrenEditionViewModel implements ParentChildrenEditionViewModel {
  private readonly _studentsById: Record<string, AccountModel>;
  private readonly _editableParent: EditableAccount;

  @observable private _isSaving = false;
  @observable private _messages: string[] = [];

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    public readonly parent: AccountModel,
    private readonly _students: AccountModel[],
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string
  ) {
    makeObservable(this);
    this._studentsById = _.keyBy(_students, (s) => s.id);
    this._editableParent = new EditableAccount(parent);
  }

  @computed
  get selectedChildren(): ChildAccountInfo[] {
    return this._editableParent.childrenAccountIds
      .map<ChildAccountInfo>((id) => ({
        accountId: id,
        account: this._studentsById[id],
        isPending: false
      }))
      .concat(
        this._editableParent.childrenAccountPendingVerificationIds.map((id) => ({
          accountId: id,
          account: this._studentsById[id],
          isPending: true
        }))
      );
  }

  @computed
  get availableStudents() {
    return this._students
      .filter(
        (s) =>
          !this._editableParent.childrenAccountIds.includes(s.id) &&
          !this._editableParent.childrenAccountPendingVerificationIds.includes(s.id)
      )
      .map(accountInfoFromModel);
  }

  @computed
  get hasChanges(): boolean {
    return this._editableParent.hasChanges;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get messages(): string[] {
    return this._messages;
  }

  @action
  addAccount(accountId: string, asPending: boolean) {
    if (asPending) {
      this._editableParent.childrenAccountPendingVerificationIds =
        this._editableParent.childrenAccountPendingVerificationIds.concat(accountId);
    } else {
      this._editableParent.childrenAccountIds = this._editableParent.childrenAccountIds.concat(accountId);
    }
  }

  @action
  removeAccount(accountId: string) {
    this._editableParent.childrenAccountIds = this._editableParent.childrenAccountIds.filter((id) => id != accountId);
    this._editableParent.childrenAccountPendingVerificationIds =
      this._editableParent.childrenAccountPendingVerificationIds.filter((id) => id != accountId);
  }

  @action
  confirmAccount(accountId: string): void {
    this.removeAccount(accountId);
    this.addAccount(accountId, false);
  }

  @action
  async apply(): Promise<void> {
    // Yes, metrics
    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

    if (!this.hasChanges) {
      console.error('Invalid operation: It should not be possible to call apply without changes.');
      return;
    }

    this._isSaving = true;
    this._messages = [];

    try {
      await this._schoolYearConfigurationStore.saveAccount(this._editableParent);
      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._messages = [strings.unexpectedError + (error as Error).message];
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }
}
