import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AutomatedTransformationImportInfo, AutomatedTransformationImportListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/NoteAdd';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { RootAdminRoles } from '@shared/models/types';
import { ImportComponentsStrings } from '@shared/resources/strings/insights/components/ImportComponentsStrings';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface AutomatedTransformationImportListProps {
  viewModel: AutomatedTransformationImportListViewModel;
  sx?: SxProps;
  className?: string;
}

interface SortableAutomatedTransformationProps {
  value: AutomatedTransformationImportInfo;
  itemIndex: number;
  viewModel: AutomatedTransformationImportListViewModel;
  strings: ImportComponentsStrings;
}

const SortableAutomatedTransformation = ({
  value,
  itemIndex,
  viewModel,
  strings
}: SortableAutomatedTransformationProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  const targetSchema = value.transformation?.targetSchema ?? value.concatenation?.targetSchema;

  return (
    <Box ref={setNodeRef} my={1} flexDirection="row" display="flex" alignItems="flex-start" style={style}>
      <Typography mr={2} variant="subtitle1" fontWeight={700}>
        {value.automatedTransformationImport.label || '?'}
      </Typography>

      <Box flex={1} flexDirection="column" px={1}>
        <Typography variant="subtitle1">
          {value.transformation?.name ?? value.concatenation?.name ?? strings.noTransformationOrConcatenation}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary }}>
          {value.automatedTransformationImport.isCompleteData
            ? strings.destructiveAutomatedImport
            : strings.additiveAutomatedImport}
        </Typography>
        {targetSchema != null && targetSchema.length > 0 && (
          <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.targetSchemaValue(targetSchema)}
          </Typography>
        )}
      </Box>

      <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
        <MoreActionMenu>
          <MenuItem onClick={() => void viewModel.editAutomatedTransformationImport(itemIndex)}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>{strings.editAutomatedTransformation}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => void viewModel.deleteAutomatedTransformationImport(itemIndex)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{strings.deleteAutomatedTransformationImport}</ListItemText>
          </MenuItem>
        </MoreActionMenu>
      </AuthorizationRoleCondition>

      <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
        <ReorderIcon sx={{ ml: 1, mt: 1.5 }} {...attributes} {...listeners} style={dragHandleStyle} />
      </AuthorizationRoleCondition>
    </Box>
  );
};

interface SortableAutomatedTransformationListProps {
  viewModel: AutomatedTransformationImportListViewModel;
  strings: ImportComponentsStrings;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
}

const SortableAutomatedTransformationList = ({
  viewModel,
  strings,
  onSortEnd
}: SortableAutomatedTransformationListProps) => (
  <ReorderableList
    items={viewModel.automatedTransformationImports}
    renderItem={(value, index) => (
      <SortableAutomatedTransformation
        key={`auto-import-row-${value.automatedTransformationImport.label}-${index}`}
        itemIndex={index}
        value={value}
        viewModel={viewModel}
        strings={strings}
      />
    )}
    onOrderChanged={onSortEnd}
  />
);

export const AutomatedTransformationImportList = observer((props: AutomatedTransformationImportListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <Box sx={sx} className={className} flexDirection="column">
      <CardHeader
        title={strings.automatedTransformationImportsTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
            <Tooltip title={strings.addAutomatedTransformationImportTooltip}>
              <IconButton onClick={() => void viewModel.addAutomatedTransformationImport()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <CardContent>
        <SortableAutomatedTransformationList
          viewModel={viewModel}
          strings={strings}
          onSortEnd={(oldIndex, newIndex) => void viewModel.reorderAutomatedTransformationImport(oldIndex, newIndex)}
        />
      </CardContent>
    </Box>
  );
});
