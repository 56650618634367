import { ManageableRole } from '@insights/enums';
import { EditableAccountInfo } from '@insights/models';
import { EditableAccountsViewModel } from '@insights/viewmodels';
import { Add, ArrowDownward, Cancel, OpenInNew, Person, QuestionMark } from '@mui/icons-material';
import LegendIcon from '@mui/icons-material/Info';
import AdminIcon from '@mui/icons-material/VpnKey';
import { Box, Link, Snackbar, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminAuthorizationRoles, AdminOrTeacherAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode, useState } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { OptionalRouterLink } from '../RouterLink';
import { SectionsInlineList } from '../SectionsInlineList';

const TableStateKey = 'EditableAccountList';

export interface EditableAccountListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: EditableAccountsViewModel;
  title?: string;
  configId: string;
  accountType: ManageableRole;
}

export const EditableAccountList = observer((props: EditableAccountListProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { sx = [], className, style, viewModel, title, configId, accountType } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  const [showToast, setShowToast] = useState(false);
  const tableStateKey = `${TableStateKey}-${accountType}`;

  async function copyIdToClipboard(account: EditableAccountInfo): Promise<void> {
    await navigator.clipboard.writeText(account.account.id);
    runInAction(() => setShowToast(true));
  }

  const canViewTeacherPlanner = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    accountType === 'teacher' &&
    accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canViewStudentPlanner = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    accountType === 'student' &&
    accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canEditDetails = (accountInfo: EditableAccountInfo) =>
    !accountInfo.isReadOnly &&
    (accountService.isAllowed(AdminAuthorizationRoles) || accountService.isAccount([accountInfo.account.id]));

  const canEditSections = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    (accountType === 'student' || accountType === 'teacher') &&
    (accountService.isAllowed(AdminAuthorizationRoles) || accountService.isAccount([accountInfo.account.id]));

  const canEditChildren = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    accountType === 'parent' &&
    accountService.isAllowed(AdminAuthorizationRoles);

  const canManageTeacherPlanning = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    accountType === 'teacher' &&
    (accountService.isAllowed(AdminAuthorizationRoles) || accountService.isAccount([accountInfo.account.id]));

  const canCopyIds = accountService.isAllowed(RootAdminRoles);
  const canAddAccounts = accountService.isAllowed(AdminAuthorizationRoles);
  const canExportToCsv = accountService.isAllowed(AdminAuthorizationRoles);

  const renderParents = accountType === 'student';
  const renderChildren = accountType === 'parent';
  const renderSections = accountType !== 'parent';
  const renderGradeLevel = accountType === 'student';

  const table = useInsightsTable(
    tableStateKey,
    viewModel.accounts,
    title ?? '',
    () => [
      {
        header: '',
        id: 'user',
        size: 40,
        grow: false,
        Cell: ({ row }) => renderUserColumn(row.original),
        enableColumnActions: false
      },
      {
        header: strings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.managedIdentifier,
            row.account.isAdmin ? ':admin' : '',
            row.account.userId.length > 0 ? ':user' : ''
          ]),
        id: 'name',
        Cell: ({ row }) => {
          const { routeTemplate, accountIdParamName } = getRouteTemplateAndParamName();

          return (
            <Stack direction="row" alignItems="center">
              <OptionalRouterLink
                variant="body1"
                to={reactRouterRouteService.resolveLocation(routeTemplate, [
                  { name: RouteParamNames.configId, value: configId },
                  { name: accountIdParamName, value: row.original.account.id }
                ])}
                disabled={routeTemplate.length === 0}
              >
                {AccountUtils.getDisplayLastFirstName(row.original.account, strings.noName)}
              </OptionalRouterLink>
              {row.original.account.isAdmin && <AdminIcon sx={{ ml: 1 }} color="secondary" />}
            </Stack>
          );
        }
      },
      {
        header: strings.email,
        accessorKey: 'account.email',
        id: 'email',
        Cell: ({ row, renderedCellValue }) => (
          <Stack>
            <Link variant="body1" target="_top" href={`mailto:${row.original.account.email}`}>
              {renderedCellValue}
            </Link>
            {row.original.account.profile.publicEmail.length > 0 && (
              <Link variant="body2" target="_top" href={`mailto:${row.original.account.profile.publicEmail}`}>
                ✉️&nbsp;{row.original.account.profile.publicEmail}
              </Link>
            )}
          </Stack>
        )
      },
      renderGradeLevel && {
        header: strings.gradeTitle,
        accessorKey: 'account.gradeLevel',
        id: 'gradeLevel',
        sortingFn: 'alphanumeric'
      },
      renderSections && {
        header: strings.sections,
        accessorFn: (row) =>
          accessorForSearch(
            _.flatMap(_.compact(row.sections.sections.map((s) => s.section)).map((s) => [s.title, s.importId]))
          ),
        id: 'sections',
        size: 600,
        Cell: ({ row }) => (
          <SectionsInlineList
            sections={row.original.sections.sections}
            configId={configId}
            noneLabel={strings.noneLabel}
          />
        )
      },
      renderParents && {
        header: strings.parents,
        accessorFn: (row) => accessorForSearch(row.parents.map((p) => p.email)),
        id: 'parents',
        Cell: ({ row }) => (
          <Stack>
            {row.original.parents.map((parent) => {
              const isPending = parent.childrenAccountPendingVerificationIds.includes(row.original.account.id);
              return (
                <Typography key={`parent-account-${parent.id}`} style={{ fontStyle: isPending ? 'italic' : 'normal' }}>
                  {parent.email}
                </Typography>
              );
            })}
          </Stack>
        )
      },
      renderChildren && {
        header: strings.children,
        accessorFn: (row) =>
          accessorForSearch(
            _.flatMap(
              row.children.concat(row.pendingChildren).map((c) => [AccountUtils.getDisplayFirstLastName(c), c.email])
            )
          ),
        id: 'children',
        Cell: ({ row }) => (
          <Stack>
            {row.original.children.map((child) => (
              <Typography key={`child-account-${child.id}`}>
                {`${AccountUtils.getDisplayFirstLastName(child)} (${child.email})`}
              </Typography>
            ))}
            {row.original.pendingChildren.map((child) => (
              <Tooltip key={`child-account-${child.id}`} title={strings.pendingChildTooltip} placement="bottom-start">
                <Stack direction="row" spacing={1}>
                  <QuestionMark fontSize="small" color="warning" />
                  <Typography style={{ fontStyle: 'italic' }}>
                    {`${AccountUtils.getDisplayFirstLastName(child)} (${child.email})`}
                  </Typography>
                </Stack>
              </Tooltip>
            ))}
          </Stack>
        )
      }
    ],
    {
      defaultSorting: { id: 'name', desc: false },
      getTopActionButtons: () => [
        canAddAccounts && {
          icon: () => <Add />,
          tooltip: strings.getAddAccountTooltip(
            localizationService.localizedStrings.models.account.roleTitleForManageableRole(accountType, false)
          ),
          onClick: () => void viewModel.addAccount()
        },
        canExportToCsv && {
          icon: () => <ArrowDownward />,
          tooltip: strings.exportToCsv,
          onClick: () => viewModel.exportToCsv()
        }
      ],
      getRowActionMenuItems: ({ row }) => [
        (canViewTeacherPlanner(row.original) || canViewStudentPlanner(row.original)) && {
          title: strings.viewPlanner,
          endAdornment: () => <OpenInNew />,
          onClick: () => void viewModel.navigateToPlanner(row.original.account.id)
        },
        canEditDetails(row.original) && {
          title: strings.editDetails,
          onClick: () => void viewModel.editAccount(row.original)
        },
        canEditSections(row.original) && {
          title: strings.editSections,
          onClick: () => void viewModel.editSelectedSections(row.original)
        },
        canEditChildren(row.original) && {
          title: strings.editChildren,
          onClick: () => void viewModel.editChildren(row.original)
        },
        canManageTeacherPlanning(row.original) && {
          title: strings.manageTeacherPlanning,
          onClick: () => void viewModel.manageTeacherPlanning(row.original)
        },
        canCopyIds && {
          title: strings.copyId,
          onClick: () => void copyIdToClipboard(row.original)
        }
      ],
      defaultPageSize: 10
    }
  );

  function renderUserColumn(info: EditableAccountInfo) {
    let icon: ReactNode = null;
    let tooltip = '';

    if (info.account.isDeleted) {
      icon = <Cancel color="error" />;
      tooltip = strings.deletedAccountTooltip;
    } else if (info.account.userId.length > 0) {
      icon = <Person color="secondary" />;
      tooltip = strings.connectedUserTooltip;
    }

    return (
      icon && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Tooltip title={tooltip}>{icon}</Tooltip>
        </Box>
      )
    );
  }

  function getRouteTemplateAndParamName(): {
    routeTemplate: string;
    accountIdParamName: string;
  } {
    switch (accountType) {
      case 'student':
        return {
          routeTemplate: RouteTemplates.studentDetails,
          accountIdParamName: RouteParamNames.studentId
        };
      case 'teacher':
        return {
          routeTemplate: RouteTemplates.teacherDetails,
          accountIdParamName: RouteParamNames.teacherId
        };
      case 'parent':
      case 'staff':
        // No metric details
        return { routeTemplate: '', accountIdParamName: '' };
      default:
        throw new Error('Unexpected account type');
    }
  }

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
      <Stack
        direction="row"
        sx={{
          mt: 1
        }}
      >
        <Tooltip
          title={strings.accountLegendTooltipLines.map((line, i) => (
            <Typography key={`tooltip-line-${i}`} variant="body2">
              {line}
            </Typography>
          ))}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center'
            }}
          >
            <LegendIcon color="secondary" />
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
              {strings.accountLegendLabel}
            </Typography>
          </Stack>
        </Tooltip>
        <Box
          sx={{
            flex: 1
          }}
        />
      </Stack>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={strings.copyIdNotification}
      />
    </Box>
  );
});
