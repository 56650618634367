import { OnboardingCommentsViewModel } from '@insights/viewmodels';
import HistoryIcon from '@mui/icons-material/History';
import { Card, CardContent, CardHeader, IconButton, Link, SxProps, Tooltip, Typography } from '@mui/material';
import { RootRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { OnboardingCommentView } from './OnboardingCommentView';

export interface OnboardingCommentsCardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingCommentsViewModel;
  showHistoryButton?: boolean;
}

export const OnboardingCommentsCard = observer((props: OnboardingCommentsCardProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel, showHistoryButton } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const showSupport = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('show');
    }
  };

  return (
    <Card className={className} style={style} id="comments">
      <CardHeader
        title={strings.commentsTitle}
        action={
          showHistoryButton && (
            <AuthorizationRoleCondition allowedRoles={RootRoles}>
              <Tooltip title={strings.viewHistoryTooltip}>
                <IconButton onClick={() => void viewModel.viewHistory()}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </AuthorizationRoleCondition>
          )
        }
      ></CardHeader>
      <CardContent>
        {viewModel.comments.map((c, index) => (
          <OnboardingCommentView key={`comment-${index}`} viewModel={c} />
        ))}
        {viewModel.isProcessCompleted && (
          <Typography mb={2}>
            {strings.suggestSupportLabel}
            <Link onClick={showSupport} fontWeight="500">
              {strings.suggestSupportButton}
            </Link>
            .
          </Typography>
        )}
        {viewModel.nextComment && <OnboardingCommentView viewModel={viewModel.nextComment} />}
      </CardContent>
    </Card>
  );
});
