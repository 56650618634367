import { RouteParamNames, RouteTemplates } from '@insights/Routes';
import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { SchoolConfigurationInfo, SchoolConfigurationsViewModel } from '@insights/viewmodels';
import { Archive, HourglassEmpty } from '@mui/icons-material';
import { Box, Chip, Snackbar, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { RootRoles } from '@shared/models/types';
import { TintedImage } from '@shared/rxp/tinted-image';
import { useNavigateAsync } from '@shared/utils';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { RouterLink } from '../RouterLink';
import { SchoolConfigurationActivity } from './SchoolConfigurationActivity';

const TableStateKey = 'SchoolConfigurations';

export interface SchoolConfigurationListProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolConfigurationsViewModel;
  schools: SchoolConfigurationInfo[];
}

export const SchoolConfigurationList = observer(
  ({ sx, className, schools, viewModel }: SchoolConfigurationListProps) => {
    const { localizationService, imageService, reactRouterRouteService, accountService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.views;
    const connectorStrings = localizationService.localizedStrings.insights.components.connectors;
    const externalImages = imageService.studyoImages.tasks.external;

    const navigate = useNavigateAsync();
    const [showToast, setShowToast] = useState(false);

    async function copyIdToClipboard(config: SchoolConfigurationInfo): Promise<void> {
      await navigator.clipboard.writeText(config.schoolConfiguration.id);
      setShowToast(true);
    }

    const isRoot = accountService.isAllowed(RootRoles);

    const table = useInsightsTable(
      TableStateKey,
      schools,
      strings.configurationsTitle,
      () => [
        {
          header: strings.school,
          accessorFn: (row) =>
            accessorForSearch([
              row.schoolConfiguration.schoolName,
              row.schoolConfiguration.comments,
              ...row.schoolConfiguration.tags
            ]),
          id: 'name',
          Cell: ({ row }) => (
            <Stack direction="row" alignItems="center" spacing={1}>
              {row.original.schoolConfiguration.state !== 'active' && (
                <Tooltip title={strings.managedGeneral.getConfigStateLabel(row.original.schoolConfiguration.state)}>
                  {row.original.schoolConfiguration.state === 'archived' ? (
                    <Archive fontSize="small" />
                  ) : (
                    <HourglassEmpty fontSize="small" />
                  )}
                </Tooltip>
              )}
              <RouterLink
                variant="body1"
                to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
                  {
                    name: RouteParamNames.configId,
                    value: row.original.schoolConfiguration.id
                  }
                ])}
              >
                {row.original.schoolConfiguration.schoolName}
              </RouterLink>
              {row.original.schoolConfiguration.title.length > 0 && (
                <Typography variant="body2">{`[${row.original.schoolConfiguration.title}]`}</Typography>
              )}
              {row.original.schoolConfiguration.tags.map((tag) => (
                <Chip
                  key={`tag-${tag}`}
                  sx={{ '& .MuiChip-label': { fontSize: (theme) => theme.typography.caption.fontSize } }}
                  variant="outlined"
                  color="info"
                  size="small"
                  label={tag}
                />
              ))}
            </Stack>
          )
        },
        {
          header: strings.activeStudents,
          id: 'activeStudents',
          enableColumnActions: false,
          Cell: ({ row }) => {
            const variation = row.original.schoolGlobalStats.activeStudents.lastWeekVariation;
            const ratioLabel =
              row.original.schoolConfiguration.expectedStudentCount === 0
                ? `${row.original.schoolGlobalStats.activeStudents.lastWeek} / ${row.original.schoolGlobalStats.accounts.studentTotal}`
                : `${row.original.schoolGlobalStats.activeStudents.lastWeek} / ${row.original.schoolConfiguration.expectedStudentCount} (${row.original.schoolGlobalStats.accounts.studentTotal})`;

            return <SchoolConfigurationActivity variation={variation} ratioLabel={ratioLabel} />;
          }
        },
        {
          header: strings.activeTeachers,
          id: 'activeTeachers',
          enableColumnActions: false,
          Cell: ({ row }) => {
            const variation = row.original.schoolGlobalStats.activeTeachers.lastWeekVariation;
            const ratioLabel = `${row.original.schoolGlobalStats.activeTeachers.lastWeek} / ${row.original.schoolGlobalStats.accounts.teacherTotal}`;

            return <SchoolConfigurationActivity variation={variation} ratioLabel={ratioLabel} />;
          }
        },
        {
          header: strings.activeParents,
          id: 'activeParents',
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Typography variant="body1">{`${row.original.schoolGlobalStats.accounts.parentClaimed} / ${row.original.schoolGlobalStats.accounts.parentTotal}`}</Typography>
          )
        },
        {
          header: strings.startDate,
          id: 'startDate',
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Typography variant="body1">
              {row.original.schoolConfiguration.startDay.formattedString(
                localizationService.localizedStrings.models.dateFormats.shortUnabridged
              )}
            </Typography>
          )
        },
        {
          header: strings.endDate,
          id: 'endDate',
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Typography variant="body1">
              {row.original.schoolConfiguration.endDay.formattedString(
                localizationService.localizedStrings.models.dateFormats.shortUnabridged
              )}
            </Typography>
          )
        },
        {
          header: strings.integrations,
          accessorFn: (row) =>
            accessorForSearch(
              row.schoolConfiguration.enabledIntegrations.map((integration) =>
                connectorStrings.localizedIntegrationName(integration)
              )
            ),
          id: 'integrations',
          enableSorting: false,
          Cell: ({ row }) => {
            const moreCount =
              row.original.schoolConfiguration.enabledIntegrations.length > 4
                ? row.original.schoolConfiguration.enabledIntegrations.length - 3
                : 0;
            const visibleIntegrations =
              moreCount === 0
                ? row.original.schoolConfiguration.enabledIntegrations
                : _.take(row.original.schoolConfiguration.enabledIntegrations, 3);
            const hiddenIntegrations =
              moreCount === 0 ? [] : _.drop(row.original.schoolConfiguration.enabledIntegrations, 3);

            return (
              <Stack direction="row" spacing={1}>
                {visibleIntegrations.map((integration) => (
                  <Tooltip key={integration} title={connectorStrings.localizedIntegrationName(integration)}>
                    <Box>
                      <TintedImage
                        source={externalImages.getIntegrationImage(integration)}
                        sx={{
                          width: 24,
                          height: 24,
                          marginRight: '6px'
                        }}
                      />
                    </Box>
                  </Tooltip>
                ))}
                {moreCount > 0 && (
                  <Tooltip
                    title={
                      <Stack direction="row" p={1}>
                        {hiddenIntegrations.map((integration) => (
                          <TintedImage
                            key={`more-integration-${integration}`}
                            source={externalImages.getIntegrationImage(integration)}
                            sx={{
                              width: 24,
                              height: 24,
                              marginRight: '6px'
                            }}
                          />
                        ))}
                      </Stack>
                    }
                  >
                    <Typography>{`+${moreCount}`}</Typography>
                  </Tooltip>
                )}
              </Stack>
            );
          }
        },
        {
          header: strings.valid,
          id: 'validity',
          size: 40,
          enableSorting: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Typography variant="body1">
              {row.original.validationMessages == null
                ? ''
                : row.original.validationMessages.length === 0
                  ? '✅'
                  : '❗'}
            </Typography>
          )
        }
      ],
      {
        defaultSorting: { id: 'name', desc: false },
        getRowActionMenuItems: ({ row }) => [
          isRoot && {
            title: strings.manageThisSchool,
            onClick: () => void viewModel.editSchool(row.original.schoolConfiguration.id, navigate)
          },
          isRoot && {
            title: strings.copyId,
            onClick: () => void copyIdToClipboard(row.original)
          }
        ]
      }
    );

    return (
      <Box sx={sx} className={className}>
        <MaterialReactTable table={table} />
        <Snackbar
          open={showToast}
          autoHideDuration={2000}
          onClose={() => setShowToast(false)}
          message={strings.copyIdNotification}
        />
      </Box>
    );
  }
);
