import { AlertService, NavigationService } from '@insights/services';
import {
  Concatenation,
  EditableImportSession,
  ImportSession,
  Schema,
  SourceData,
  SourceFile,
  Transformation
} from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { NavigateFunctionAsync } from '@shared/utils';
import { computed, makeObservable } from 'mobx';

export interface SourceDataInfo {
  data: SourceData;
  file?: SourceFile;
  concatenation?: Concatenation;
  transformation?: Transformation;
}
export interface ImportSessionDataListViewModel {
  readonly data: SourceDataInfo[];

  addConcatenation(): Promise<void>;
  removeConcatenation(concatenation: Concatenation): Promise<void>;

  addTransformation(): Promise<void>;
  removeTransformation(transformation: Transformation): Promise<void>;

  testData(data: SourceData, navigate: NavigateFunctionAsync): Promise<void>;
  viewData(data: SourceData, navigate: NavigateFunctionAsync): Promise<void>;
  editOrderedHeaders(file: SourceFile): Promise<void>;
  editConcatenationOptions(concatenation: Concatenation): Promise<void>;
  editTransformationOptions(transformation: Transformation): Promise<void>;
  editTransformationRecipe(transformation: Transformation, navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppImportSessionDataListViewModel implements ImportSessionDataListViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _sessionId: string,
    private readonly _session: ImportSession,
    private readonly _schemas: Schema[]
  ) {
    makeObservable(this);
  }

  @computed
  get data() {
    const files = new Map(this._session.expectedFiles.map((f) => [f.label, f]));
    const concatenations = new Map(this._session.concatenations.map((c) => [c.label, c]));
    const transformations = new Map(this._session.transformations.map((t) => [t.label, t]));

    return this._session.data.map((data) => ({
      data,
      file: files.get(data.label),
      concatenation: concatenations.get(data.label),
      transformation: transformations.get(data.label)
    }));
  }

  async addConcatenation(): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditConcatenation(
      this._session.configId,
      this._session,
      undefined,
      this._schemas
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async removeConcatenation(concatenation: Concatenation): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    const response = await this._alertService.showConfirmation({
      title: strings.confirmDeleteConcatenationTitle,
      message: strings.confirmDeleteConcatenationMessage(concatenation.name, concatenation.label)
    });

    if (response !== 'cancelled') {
      try {
        const session = new EditableImportSession(this._session);
        const concatenationToRemove = session.concatenations.find((c) => c.label === concatenation.label);

        if (concatenationToRemove == null) {
          throw new Error('Could not find a transformation with the same label.');
        }

        concatenationToRemove.markAsDeleted();

        // No need for the data, we invalidate after.
        await this._importSessionStore.createOrUpdateImportSession(session, false);
        this._importSessionStore.invalidate();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }
  async addTransformation(): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditTransformation(
      this._session.configId,
      this._session,
      undefined,
      this._schemas
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async removeTransformation(transformation: Transformation): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    const response = await this._alertService.showConfirmation({
      title: strings.confirmDeleteTransformationTitle,
      message: strings.confirmDeleteTransformationMessage(transformation.name, transformation.label)
    });

    if (response !== 'cancelled') {
      try {
        const session = new EditableImportSession(this._session);
        const transformationToRemove = session.transformations.find((t) => t.label === transformation.label);

        if (transformationToRemove == null) {
          throw new Error('Could not find a transformation with the same label.');
        }

        transformationToRemove.markAsDeleted();

        // No need for the data, we invalidate after.
        await this._importSessionStore.createOrUpdateImportSession(session, false);
        this._importSessionStore.invalidate();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }

  async testData(data: SourceData, navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToImportDataDetails(this._configId, this._sessionId, data.label, navigate);
  }

  async viewData(data: SourceData, navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToImportSessionSourceData(
      this._configId,
      this._sessionId,
      data.label,
      navigate
    );
  }

  async editOrderedHeaders(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionFileOrderedHeaders(
      this._configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async editConcatenationOptions(concatenation: Concatenation): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditConcatenation(
      this._session.configId,
      this._session,
      concatenation.label,
      this._schemas
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async editTransformationOptions(transformation: Transformation): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditTransformation(
      this._session.configId,
      this._session,
      transformation.label,
      this._schemas
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async editTransformationRecipe(transformation: Transformation, navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToImportSessionTransformation(
      this._configId,
      this._sessionId,
      transformation.label,
      navigate
    );
  }
}
