import { css } from '@emotion/css';
import {
  AuthorizationRoleCondition,
  CourseOccurrenceInformationChip,
  ErrorIndicator,
  InformationText,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import { SettingsStore } from '@insights/services';
import {
  WorkloadManagerBySectionInfo,
  WorkloadManagerBySectionPageInfo,
  WorkloadManagerBySectionViewModel
} from '@insights/viewmodels';
import { mdiCalendarEdit } from '@mdi/js';
import Icon from '@mdi/react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Divider,
  IconButton,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { TaskIcon } from '@shared/components/contents';
import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { AdminAuthorizationRoles, Color, ImportantContentIcons } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ReactRouterRouteService } from '@shared/web/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import {
  MinLayoutWidth,
  MinTinyChartHeight,
  WorkloadManagerStudentAtThresholdColor,
  WorkloadManagerStudentAtThresholdHoveredColor,
  WorkloadManagerStudentOverThresholdColor,
  WorkloadManagerStudentOverThresholdHoveredColor
} from '../../../Constants';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface WorkloadManagerBySectionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: WorkloadManagerBySectionViewModel;
  displaySectionName: boolean;
  displayWeekNavigation?: boolean;
}

export const WorkloadManagerBySection = observer((props: WorkloadManagerBySectionProps) => {
  const { localizationService, reactRouterRouteService, settingsStore } = useInsightsServices();
  const { className, sx = [], style, viewModel, displaySectionName, displayWeekNavigation = true } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const theme = useTheme();

  return (
    <Box sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="workload-manager-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.workload,
          titleVariant: 'h6'
        }}
      >
        <Box sx={{ minHeight: MinTinyChartHeight, minWidth: MinLayoutWidth }} display="flex" flexDirection="row">
          <ObservablePresenter
            sx={{ flex: 1 }}
            data={viewModel.data}
            indicatorsSize="normal"
            loadingMessage={strings.loadingDataMessage}
            errorMessage={strings.loadingDataErrorMessage}
            render={(data) =>
              renderShell(
                data,
                displaySectionName,
                displayWeekNavigation,
                viewModel,
                localizationService,
                reactRouterRouteService,
                settingsStore,
                theme
              )
            }
          />
        </Box>
      </RequiresFeatureCondition>
    </Box>
  );
});

function renderShell(
  workloadData: WorkloadManagerBySectionInfo,
  displaySectionName: boolean,
  displayWeekNavigation: boolean,
  viewModel: WorkloadManagerBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <Box display="flex" flexDirection="column">
      <Box marginLeft={3} marginBottom={2} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6">{strings.workload}</Typography>

        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <Tooltip title={strings.editAssessmentPlanningDatesButtonTooltip}>
            <IconButton onClick={() => void viewModel.editAssessmentPlanningDates()}>
              <Icon path={mdiCalendarEdit} size={1} color={theme.palette.text.secondary} />
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>

        <Box flex={1} />

        <Typography variant="body1">{strings.showOnlyExams}</Typography>

        <Switch checked={viewModel.examOnly} onChange={(_, checked) => (viewModel.examOnly = checked)} />

        <Tooltip title={renderFilterTooltip(localizationService)}>
          <InfoIcon sx={{ color: blue[700] }} />
        </Tooltip>
      </Box>

      <Box flex={1}>
        {viewModel.pageData.case({
          pending: () =>
            renderEmptyTable(
              workloadData,
              displaySectionName,
              viewModel,
              localizationService,
              reactRouterRouteService,
              settingsStore,
              theme
            ),
          rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
          fulfilled: (data) =>
            renderTable(
              workloadData,
              data,
              displaySectionName,
              viewModel,
              localizationService,
              reactRouterRouteService,
              settingsStore,
              theme
            )
        })}
      </Box>

      <Box sx={{ minHeight: 30 }} marginTop={2} marginLeft={3} display="flex" flexDirection="row" alignItems="center">
        {/* Legend */}
        <Tooltip
          slotProps={{
            popper: { sx: { opacity: 1 } },
            tooltip: {
              sx: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.getContrastText(theme.palette.common.white),
                boxShadow: theme.shadows[1],
                maxWidth: 'none'
              }
            }
          }}
          title={renderLegendTooltip(localizationService)}
        >
          <span>
            <InformationText text={strings.showLegend} />
          </span>
        </Tooltip>

        <Box flex={1} />

        {/* Show a loading indicator when changing the current page */}
        {viewModel.pageData.state === 'pending' && <LoadingIndicator size="tiny" />}

        {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
      </Box>
    </Box>
  );
}

function renderEmptyTable(
  workloadData: WorkloadManagerBySectionInfo,
  displaySectionName: boolean,
  viewModel: WorkloadManagerBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      workloadData,
      {
        schoolDays: workloadData.schoolDays.filter((sd) => sd.day.isWithin(pageRange.startDay, pageRange.endDay)),
        dayInfos: [],
        weekInfos: []
      },
      displaySectionName,
      viewModel,
      localizationService,
      reactRouterRouteService,
      settingsStore,
      theme
    );
  }

  return null;
}

function renderTable(
  workloadData: WorkloadManagerBySectionInfo,
  pageData: WorkloadManagerBySectionPageInfo,
  displaySectionName: boolean,
  viewModel: WorkloadManagerBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      {renderTableHeader(workloadData, pageData, displaySectionName, localizationService, theme)}
      {renderTableBody(
        workloadData,
        pageData,
        displaySectionName,
        viewModel,
        localizationService,
        reactRouterRouteService,
        settingsStore,
        theme
      )}
    </Table>
  );
}

function renderTableHeader(
  _workloadData: WorkloadManagerBySectionInfo,
  pageData: WorkloadManagerBySectionPageInfo,
  displaySectionName: boolean,
  localizationService: LocalizationService,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <TableHead>
      <TableRow sx={{ verticalAlign: 'center', height: '50px' }}>
        {displaySectionName && (
          <TableCell sx={{ width: '240px', cursor: 'default' }}>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.text.secondary, fontWeight: 500, lineHeight: 'normal' }}
            >
              {strings.section}
            </Typography>
          </TableCell>
        )}

        {pageData.schoolDays
          .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
          .map((schoolDay) => (
            <TableCell
              key={schoolDay.day.asDateString}
              sx={{ textAlign: 'center', paddingRight: theme.spacing(2), width: 'calc(80%/6)', cursor: 'default' }}
            >
              <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
            </TableCell>
          ))}

        <TableCell
          sx={{
            textAlign: 'center',
            paddingRight: theme.spacing(2),
            width: 'calc(80%/6)',
            cursor: 'default',
            borderLeft: `1px solid ${grey[300]}`
          }}
        >
          <SchoolWeekHeader schoolDay={pageData.schoolDays[0]} displayType="column-header" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function renderTableBody(
  workloadData: WorkloadManagerBySectionInfo,
  pageData: WorkloadManagerBySectionPageInfo,
  displaySectionName: boolean,
  viewModel: WorkloadManagerBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const cellAtThresholdClassName = css({
    backgroundColor: WorkloadManagerStudentAtThresholdColor,
    color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdColor),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: WorkloadManagerStudentAtThresholdHoveredColor,
      color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdHoveredColor)
    }
  });

  const cellOverThresholdClassName = css({
    backgroundColor: WorkloadManagerStudentOverThresholdColor,
    color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdColor),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: WorkloadManagerStudentOverThresholdHoveredColor,
      color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdHoveredColor)
    }
  });

  return (
    <TableBody>
      {workloadData.sections.length === 0 && (
        <TableRow>
          <TableCell colSpan={99} align="center">
            <Typography variant="body2">{strings.emptyTable}</Typography>
          </TableCell>
        </TableRow>
      )}
      {workloadData.sections.length > 0 &&
        workloadData.sections.map((sectionInfo) => {
          const weekInfo = pageData.weekInfos.find(
            (i) => i.schoolDay == null && i.sectionId === sectionInfo.section.id
          );

          const studentCountAtThresholdForWeek = weekInfo?.studentIdsAtThreshold.length ?? 0;
          const studentCountOverThresholdForWeek = weekInfo?.studentIdsOverThreshold.length ?? 0;
          const publishedTasksCountForWeek = weekInfo?.publishedTaskCount ?? 0;

          const weekType =
            studentCountOverThresholdForWeek > 0 ? 'error' : studentCountAtThresholdForWeek > 0 ? 'warning' : 'normal';

          const weekCellEffectiveClassName =
            weekType === 'error' ? cellOverThresholdClassName : weekType === 'warning' ? cellAtThresholdClassName : '';

          return (
            <TableRow key={sectionInfo.section.id} sx={{ verticalAlign: 'center', height: '50px' }}>
              {displaySectionName && (
                <TableCell sx={{ width: '240px', cursor: 'default' }}>
                  <SectionName
                    color={sectionInfo.section.color}
                    title={sectionInfo.section.title}
                    subInformation1={sectionInfo.section.sectionNumber}
                    subInformation2={strings.localizedNumberOfStudents(sectionInfo.students.size)}
                    thresholds={{
                      dailyThreshold: sectionInfo.dailyThreshold,
                      weeklyThreshold: sectionInfo.weeklyThreshold
                    }}
                    linkTo={
                      settingsStore.isEmbedded
                        ? undefined
                        : reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                            {
                              name: RouteParamNames.configId,
                              value: viewModel.configId
                            },
                            {
                              name: RouteParamNames.sectionId,
                              value: sectionInfo.section.id
                            }
                          ])
                    }
                  />
                </TableCell>
              )}

              {pageData.schoolDays
                .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
                .map((schoolDay) => {
                  const dayInfo = pageData.dayInfos.find(
                    (d) => d.schoolDay!.day.isSame(schoolDay.day) && d.sectionId === sectionInfo.section.id
                  );
                  const studentCountAtThreshold = dayInfo?.studentIdsAtThreshold.length ?? 0;
                  const studentCountOverThreshold = dayInfo?.studentIdsOverThreshold.length ?? 0;
                  const publishedTaskCount = dayInfo?.publishedTaskCount ?? 0;

                  const dayType =
                    studentCountOverThreshold > 0 ? 'error' : studentCountAtThreshold > 0 ? 'warning' : 'normal';

                  const dayCellEffectiveClassName =
                    dayType === 'error'
                      ? cellOverThresholdClassName
                      : dayType === 'warning'
                        ? cellAtThresholdClassName
                        : '';

                  return (
                    <Tooltip
                      key={`${sectionInfo.section.id} - ${schoolDay.day.asDateString}`}
                      classes={{
                        tooltip: 'tooltip',
                        popper: 'tooltipPopper'
                      }}
                      title={renderValueTooltip(
                        studentCountAtThreshold,
                        studentCountOverThreshold,
                        sectionInfo.section.color,
                        localizationService
                      )}
                    >
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          paddingRight: theme.spacing(2),
                          width: 'calc(80%/6)',
                          cursor: 'default'
                        }}
                        className={clsx(dayCellEffectiveClassName)}
                        onClick={() =>
                          dayInfo &&
                          (studentCountAtThreshold > 0 || studentCountOverThreshold > 0) &&
                          void showDetail(
                            viewModel,
                            dayInfo.sectionId,
                            dayInfo.schoolDay!,
                            dayInfo.schoolDay!,
                            dayInfo.studentIdsAtThreshold,
                            dayInfo.studentIdsOverThreshold
                          )
                        }
                      >
                        <CourseOccurrenceInformationChip
                          hasCourseOccurrence={dayInfo?.hasCourseOccurrence ?? false}
                          hasPublishedTasks={publishedTaskCount > 0}
                          value={studentCountOverThreshold || studentCountAtThreshold}
                          type={dayType}
                        />
                      </TableCell>
                    </Tooltip>
                  );
                })}

              <Tooltip
                classes={{
                  tooltip: 'tooltip',
                  popper: 'tooltipPopper'
                }}
                title={renderValueTooltip(
                  studentCountAtThresholdForWeek,
                  studentCountOverThresholdForWeek,
                  sectionInfo.section.color,
                  localizationService
                )}
              >
                <TableCell
                  sx={{
                    textAlign: 'center',
                    paddingRight: theme.spacing(2),
                    width: 'calc(80%/6)',
                    cursor: 'default',
                    borderLeft: `1px solid ${grey[300]}`
                  }}
                  className={weekCellEffectiveClassName}
                  onClick={() =>
                    weekInfo &&
                    (studentCountAtThresholdForWeek > 0 || studentCountOverThresholdForWeek > 0) &&
                    void showDetail(
                      viewModel,
                      weekInfo.sectionId,
                      pageData.schoolDays[0],
                      pageData.schoolDays[pageData.schoolDays.length - 1],
                      weekInfo.studentIdsAtThreshold,
                      weekInfo.studentIdsOverThreshold
                    )
                  }
                >
                  <CourseOccurrenceInformationChip
                    hasCourseOccurrence={false}
                    hasPublishedTasks={publishedTasksCountForWeek > 0}
                    value={studentCountOverThresholdForWeek || studentCountAtThresholdForWeek || 0}
                    type={weekType}
                  />
                </TableCell>
              </Tooltip>
            </TableRow>
          );
        })}
    </TableBody>
  );
}

function renderValueTooltip(
  studentCountAtThreshold: number,
  studentCountOverThreshold: number,
  sectionColor: Color,
  localizationService: LocalizationService
) {
  if (studentCountAtThreshold === 0 && studentCountOverThreshold === 0) {
    return '';
  }

  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <Box display="flex" flexDirection="row">
      <Box mr="6px" borderLeft={`3px solid ${SectionColors.get(sectionColor)}`} />

      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Box marginRight={3}>
            <Typography>{strings.overloadedStudents}</Typography>
          </Box>
          <Box flex={1} />
          <Typography align="right" fontWeight={400}>
            {studentCountOverThreshold}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box marginRight={3}>
            <Typography>{strings.studentsAtThreshold}</Typography>
          </Box>
          <Box flex={1} />
          <Typography align="right" fontWeight={400}>
            {studentCountAtThreshold}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function renderFilterTooltip(localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom={1}>
        <span style={{ color: 'white' }}>{strings.filterTooltipContent}</span>
      </Box>

      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {ImportantContentIcons.filter((icon) => icon !== 'exam').map((icon) => (
          <TaskIcon key={icon} icon={icon} forcedIconColor="white" sx={{ mx: 0.5 }} />
        ))}
      </Box>
    </Box>
  );
}

function renderLegendTooltip(localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <Box sx={{ backgroundColor: WorkloadManagerStudentOverThresholdColor, width: 60, height: 32 }} />

        <Box marginLeft={1}>
          <Typography noWrap={true} sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.numberOfOverloadedStudents}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <Box sx={{ backgroundColor: WorkloadManagerStudentAtThresholdColor, width: 60, height: 32 }} />

        <Box marginLeft={1}>
          <Typography noWrap={true} sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.numberOfStudentsAtThreshold}
          </Typography>
        </Box>
      </Box>

      <Box marginY={1}>
        <Divider />
      </Box>

      <Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={true} hasPublishedTasks={true} value={0} />

        <Box marginLeft={1}>
          <Typography noWrap={true} sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.sectionOccursPublished}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={true} hasPublishedTasks={false} value={0} />

        <Box marginLeft={1}>
          <Typography noWrap={true} sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.sectionOccursNoPublished}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={false} hasPublishedTasks={true} value={0} />

        <Box marginLeft={1}>
          <Typography noWrap={true} sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.sectionNotOccursPublished}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

async function showDetail(
  viewModel: WorkloadManagerBySectionViewModel,
  sectionId: string,
  fromDay: SchoolDay,
  toDay: SchoolDay,
  studentIdsAtThreshold: string[],
  studentIdsOverThreshold: string[]
) {
  await viewModel.showDetail(sectionId, fromDay, toDay, studentIdsAtThreshold, studentIdsOverThreshold);
}
