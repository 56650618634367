import { AlertService, NavigationService } from '@insights/services';
import { AccountSummary } from '@shared/models/config';
import { UserProfile } from '@shared/models/user';
import { LocalizationService } from '@shared/resources/services';
import { UserStore } from '@shared/services/stores';
import { NavigateFunctionAsync } from '@shared/utils';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface UsersTableRowInfo {
  userProfile?: UserProfile;
  account?: AccountSummary;
  children?: UsersTableRowInfo[];
}

export interface UsersViewModel {
  userIdOrEmail: string;
  searchText: string;

  readonly isTextInputEnabled: boolean;
  readonly canSearch: boolean;
  readonly isSearching: boolean;

  readonly usersTableRowInfos: UsersTableRowInfo[];
  readonly partialResults: boolean;

  search(): Promise<void>;
  navigateToPlanner(configId: string, accountId: string): Promise<void>;
  editAccount(account: UsersTableRowInfo): Promise<void>;
  editSelectedSections(account: UsersTableRowInfo): Promise<void>;
  editSchool(configId: string, navigate: NavigateFunctionAsync): Promise<void>;
  deleteUser(user: UserProfile): Promise<void>;
}

export class AppUsersViewModel implements UsersViewModel {
  @observable private _userIdOrEmail = '';
  @observable private _searchText = '';
  @observable private _isSearching = false;
  @observable private _userProfiles: UserProfile[] = [];
  @observable private _partialResults = false;

  constructor(
    private readonly _userStore: UserStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
  }

  @computed
  get userIdOrEmail() {
    return this._userIdOrEmail;
  }
  set userIdOrEmail(value: string) {
    if (this.isTextInputEnabled) {
      this._userIdOrEmail = value.trim();
      this._searchText = '';
    }
  }

  @computed
  get searchText() {
    return this._searchText;
  }
  set searchText(value: string) {
    if (this.isTextInputEnabled) {
      this._searchText = value.trim();
      this._userIdOrEmail = '';
    }
  }

  @computed
  get isTextInputEnabled() {
    return !this._isSearching;
  }

  @computed
  get canSearch() {
    return !this._isSearching && (this._userIdOrEmail.length > 0 || this._searchText.length > 0);
  }

  @computed
  get isSearching() {
    return this._isSearching;
  }

  @computed
  get usersTableRowInfos(): UsersTableRowInfo[] {
    return _.chain(this._userProfiles)
      .orderBy(
        [
          (u) => _.max(u.accountSummaries.map((a) => a.configurationSummary?.startDay.asDateString ?? '')),
          (u) => (u.username.length > 0 ? u.username : 'zzzzz')
        ],
        ['desc', 'asc']
      )
      .flatMap((userProfile) =>
        userProfile.accountSummaries.length === 0
          ? [{ userProfile }]
          : _.chain(userProfile.accountSummaries)
              .orderBy(
                [(a) => a.configurationSummary?.startDay.year ?? 0, (a) => a.configurationSummary?.schoolName ?? ''],
                ['desc', 'asc']
              )
              .map<UsersTableRowInfo>((account) => ({
                userProfile,
                account,
                children: account.childrenAccountSummaries
                  .concat(account.childrenAccountPendingVerificationSummaries)
                  .map<UsersTableRowInfo>((child) => ({
                    account: child
                  }))
              }))
              .value()
      )
      .value();
  }

  @computed
  get partialResults() {
    return this._partialResults;
  }

  @action
  async search(): Promise<void> {
    if (!this.canSearch) {
      return;
    }

    try {
      this._isSearching = true;
      this._userProfiles = [];
      this._partialResults = false;

      let fetchedProfiles: UserProfile[] = [];
      let partialResults = false;

      if (this._userIdOrEmail.length > 0) {
        if (this._userIdOrEmail.includes('@')) {
          fetchedProfiles = await this._userStore.searchForUserProfilesWithEmail(this._userIdOrEmail);
        } else {
          const profile = await this._userStore.getUserProfileById(this._userIdOrEmail);
          fetchedProfiles = profile ? [profile] : [];
        }
      } else if (this._searchText.length > 0) {
        const result = await this._userStore.searchForUserProfilesWithText(this._searchText);
        fetchedProfiles = result.userProfiles;
        partialResults = result.partialResults;
      }

      runInAction(() => {
        this._userProfiles = fetchedProfiles;
        this._partialResults = partialResults;
      });
    } finally {
      runInAction(() => (this._isSearching = false));
    }
  }

  navigateToPlanner(configId: string, accountId: string): Promise<void> {
    return this._navigationService.navigateToPlannerExternal(configId, accountId);
  }

  async editAccount(account: UsersTableRowInfo): Promise<void> {
    if (account.account == null) {
      return;
    }

    const result = await this._navigationService.navigateToAccountEdition(account.account.configId, account.account.id);
    if (result !== 'cancelled') {
      await this.refreshAccount(account.account);
    }
  }

  async editSelectedSections(account: UsersTableRowInfo): Promise<void> {
    if (account.account == null) {
      return;
    }

    await this._navigationService.navigateToAccountSectionsEdition(account.account.configId, account.account.id);
  }

  editSchool(configId: string, navigate: NavigateFunctionAsync): Promise<void> {
    return this._navigationService.navigateToManageSchool(configId, navigate);
  }

  async deleteUser(user: UserProfile): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.users;

    if (
      (await this._alertService.showConfirmation({
        title: strings.deleteUserConfirmationTitle,
        message: strings.deleteUserConfirmationMessage(user.userId, user.email)
      })) !== 'cancelled'
    ) {
      try {
        await this._userStore.deleteUser(user.userId);
        await this.search();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.deleteUserErrorTitle,
          message: strings.deleteUserErrorMessage + (error as Error).message
        });
      }
    }
  }

  private async refreshAccount(account: AccountSummary): Promise<void> {
    const updatedAccount = await this._userStore.getUserProfileById(account.userId);
    if (updatedAccount == null) {
      runInAction(() => _.remove(this._userProfiles, { userId: account.userId }));
    } else {
      const index = _.findIndex(this._userProfiles, {
        userId: updatedAccount.userId
      });
      runInAction(() => (this._userProfiles[index] = updatedAccount!));
    }
  }
}
