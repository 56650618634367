import { AlertService } from '@insights/services';
import { EditableConcatenation, EditableImportSession, ImportSession, Schema } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IndexableSource } from './IndexableSource';
import { AppConcatenationSchemaViewModel, TargetSchemaAwareViewModel } from './TargetSchemaAwareViewModel';

export interface ImportSessionAddOrEditConcatenationDialogViewModel {
  readonly isNew: boolean;
  readonly availableSources: IndexableSource[];

  label: string;
  name: string;
  readonly labelError: string;
  sourceLabels: string[];
  columnCount: number;
  useColumnNames: boolean;

  readonly schemaViewModel: TargetSchemaAwareViewModel;

  readonly canSave: boolean;
  readonly isExecuting: boolean;

  saveConcatenation(): Promise<void>;
  cancel(): void;
}

export class AppImportSessionAddOrEditConcatenationDialogViewModel
  implements ImportSessionAddOrEditConcatenationDialogViewModel
{
  private readonly _editableSession: EditableImportSession;
  private readonly _editableConcatenation: EditableConcatenation;
  @observable private _isExecuting = false;

  private readonly _availableSources: IndexableSource[];
  private readonly _schemaViewModel: TargetSchemaAwareViewModel;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _configId: string,
    session: ImportSession,
    private readonly _existingConcatenationLabel: string,
    schemas: Schema[],
    private readonly _onSuccess: (session?: ImportSession) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);

    if (_existingConcatenationLabel.length === 0) {
      this._editableConcatenation = EditableConcatenation.createNew();
      this._editableSession.addConcatenation(this._editableConcatenation);
    } else {
      const existingConcatenation = this._editableSession.concatenations.find(
        (t) => t.label === this._existingConcatenationLabel
      );

      if (existingConcatenation == null) {
        throw new Error('Could not find a concatenation to edit with that label.');
      }

      this._editableConcatenation = existingConcatenation;
    }

    this._availableSources = this.createAvailableSources(session);
    this._schemaViewModel = new AppConcatenationSchemaViewModel(this._editableConcatenation, schemas);
  }

  get isNew() {
    return this._existingConcatenationLabel.length === 0;
  }

  get availableSources() {
    return this._availableSources;
  }

  @computed
  get label() {
    return this._editableConcatenation.label;
  }

  set label(value: string) {
    this._editableConcatenation.label = value.toUpperCase();
  }

  @computed
  get name() {
    return this._editableConcatenation.name;
  }

  set name(value: string) {
    this._editableConcatenation.name = value;
  }

  @computed
  get labelError() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    if (this._editableSession.expectedFiles.find((f) => f.label === this.label) != null) {
      return strings.labelInUseByFile;
    } else if (
      this._editableSession.concatenations
        .filter((t) => t !== this._editableConcatenation)
        .find((t) => t.label === this.label) != null
    ) {
      return strings.labelInUseByOtherConcatenation;
    } else if (this._editableSession.transformations.find((t) => t.label === this.label) != null) {
      return strings.labelInUseByTransformation;
    }

    return '';
  }

  @computed
  get sourceLabels() {
    return this._editableConcatenation.sourceLabels;
  }

  set sourceLabels(values: string[]) {
    this._editableConcatenation.sourceLabels = values;
  }

  @computed
  get columnCount(): number {
    return this._editableConcatenation.columnCount;
  }

  set columnCount(value: number) {
    if (!Number.isNaN(value) && value >= 0) {
      this._editableConcatenation.columnCount = value;
    }
  }

  @computed
  get useColumnNames(): boolean {
    return this._editableConcatenation.useColumnNames;
  }

  set useColumnNames(value: boolean) {
    this._editableConcatenation.useColumnNames = value;
  }

  get schemaViewModel() {
    return this._schemaViewModel;
  }

  @computed
  get canSave() {
    // We allow one-file concatenations, which can be used "in case of an extra file not yet received"
    return this.label.length > 0 && this.name.length > 0 && this.sourceLabels.length > 0;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @action
  async saveConcatenation(): Promise<void> {
    if (!this.canSave) {
      return;
    }

    this._isExecuting = true;

    try {
      // No need for the data in the origin screen.
      const newSession = await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      this._onSuccess(newSession);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  cancel(): void {
    this._onCancel();
  }

  private createAvailableSources(session: ImportSession): IndexableSource[] {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;

    const fileNames = new Map(session.expectedFiles.map((f) => [f.label, strings.fileName(f.name)]));
    const concatenationNames = new Map(session.concatenations.map((c) => [c.label, strings.concatenationName(c.name)]));
    const transformationNames = new Map(
      session.transformations.map((t) => [t.label, strings.transformationName(t.name)])
    );

    return session.data
      .filter((data) => data.label !== this._existingConcatenationLabel)
      .map((data) => ({
        label: data.label,
        name:
          fileNames.get(data.label) ??
          concatenationNames.get(data.label) ??
          transformationNames.get(data.label) ??
          data.label,
        columnCount: data.rows[0]?.columns.length || data.columnNames.length
      }));
  }
}
