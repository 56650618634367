import { SchoolCalendarViewModel } from '@insights/viewmodels';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Row } from '../layout';
import { CycleDaysDialog } from './CycleDaysDialog';
import { SchoolCalendarCycleDay } from './SchoolCalendarCycleDay';

export interface SchoolCalendarCycleDaysProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
}

export const SchoolCalendarCycleDays = observer((props: SchoolCalendarCycleDaysProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  const daysPerCycle = viewModel.daysPerCycle;

  return (
    <Column sx={sx} className={className} horizontalContentAlignment="stretch">
      <Row verticalContentAlignment="center">
        <Typography
          sx={{
            fontWeight: '500',
            mr: 1,
            my: 1
          }}
        >
          {strings.localizedNumberOfCycleDays(daysPerCycle)}
        </Typography>
        {viewModel.canEdit && (
          <IconButton onClick={() => viewModel.editCycleDays()}>
            <EditIcon />
          </IconButton>
        )}
      </Row>
      {viewModel.cycleDayRows.map((row, rowIndex) => (
        <Row key={`cycle-days-row-${rowIndex}`} sx={{ height: 60 }}>
          {row.map((day, dayIndex) => (
            <SchoolCalendarCycleDay
              key={`cycle-day-${dayIndex}`}
              viewModel={day}
              sx={{ padding: '5px', flexGrow: 1, flexBasis: 0, height: '100%' }}
              disabled={!viewModel.canEdit}
            />
          ))}
        </Row>
      ))}
      <CycleDaysDialog viewModel={viewModel} />
    </Column>
  );
});
