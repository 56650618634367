import { LoadingIndicator, OptionalRouterLink, PageHeaderBar, RouterLink, UserInfo } from '@insights/components';
import { accessorForSearch, useInsightsTable } from '@insights/components/InsightsTable';
import { RouteParamNames, RouteTemplates } from '@insights/Routes';
import DeletedIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Card, Chip, styled, SxProps, TextField, Tooltip, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { Role, RootAdminRoles, RootRoles } from '@shared/models/types';
import { useNavigateAsync } from '@shared/utils';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, CSSProperties, FormEvent, useMemo } from 'react';
import { MinLayoutWidth, ParentsColor, StudentsColor, TeachersColor, TeachersLightColor } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';

const TableStateKey = 'Users';

export interface UsersProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Users = observer((props: UsersProps) => {
  const { accountService, localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const isSuperAdmin = accountService.isAllowed(RootAdminRoles);
  const isSuperObserver = accountService.isAllowed(RootRoles);

  const viewModel = useMemo(() => viewModelFactory.createUsers(), []);
  const navigate = useNavigateAsync();

  function onUserIdOrEmailChange(event: ChangeEvent<HTMLInputElement>) {
    viewModel.userIdOrEmail = event.target.value;
  }

  function onSearchTextChange(event: ChangeEvent<HTMLInputElement>) {
    viewModel.searchText = event.target.value;
  }

  function onSearchClick(event: FormEvent) {
    event.preventDefault();
    void viewModel.search();
  }

  const table = useInsightsTable(
    TableStateKey,
    viewModel.usersTableRowInfos,
    strings.users,
    () => [
      {
        header: strings.username,
        accessorKey: 'userProfile.username',
        enableSorting: false,
        Cell: ({ row }) => row.original.userProfile && <UserInfo profile={row.original.userProfile} />
      },
      {
        header: strings.email,
        accessorKey: 'userProfile.email',
        enableSorting: false,
        Cell: ({ row, renderedCellValue }) => (
          <Box display="flex" flexDirection="row">
            <Box marginRight={0.5}>
              {row.original.userProfile?.isEmailConfirmed === true && <VerifiedUserIcon color={'secondary'} />}
            </Box>
            <Typography variant="body1">{renderedCellValue}</Typography>
          </Box>
        )
      },
      {
        header: strings.configuration,
        accessorFn: (row) => row.account?.configurationSummary?.schoolName ?? '',
        enableSorting: false,
        Cell: ({ row, renderedCellValue }) =>
          row.original.account?.configurationSummary != null && (
            <Box display="flex" flexDirection="column">
              <RouterLink
                to={reactRouterRouteService.resolveLocation(RouteTemplates.schoolRoot, [
                  {
                    name: RouteParamNames.configId,
                    value: row.original.account.configId
                  }
                ])}
                color="primary"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box marginRight={0.5}>
                    <Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main }}>
                      {renderedCellValue}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  >{`[${SchoolYearConfigurationUtils.displayTitle(
                    row.original.account.configurationSummary
                  )}]`}</Typography>
                </Box>
              </RouterLink>
              <code className={'identifier'}>{row.original.account.configId}</code>
            </Box>
          )
      },
      {
        header: strings.accounts,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayFirstLastName(row.account, ''),
            row.account?.email,
            row.account?.managedIdentifier
          ]),
        enableSorting: false,
        Cell: ({ row }) => {
          const isTeacherOrStudent =
            row.original.account &&
            (row.original.account.role === 'teacher' || row.original.account.role === 'student');

          return (
            row.original.account != null && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box marginRight={1}>
                  <Chip
                    color="primary"
                    variant="outlined"
                    classes={{
                      root: 'accountRoleChip',
                      label: 'accountRoleChipLabel',
                      outlined: chipClassFromRole(row.original.account.role)
                    }}
                    label={row.original.account.role}
                  />
                </Box>
                {row.original.account.isDeleted && (
                  <Tooltip title={strings.deletedAccount}>
                    <DeletedIcon className={'deletedIcon'} fontSize="small" color="error" />
                  </Tooltip>
                )}
                <Box display="flex" flexDirection="column">
                  <OptionalRouterLink
                    disabled={!isTeacherOrStudent}
                    color={isTeacherOrStudent ? 'primary' : undefined}
                    to={reactRouterRouteService.resolveLocation(
                      row.original.account.role === 'student'
                        ? RouteTemplates.studentDetails
                        : RouteTemplates.teacherDetails,
                      [
                        {
                          name: RouteParamNames.configId,
                          value: row.original.account.configId
                        },
                        {
                          name:
                            row.original.account.role === 'student'
                              ? RouteParamNames.studentId
                              : RouteParamNames.teacherId,
                          value: row.original.account.id
                        }
                      ]
                    )}
                  >
                    <Typography variant="body1" color={isTeacherOrStudent ? 'primary' : undefined}>
                      {AccountUtils.getDisplayFirstLastName(
                        row.original.account,
                        localizationService.localizedStrings.insights.noName
                      )}
                    </Typography>
                  </OptionalRouterLink>

                  {row.original.account.email.length > 0 && (
                    <Typography variant="body1">{row.original.account.email}</Typography>
                  )}
                  <code className={'identifier'}>{row.original.account.id}</code>
                </Box>
              </Box>
            )
          );
        }
      }
    ],
    {
      getRowActionMenuItems: ({ row }) => [
        isSuperObserver && {
          title: strings.viewPlanner,
          endAdornment: () => <OpenInNewIcon />,
          onClick: () => void viewModel.navigateToPlanner(row.original.account!.configId, row.original.account!.id),
          isDisabled: row.original.account == null
        },
        {
          title: strings.editAccountDetails,
          onClick: () => void viewModel.editAccount(row.original)
        },
        (row.original.account?.role === 'student' || row.original.account?.role === 'teacher') && {
          title: strings.editAccountSections,
          onClick: () => void viewModel.editSelectedSections(row.original)
        },
        isSuperObserver && {
          title: strings.manageThisSchool,
          onClick: () => void viewModel.editSchool(row.original.account!.configurationSummary!.id, navigate),
          isDisabled: row.original.account?.configurationSummary == null
        },
        isSuperAdmin &&
          row.original.userProfile != null && {
            title: strings.deleteUser,
            onClick: () => void viewModel.deleteUser(row.original.userProfile!),
            isDisabled: row.original.account != null
          }
      ],
      getSubRows: (row) => row.children ?? []
    }
  );

  return (
    <Root sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <Box padding={2} flex={1} display="flex" flexDirection="column">
        <Box paddingBottom={2}>
          <Card className={'card'}>
            <Box height="100%" width="100%">
              <form onSubmit={onSearchClick}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <TextField
                    label={strings.idOrEmail}
                    className={'textField'}
                    disabled={!viewModel.isTextInputEnabled}
                    autoFocus
                    value={viewModel.userIdOrEmail}
                    onChange={onUserIdOrEmailChange}
                  />
                  <TextField
                    label={strings.searchText}
                    className={'textField'}
                    disabled={!viewModel.isTextInputEnabled}
                    value={viewModel.searchText}
                    onChange={onSearchTextChange}
                  />
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    className={'button'}
                    disabled={!viewModel.canSearch}
                  >
                    {strings.search}
                  </Button>
                  {viewModel.isSearching && <LoadingIndicator size={'small'} />}
                </Box>
              </form>
            </Box>
          </Card>
        </Box>

        <Box flex={1} overflow="auto">
          <Card className={'card'}>
            <Box height="100%" width="100%">
              <MaterialReactTable table={table} />
            </Box>
          </Card>
        </Box>
      </Box>
    </Root>
  );
});

function chipClassFromRole(role: Role) {
  switch (role) {
    case 'teacher':
      return 'accountRoleChipOutlineTeacher';
    case 'student':
      return 'accountRoleChipOutlineStudent';
    case 'parent':
      return 'accountRoleChipOutlineParent';
    default:
      return 'accountRoleChipOutlineIndividual';
  }
}

const Root = styled(Box)(({ theme }) => ({
  '.card': {
    minWidth: MinLayoutWidth
  },
  '.viewPlannerIcon': {
    margin: 0,
    marginLeft: theme.spacing(3)
  },
  '.textField': {
    marginLeft: 8,
    marginRight: 8,
    width: 325
  },
  '.identifier': {
    color: theme.palette.grey[400]
  },
  '.accountRoleChip': {
    // This value is adequate for all possible roles in the current typography (accountRoleChipLabel below)
    minWidth: 80
  },
  '.deletedIcon': {
    marginRight: theme.spacing(1)
  },
  '.accountRoleChipOutlineTeacher': {
    borderColor: TeachersColor
  },
  '.accountRoleChipOutlineStudent': {
    borderColor: StudentsColor
  },
  '.accountRoleChipOutlineParent': {
    borderColor: ParentsColor
  },
  '.accountRoleChipOutlineIndividual': {
    borderColor: TeachersLightColor
  },
  accountRoleChipLabel: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight
  },
  '.button': {
    marginLeft: 8,
    marginRight: 8
  }
}));
