import { CustomizationData, SchoolCalendarDayOfWeekViewModel } from '@insights/viewmodels';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { Droppable } from '@shared/rxp/drag-drop';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Row } from '../layout';
import { CustomizationsList } from './CustomizationsList';

export interface SchoolCalendarDayOfWeekProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarDayOfWeekViewModel;
  disabled?: boolean;
}

export const SchoolCalendarDayOfWeek = observer((props: SchoolCalendarDayOfWeekProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className, disabled = false } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const dayName = localizationService.localizedStrings.models.dayOfWeek.shortLocalizedDayOfWeek(viewModel.dayOfWeek);

  const onDropCustomization = (data: unknown) => {
    setIsDraggingOver(false);
    // Safeguard
    if (!disabled) {
      void props.viewModel.applyCustomizations(data as CustomizationData);
    }
  };

  const editCustomizations = !disabled ? () => void props.viewModel.editCustomizations() : undefined;

  const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    flexDirection: 'column'
  };

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: isDraggingOver ? grey[600] : grey[200],
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: viewModel.hasCustomization ? yellow[100] : grey[100],
        ...sx
      }}
    >
      <Droppable
        acceptedType="customization"
        handleDrop={onDropCustomization}
        handleDragOver={() => setIsDraggingOver(true)}
        handleDragLeave={() => setIsDraggingOver(false)}
        sx={{ flex: 1 }}
      >
        <Tooltip title={viewModel.hasCustomization ? <CustomizationsList viewModel={viewModel} /> : ''}>
          <Box onClick={editCustomizations} style={containerStyle}>
            <Row sx={{ flex: 1 }} verticalContentAlignment="center">
              <Typography variant="subtitle2" sx={{ flex: 1, mr: 1 }}>
                {dayName}
              </Typography>

              {!viewModel.hasCustomization && <WarningIcon color="error" style={{ width: 20, height: 20 }} />}
            </Row>
          </Box>
        </Tooltip>
      </Droppable>
    </Box>
  );
});
