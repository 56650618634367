import { AccountService, AlertService, NavigationService } from '@insights/services';
import { EditableSchoolYearConfiguration } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppSchoolCalendarViewModel, SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface SchoolCalendarScreenViewModel {
  readonly configId: string;
  readonly calendar: IPromiseBasedObservable<SchoolCalendarViewModel>;
}

export class AppSchoolCalendarScreenViewModel implements SchoolCalendarScreenViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get calendar(): IPromiseBasedObservable<SchoolCalendarViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<SchoolCalendarViewModel> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    const schoolDays = await this._calendarStore.getVolatileSchoolDays(config, []);

    return new AppSchoolCalendarViewModel(
      this._accountService,
      this._localizationService,
      this._navigationService,
      this._alertService,
      this._schoolYearConfigurationStore,
      this._calendarStore,
      new EditableSchoolYearConfiguration(config),
      schoolDays
    );
  }
}
