import { FilterAlt } from '@mui/icons-material';
import { Box, List, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { Incident, SourceRow } from '@shared/models/import';
import { OrderedIncidentSeverities } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import _, { chain, compact } from 'lodash';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { useInsightsTable } from '../InsightsTable';
import { Scroll } from '../layout';
import { ImportDataIncident } from './ImportDataIncident';
import { ImportDataIncidentIcon, IncidentSize } from './ImportDataIncidentIcon';

const DefaultTableStateKey = 'ImportSessionSourceRowList';

type IncidentFilter = 'all-rows' | 'rows-with-incident' | 'rows-without-incident';

export interface ImportSessionSourceRowListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  rows: SourceRow[];
  useNonPropFont?: boolean;
  globalIncidents?: Incident[];
  columnNames: string[];
  showIncidents?: boolean;
  pageSize?: number;
  tableStateKey?: string;
}

export const ImportSessionSourceRowList = observer((props: ImportSessionSourceRowListProps) => {
  const {
    sx = [],
    className,
    style,
    title,
    rows,
    useNonPropFont = false,
    columnNames,
    showIncidents,
    pageSize,
    tableStateKey,
    globalIncidents = []
  } = props;
  const { localizationService } = useInsightsServices();

  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.components.import;
  const [incidentFilter, setIncidentFilter] = useState<IncidentFilter>('all-rows');

  const filledNames = columnNames.length > 0 ? columnNames : (rows[0]?.columns.map(() => '') ?? []);

  const incidentsSummary: Incident[] = useMemo(() => {
    return chain(rows)
      .map('incidents')
      .flatten()
      .concat(globalIncidents)
      .uniqBy('message')
      .sortBy('code', 'message')
      .value();
  }, [rows, globalIncidents]);

  const hasIncidents = useMemo(() => incidentsSummary.length > 0, [incidentsSummary]);

  function toggleIncidentFilter() {
    if (incidentFilter === 'all-rows') {
      setIncidentFilter('rows-with-incident');
    } else if (incidentFilter === 'rows-with-incident') {
      setIncidentFilter('rows-without-incident');
    } else {
      setIncidentFilter('all-rows');
    }
  }

  const table = useInsightsTable(
    tableStateKey ?? DefaultTableStateKey,
    filterRows(rows, incidentFilter),
    title ?? '',
    () => [
      showIncidents && {
        header: '',
        id: 'incidents',
        size: 40,
        Cell: ({ row }) => renderError(row.original)
      },
      ...filledNames.map<MRT_ColumnDef<SourceRow> | undefined | false>(
        (name, index) =>
          !name.startsWith('_') && {
            header: name.length === 0 ? `${index}` : `${index} : ${name.replace('~', '')}`,
            accessorFn: (row) => row.columns[index] ?? '',
            id: `${index}-${name}`,
            Cell: ({ row }) => {
              const column = row.original.columns[index] ?? '';

              const hasAppliedChanges = column.includes('►');
              const hasKeptChanges = column.includes('◄');

              return name.startsWith('~') ? (
                renderTooltipRow(column, localizationService)
              ) : hasAppliedChanges || hasKeptChanges ? (
                renderUpdatedRow(column, hasAppliedChanges, theme)
              ) : useNonPropFont ? (
                <pre>{column}</pre>
              ) : (
                column
              );
            }
          }
      )
    ],
    {
      getTopActionButtons: () => [
        showIncidents && {
          icon: () => <FilterAlt color={getIncidentFilterColor(incidentFilter)} />,
          tooltip: strings.toggleIncidentFilterTooltip,
          onClick: () => toggleIncidentFilter()
        }
      ],
      defaultPageSize: pageSize,
      fontSize: 'small'
    }
  );

  return (
    <Scroll sx={sx} className={className} style={style} direction="x">
      <Box>
        <MaterialReactTable table={table} />
      </Box>
      <Box>
        {showIncidents && hasIncidents && (
          <List
            subheader={
              <Typography variant={'subtitle2'} my={1}>
                {strings.incidentsSummary}
              </Typography>
            }
          >
            {renderIncidents(incidentsSummary, 'small')}
          </List>
        )}
      </Box>
    </Scroll>
  );
});

function renderIncidents(incidents: Incident[], size?: IncidentSize) {
  return incidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} size={size} colorMode="icon-incident-color" />
  ));
}

function renderUpdatedRow(row: string, hasAppliedChanges: boolean, theme: Theme) {
  const symbol = hasAppliedChanges ? '►' : '◄';
  const oldValueEndIndex = hasAppliedChanges ? row.indexOf(symbol) : row.indexOf(symbol) + 1;

  const oldValue = row.substring(0, oldValueEndIndex);
  const newValue = row.substring(oldValueEndIndex);

  return (
    <Box>
      <Box>{oldValue}</Box>
      <Box sx={{ color: hasAppliedChanges ? theme.palette.success.main : undefined }}>{newValue}</Box>
    </Box>
  );
}

function renderTooltipRow(row: string, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.components.import;

  const values = compact(row.split(','));
  const addedValues = values.filter((v) => v.startsWith('+'));
  const removedValues = values.filter((v) => v.startsWith('-'));
  const otherValues = values.filter((v) => !v.startsWith('+') && !v.startsWith('-'));

  let tooltip = '';

  if (addedValues.length > 0) {
    tooltip += `${strings.addedValues}:\n` + addedValues.map((v) => `* ${v}`).join('\n');
  }

  if (removedValues.length > 0) {
    if (tooltip.length > 0) {
      tooltip += '\n\n';
    }

    tooltip += `${strings.removedRows}:\n` + removedValues.map((v) => `* ${v}`).join('\n');
  }

  if (otherValues.length > 0) {
    if (tooltip.length > 0) {
      tooltip += '\n\n';
    }

    tooltip += `${strings.other}:\n` + otherValues.map((v) => `* ${v}`).join('\n');
  }

  if (tooltip.length === 0) {
    tooltip += strings.noValues;
  }

  return (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}>
      <div>{strings.values(values.length)}</div>
    </Tooltip>
  );
}

function renderError(row: SourceRow) {
  const highestSeverity = _.chain(row.incidents)
    .orderBy((i) => OrderedIncidentSeverities.indexOf(i.severity), 'desc')
    .first()
    .value()?.severity;

  return highestSeverity != null ? (
    <Tooltip title={renderIncidents(row.incidents)} placement="bottom-start">
      <Box>
        <ImportDataIncidentIcon severity={highestSeverity} size="small" />
      </Box>
    </Tooltip>
  ) : undefined;
}

function getIncidentFilterColor(filter: IncidentFilter): 'disabled' | 'error' | 'secondary' {
  switch (filter) {
    case 'all-rows':
      return 'disabled';
    case 'rows-with-incident':
      return 'error';
    case 'rows-without-incident':
      return 'secondary';
  }
}

function filterRows(rows: SourceRow[], filter: IncidentFilter): SourceRow[] {
  // We don't care about ids as rows aren't sortable, but toggling filters
  // should not generate same ids for different rows.
  switch (filter) {
    case 'rows-with-incident':
      return rows.filter((r) => r.incidents.length > 0);
    case 'rows-without-incident':
      return rows.filter((r) => r.incidents.length === 0);
    default:
      return rows;
  }
}
