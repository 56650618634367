import { ExternalAssociationViewModel, UnknownExternalSection } from '@insights/viewmodels';
import { LinkOff } from '@mui/icons-material';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/LinkOff';
import { Box, Input, InputAdornment, Popover, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { ExternalSection } from '@shared/models/connectors';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { useInsightsTable } from '../InsightsTable';
import { SectionName } from '../SectionName';
import { SectionSchedulesSummary } from '../sections';

const TableStateKey = 'EditableExternalSection';

export interface EditableExternalSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalAssociationViewModel;
  externalSections: ExternalSection[];
  onChange: (externalSectionId?: string) => void;
  disabled?: boolean;
}

export const EditableExternalSection = observer((props: EditableExternalSectionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel, externalSections, onChange, disabled = false } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const section = viewModel.section;

  const [popoverTarget, setPopoverTarget] = useState<HTMLDivElement | undefined>(undefined);
  const isOpen = popoverTarget != undefined;

  const theme = useTheme();

  function openPopover(target: HTMLDivElement) {
    setPopoverTarget(target);
  }

  function closePopover() {
    setPopoverTarget(undefined);
  }

  function selectSection(section: ExternalSection | undefined) {
    if (section == null) {
      onChange('');
    } else {
      onChange(section.id);
    }
    closePopover();
  }

  function getExternalSectionDisplayTitle(section?: ExternalSection | UnknownExternalSection) {
    return (
      section &&
      (section === 'unknown'
        ? localizationService.localizedStrings.insights.components.connectors.unknownExternalSection
        : `${section.title} [${section.code}]`)
    );
  }

  const title =
    section == null
      ? strings.orphanAssociationTitle
      : () => (
          <Stack sx={{ px: 2, width: '100%' }} direction="row" spacing={2} alignItems="center">
            <SectionName
              sx={{ flex: 1 }}
              title={section.title}
              color={section.color}
              subInformation1={section.importId}
              subInformation2={section.sectionNumber}
            />
            <SectionSchedulesSummary schedules={section.schedules} />
            {viewModel.teachers[0] && (
              <Typography variant="h6">{AccountUtils.getDisplayLastFirstName(viewModel.teachers[0], '')}</Typography>
            )}
          </Stack>
        );

  const table = useInsightsTable(
    TableStateKey,
    externalSections,
    title,
    () => [
      {
        header: strings.externalSectionTitle,
        accessorKey: 'title'
      },
      {
        header: strings.externalSectionCode,
        accessorKey: 'code'
      },
      {
        header: strings.externalSectionGroup,
        accessorKey: 'group',
        sortingFn: 'alphanumeric'
      },
      {
        header: strings.externalSectionLevel,
        accessorKey: 'level',
        sortingFn: 'alphanumeric'
      },
      {
        header: strings.externalSectionTerm,
        accessorKey: 'term'
      },
      {
        header: strings.externalSectionId,
        accessorKey: 'id'
      },
      {
        header: strings.externalSectionExtraData,
        accessorKey: 'extraData'
      }
    ],
    {
      selectionOptions: {
        getRowId: (row) => row.id,
        selectedRowIds: viewModel.externalSectionId != null ? [viewModel.externalSectionId] : [],
        onSelectionChanged: (ids) => onChange(ids?.length > 0 ? ids[0] : '')
      },
      getTopActionButtons: () => [
        {
          icon: () => <LinkOff />,
          tooltip: strings.detachTooltip,
          onClick: () => selectSection(undefined),
          isDisabled: viewModel.externalSection == null
        }
      ],
      defaultPageSize: 10,
      fontSize: 'small'
    }
  );

  return (
    <Box sx={sx} className={className}>
      <Input
        sx={{
          fontSize: theme.typography.fontSize,
          fontWeight: theme.typography.fontWeightLight,
          backgroundColor: isOpen ? '#eee' : undefined
        }}
        fullWidth
        readOnly
        value={getExternalSectionDisplayTitle(viewModel.externalSection) ?? strings.none}
        onClick={(event) => {
          if (viewModel.isBroken) {
            if (viewModel.hasAssociation) {
              viewModel.setExternalSection(undefined);
            }
          } else {
            openPopover(event.currentTarget);
          }
        }}
        endAdornment={
          !disabled && (
            <InputAdornment position="end">
              {viewModel.isBroken ? viewModel.hasAssociation ? <DeleteIcon /> : <></> : <ExpandIcon />}
            </InputAdornment>
          )
        }
        error={viewModel.isBroken}
        disabled={disabled}
      />
      <Popover
        open={isOpen}
        anchorEl={popoverTarget}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => closePopover()}
        slotProps={{ paper: { style: { width: '75%' } } }}
      >
        <MaterialReactTable table={table} />
      </Popover>
    </Box>
  );
});
