import { AccountUtils } from '@shared/components/utils/models/AccountUtils';
import { AuthorizationRoleComparer, getRoleWeight } from '@shared/models/AccountRoleComparer';
import { AccountSummary, SchoolYearConfigurationSummary } from '@shared/models/config';
import { AuthorizationRole, Day, PremiumFeature, Role } from '@shared/models/types';
import { authorizationRoleFromRole } from '@shared/models/types/EnumConversion';
import { UserProfile } from '@shared/models/user';
import { AuthenticationService, CompleteLoginResult } from '@shared/services';
import {
  CalendarStore,
  ConnectorsStore,
  ContentStore,
  ImporterStore,
  MetricsStore,
  SchoolYearConfigurationStore,
  UserStore
} from '@shared/services/stores';
import _, { chain, intersection, union } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { SettingsStore } from './SettingsStore';
import { InsightsAnalyticsService } from './analytics';

export interface SchoolYearConfigurationInfo {
  schoolConfiguration: SchoolYearConfigurationSummary;
  roles: AuthorizationRole[];
}

export interface AccountService {
  currentConfigId: string | undefined;

  readonly isLoggedIn: boolean;
  readonly userDisplayName: string;
  readonly userEmail: string;
  readonly isRootAdmin: boolean;
  readonly isRootObserver: boolean;
  readonly isRootAdminOrObserver: boolean;
  readonly highestUserRole: AuthorizationRole | undefined;
  readonly accountSummaries: AccountSummary[];
  readonly schoolConfigurations: SchoolYearConfigurationInfo[];
  readonly visibleSchoolConfigurations: SchoolYearConfigurationInfo[];
  readonly defaultConfiguration: SchoolYearConfigurationInfo | undefined;

  startSilentLogin: () => Promise<void>;
  login: (referrer?: string) => Promise<boolean>;
  completeLogin: () => Promise<CompleteLoginResult>;
  logout: () => Promise<void>;
  completeLogout: () => Promise<void>;

  isAllowed: (allowedRoles: AuthorizationRole[]) => boolean;
  isAllowedAll: (requiredRoles: AuthorizationRole[]) => boolean;
  isFeatureAllowed: (feature: PremiumFeature) => boolean;
  isAccount: (accountIds: string[]) => boolean;
  getAccountIdForConfigRole: (configId: string, role: Role) => string | undefined;
}

export class AppAccountService implements AccountService {
  @observable private _currentConfigId: string | undefined;
  @observable private _userProfile?: UserProfile;

  private _isLoggingInSilently = false;
  private _isLoggingIn = false;
  private _isCompletingLogin = false;

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _contentStore: ContentStore,
    private readonly _importSessionStore: ImporterStore,
    private readonly _metricsStore: MetricsStore,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _userStore: UserStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _authenticationService: AuthenticationService,
    private readonly _analyticsService: InsightsAnalyticsService
  ) {
    makeObservable(this);
    // We need to monitor the authentication service since it can log us
    // out by itself. In that case, we need to reset our states.
    reaction(
      () => this._authenticationService.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          // Nothing to do if authenticated
          return;
        }

        this.updateAnalyticsUserInfo();
        this.updateAnalyticsConfigInfo();
        void this.clearStores();
        this.resetStates();
      }
    );
  }

  @computed
  get currentConfigId(): string | undefined {
    return this._currentConfigId;
  }

  set currentConfigId(configId: string | undefined) {
    if (this._currentConfigId === configId) {
      return;
    }

    /**
     * Only clear the store when the config is changed and is not the first loaded config.
     * We don't clear on the first load since it would mean the setting store would never be persisted.
     */
    if (this._currentConfigId != null) {
      void this._settingsStore.clear();
    }

    this._currentConfigId = configId;
    this.updateAnalyticsConfigInfo();
  }

  @computed
  get isLoggedIn(): boolean {
    return (
      (this._userProfile != null || this._settingsStore.useIOSAccessTokenProvider) &&
      this._authenticationService.isAuthenticated
    );
  }

  @computed
  get userDisplayName(): string {
    return this.computeUserDisplayName();
  }

  @computed
  get userEmail(): string {
    return this.computeUserEmail();
  }

  @computed
  get isRootAdmin(): boolean {
    return this._userProfile?.userRole === 'root-admin-user';
  }

  @computed
  get isRootObserver(): boolean {
    return this._userProfile?.userRole === 'root-observer-user';
  }

  @computed
  get isRootAdminOrObserver(): boolean {
    return (
      (this._userProfile &&
        (this._userProfile.userRole === 'root-admin-user' || this._userProfile.userRole === 'root-observer-user')) ??
      false
    );
  }

  @computed
  get highestUserRole(): AuthorizationRole | undefined {
    const userRoles = this.getUserRoles(this._currentConfigId, this._settingsStore.demoMode);

    if (userRoles.length === 0) {
      return undefined;
    }

    // The roles are already sorted by priority
    return userRoles[0];
  }

  @computed
  get accountSummaries() {
    if (this._userProfile == null) {
      return [];
    }

    return this._userProfile.accountSummaries.filter((a) => a.role !== 'unknown');
  }

  @computed
  get schoolConfigurations(): SchoolYearConfigurationInfo[] {
    if (this._userProfile == null) {
      return [];
    }

    return chain(this.accountSummaries)
      .map((a) => a.configurationSummary)
      .compact()
      .uniqBy((c) => c.id)
      .orderBy((c) => [c.startDay.asDateString, c.schoolName], ['desc', 'asc'])
      .map((c) => ({
        schoolConfiguration: c,
        roles: this.getUserRoles(c.id, this._settingsStore.demoMode)
      }))
      .value();
  }

  @computed
  get visibleSchoolConfigurations(): SchoolYearConfigurationInfo[] {
    // since we do not have metrics for previous configs.
    return this.schoolConfigurations.filter((c) => c.schoolConfiguration.type === 'managed');
  }

  @computed
  get defaultConfiguration(): SchoolYearConfigurationInfo | undefined {
    const today = Day.fromDate(new Date())!;
    return (
      this.visibleSchoolConfigurations.reduce<SchoolYearConfigurationInfo | undefined>(
        (previous, current) => (current.schoolConfiguration.endDay.isBefore(today) ? previous : current),
        undefined
      ) ?? this.visibleSchoolConfigurations[0]
    );
  }

  async startSilentLogin(): Promise<void> {
    if (this._isLoggingInSilently) {
      throw new Error('Cannot call startSilentLogin while already logging in silently.');
    }

    try {
      this._isLoggingInSilently = true;

      await this._authenticationService.startSilentSigninFlow();

      if (this._authenticationService.isAuthenticated) {
        await this.loadUserProfile();
      }
    } catch (error) {
      console.error(`An error occurred while starting the silent login`, error);
    } finally {
      this._isLoggingInSilently = false;
      this.updateAnalyticsUserInfo();
    }
  }

  async login(referrer?: string): Promise<boolean> {
    if (this._isLoggingIn) {
      throw new Error('Cannot call login while logging in');
    }

    try {
      this._isLoggingIn = true;

      const loginResult = await this._authenticationService.login(referrer);

      if (loginResult) {
        await this.loadUserProfile();
      }

      return loginResult;
    } catch (error) {
      console.error(`An error occurred while logging in...`, error);

      // If an error occurred while loading the profile, ensure we are logged out.
      if (this._authenticationService.isAuthenticated) {
        await this.logout();
      }

      return false;
    } finally {
      this._isLoggingIn = false;
      this.updateAnalyticsUserInfo();
    }
  }

  async completeLogin(): Promise<CompleteLoginResult> {
    if (this._isCompletingLogin) {
      return { success: false };
    }

    try {
      this._isCompletingLogin = true;

      const loginResult = await this._authenticationService.completeLogin();

      if (loginResult) {
        await this.loadUserProfile();
      }

      return loginResult;
    } catch (error) {
      console.error(`An error occurred while logging in...`, error);

      // If an error occurred while loading the profile, ensure we are logged out.
      if (this._authenticationService.isAuthenticated) {
        await this.logout();
      }

      return { success: false };
    } finally {
      this._isCompletingLogin = false;
      this.updateAnalyticsUserInfo();
    }
  }

  async logout(): Promise<void> {
    await this._authenticationService.logout();
  }

  async completeLogout(): Promise<void> {
    await this._authenticationService.completeLogout();
  }

  isAllowed = computedFn((allowedRoles: AuthorizationRole[]): boolean => {
    // If there are any roles in the intersection of the allowed roles
    // and the user roles, this means the user have access.
    return (
      intersection(allowedRoles, this.getUserRoles(this._currentConfigId, this._settingsStore.demoMode)).length > 0
    );
  });

  isAllowedAll = computedFn((requiredRoles: AuthorizationRole[]): boolean => {
    return (
      union(requiredRoles, this.getUserRoles(this._currentConfigId, this._settingsStore.demoMode)).length ===
      requiredRoles.length
    );
  });

  isFeatureAllowed = computedFn((feature: PremiumFeature): boolean => {
    if (this._settingsStore.useIOSAccessTokenProvider) {
      // This means that we won't be able to disable some features when being embedded in the iOS app.
      // The reason is that we use the UserProfile which we can't load when using an access token
      // provided by iOS.
      return true;
    }

    // Root users don't have config summaries in their profiles, so we can't check disabled features.
    if (this.isRootAdminOrObserver) {
      return true;
    }

    const currentConfig = this.getCurrentConfig();

    if (currentConfig == null) {
      return false;
    }

    return !currentConfig.disabledFeatures.includes(feature);
  });

  isAccount = computedFn((accountIds: string[]): boolean => {
    // Because it's memoized, and there will rarely be more than 2 accountIds and few
    // accountSummaries, there is no need to optimize this with sets.
    return this.accountSummaries.find((a) => accountIds.find((id) => id === a.id) != null) != null;
  });

  getAccountIdForConfigRole(configId: string, role: Role): string | undefined {
    if (this._userProfile == null) {
      return undefined;
    }

    return chain(this.accountSummaries)
      .filter((accountSummary) => accountSummary.configId === configId && accountSummary.role === role)
      .map((accountSummary) => accountSummary.id)
      .head()
      .value();
  }

  private async loadUserProfile(): Promise<void> {
    if (this._settingsStore.useIOSAccessTokenProvider) {
      return;
    }

    const userProfile = await this._userStore.getUserProfile();
    runInAction(() => (this._userProfile = userProfile));
  }

  private async clearStores() {
    await Promise.all([
      this._calendarStore.clear(),
      this._connectorsStore.clear(),
      this._contentStore.clear(),
      this._importSessionStore.clear(),
      this._metricsStore.clear(),
      this._schoolYearConfigurationStore.clear(),
      this._userStore.clear(),
      this._settingsStore.clear()
    ]);
  }

  @action
  private resetStates() {
    this._isLoggingInSilently = false;
    this._isLoggingIn = false;
    this._isCompletingLogin = false;
    this._userProfile = undefined;
    this._currentConfigId = undefined;
  }

  private getUserRoles = computedFn((configId?: string, isDemoMode?: boolean): AuthorizationRole[] => {
    if (this._userProfile == null) {
      return [];
    }

    const roles = chain(this.accountSummaries)
      // When no configId is provided, we consider all roles.
      .filter((accountSummary) => configId == null || accountSummary.configId === configId)
      .map((accountSummary) =>
        accountSummary.isAdmin
          ? ['admin', authorizationRoleFromRole(accountSummary.role)]
          : [authorizationRoleFromRole(accountSummary.role)]
      )
      .flatten()
      .uniq()
      .value()
      .map((x) => x as AuthorizationRole)
      .sort(AuthorizationRoleComparer);

    if (isDemoMode === true) {
      // Do not include the "root admin" role, but make sure the admin role is there.
      return _.uniq(['admin', ...roles]);
    }

    switch (this._userProfile.userRole) {
      case 'root-admin-user':
        return ['super-admin', ...roles];
      case 'root-observer-user':
        return ['super-observer', ...roles];
      default:
        return roles;
    }
  });

  private getCurrentConfig(): SchoolYearConfigurationSummary | undefined {
    return (
      (this._userProfile &&
        chain(this.accountSummaries)
          .filter((accountSummary) => accountSummary.configId === this.currentConfigId)
          .map((accountSummary) => accountSummary.configurationSummary)
          .head()
          .value()) ??
      undefined
    );
  }

  private computeUserDisplayName(): string {
    if (this._userProfile == null) {
      return '';
    }

    const account = chain(this.accountSummaries)
      .filter((a) => a.configId === this._currentConfigId)
      .orderBy((a) => getRoleWeight(a.role), 'desc')
      .head()
      .value();

    if (account != null) {
      return AccountUtils.getDisplayFirstLastName(account, account.email);
    }

    return this._userProfile.email;
  }

  private computeUserEmail(): string {
    if (this._userProfile == null) {
      return '';
    }

    const account = chain(this.accountSummaries)
      .filter((a) => a.configId === this._currentConfigId)
      .orderBy((a) => getRoleWeight(a.role), 'desc')
      .head()
      .value();

    if (account != null) {
      return account.email;
    }

    return this._userProfile.email;
  }

  private updateAnalyticsUserInfo() {
    if (this._userProfile == null) {
      this._analyticsService.clearUserInfo();
      return;
    }

    this._analyticsService.setUserInfo({
      userId: this._userProfile.userId,
      intercomHash: this._userProfile.intercomHash,
      email: this._userProfile.email,
      userName: this._userProfile.username
    });
  }

  private updateAnalyticsConfigInfo() {
    if (this._userProfile == null || this._currentConfigId == null) {
      this._analyticsService.clearConfigInfo();
      return;
    }

    if (this.isRootAdminOrObserver) {
      this._analyticsService.setConfigInfo({
        configId: this._currentConfigId,
        accountId: this.isRootAdmin ? 'super-admin' : 'super-observer',
        accountRole: this.isRootAdmin ? 'super-admin' : 'super-observer',
        accountFullName: this._userProfile.email,
        schoolName: ''
      });
    } else {
      const currentAccount = chain(this.accountSummaries)
        .filter((a) => a.configId === this._currentConfigId)
        .orderBy((a) => getRoleWeight(a.role), 'desc')
        .head()
        .value();

      if (currentAccount == null) {
        this._analyticsService.clearConfigInfo();
        return;
      }

      this._analyticsService.setConfigInfo({
        configId: this._currentConfigId,
        accountId: currentAccount.id,
        accountRole: currentAccount.role,
        accountFullName: AccountUtils.getDisplayFirstLastName(currentAccount),
        schoolName: currentAccount.configurationSummary?.schoolName ?? ''
      });
    }
  }
}
