import { AccountService, AlertService, NavigationService } from '@insights/services';
import { SchoolDay } from '@shared/models/calendar';
import {
  DayConfigurationModel,
  EditableDayConfiguration,
  EditableSchedule,
  EditableSchoolYearConfiguration,
  EditableSpecialDay,
  EditableTerm,
  ScheduleModel,
  SpecialDayModel,
  TermModel
} from '@shared/models/config';
import { AdminAuthorizationRoles, AllDayOfWeek, Day, DayOfWeek } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ActivatableEditor, AppActivatableEditor } from './Editor';
import {
  AppSchoolCalendarCycleDayViewModel,
  EmptySchoolCalendarCycleDayViewModel,
  SchoolCalendarCycleDayViewModel
} from './SchoolCalendarCycleDayViewModel';
import {
  AppSchoolCalendarDayOfWeekViewModel,
  SchoolCalendarDayOfWeekViewModel
} from './SchoolCalendarDayOfWeekViewModel';
import { AppSchoolCalendarDayViewModel, SchoolCalendarDayViewModel } from './SchoolCalendarDayViewModel';
import {
  AppSchoolCalendarEditCycleDaysViewModel,
  SchoolCalendarEditCycleDaysViewModel
} from './SchoolCalendarEditCycleDaysViewModel';
import {
  AppSchoolCalendarImportFromViewModel,
  SchoolCalendarImportFromViewModel
} from './SchoolCalendarImportFromViewModel';
import { AppSchoolCalendarScheduleViewModel, SchoolCalendarScheduleViewModel } from './SchoolCalendarScheduleViewModel';
import {
  AppSchoolCalendarSpecialDayViewModel,
  SchoolCalendarSpecialDayViewModel
} from './SchoolCalendarSpecialDayViewModel';
import { AppSchoolCalendarTermViewModel, SchoolCalendarTermViewModel } from './SchoolCalendarTermViewModel';

export interface SchoolCalendarViewModel {
  readonly configId: string;
  readonly editableConfig: EditableSchoolYearConfiguration;

  readonly activeMonth: Day;
  readonly weeks: SchoolCalendarDayViewModel[][];
  readonly daysOfWeek: SchoolCalendarDayOfWeekViewModel[];
  readonly cycleDayRows: SchoolCalendarCycleDayViewModel[][];
  readonly specialDaysById: Record<string, SpecialDayModel>;
  readonly schedulesById: Record<string, ScheduleModel>;
  readonly daysPerCycle: number;
  readonly startDay: Day;
  readonly endDay: Day;

  // For special day edition picker
  readonly availableSchedules: ScheduleModel[];

  readonly dayConfigurationsByCycleDay: Record<number, DayConfigurationModel[]>;
  readonly dayConfigurationsByDay: Record<string, DayConfigurationModel[]>;
  readonly dayConfigurationsByDayOfWeek: Record<string, DayConfigurationModel[]>;
  readonly dayConfigurationsBySpecialDayId: Record<string, DayConfigurationModel[]>;
  readonly dayConfigurationsByScheduleId: Record<string, DayConfigurationModel[]>;

  readonly canEdit: boolean;
  readonly hasChanges: boolean;
  readonly isUpdating: boolean;
  readonly errorMessage?: string;

  readonly canGoToPreviousMonth: boolean;
  readonly canGoToNextMonth: boolean;

  goToPreviousMonth(): void;
  goToCurrentMonth(): void;
  goToNextMonth(): void;

  getCycleDay(cycleDayNb: number): SchoolCalendarCycleDayViewModel;

  removeSpecialDayFromDay(day: Day, specialDayId: string): Promise<void>;
  clearSpecialDaysFromDay(day: Day): Promise<void>;
  addSpecialDayToDay(day: Day, specialDay: SpecialDayModel, skipUpdate?: boolean): Promise<void>;

  removeSpecialDayFromDayOfWeek(dayOfWeek: DayOfWeek, specialDayId: string): Promise<void>;
  clearSpecialDaysFromDayOfWeek(dayOfWeek: DayOfWeek): Promise<void>;
  addSpecialDayToDayOfWeek(dayOfWeek: DayOfWeek, specialDay: SpecialDayModel): Promise<void>;

  removeSpecialDayFromCycleDay(cycleDay: number, specialDayId: string): Promise<void>;
  clearSpecialDaysFromCycleDay(cycleDay: number): Promise<void>;
  addSpecialDayToCycleDay(cycleDay: number, specialDay: SpecialDayModel): Promise<void>;

  removeScheduleFromDay(day: Day, scheduleId: string): Promise<void>;
  clearSchedulesFromDay(day: Day): Promise<void>;
  addScheduleToDay(day: Day, schedule: ScheduleModel): Promise<void>;

  removeScheduleFromDayOfWeek(dayOfWeek: DayOfWeek, scheduleId: string): Promise<void>;
  clearSchedulesFromDayOfWeek(dayOfWeek: DayOfWeek): Promise<void>;
  addScheduleToDayOfWeek(dayOfWeek: DayOfWeek, schedule: ScheduleModel): Promise<void>;

  removeScheduleFromCycleDay(cycleDay: number, scheduleId: string): Promise<void>;
  clearSchedulesFromCycleDay(cycleDay: number): Promise<void>;
  addScheduleToCycleDay(cycleDay: number, schedule: ScheduleModel): Promise<void>;

  readonly cycleDaysEditor: ActivatableEditor<EditableSchoolYearConfiguration, SchoolCalendarEditCycleDaysViewModel>;
  editCycleDays(): void;

  readonly specialDayEditor: ActivatableEditor<EditableSpecialDay, SchoolCalendarSpecialDayViewModel>;
  addSpecialDay(): void;
  editSpecialDay(specialDay: SpecialDayModel): void;
  deleteSpecialDay(specialDay: SpecialDayModel): Promise<void>;
  undeleteSpecialDay(specialDay: SpecialDayModel): Promise<void>;
  copySpecialDay(specialDay: SpecialDayModel): void;

  readonly scheduleEditor: ActivatableEditor<EditableSchedule, SchoolCalendarScheduleViewModel>;
  addSchedule(): void;
  editSchedule(schedule: ScheduleModel): void;
  deleteSchedule(schedule: ScheduleModel): Promise<void>;
  undeleteSchedule(schedule: ScheduleModel): Promise<void>;
  copySchedule(schedule: ScheduleModel): void;

  readonly termEditor: ActivatableEditor<EditableTerm, SchoolCalendarTermViewModel>;
  addTerm(): void;
  editTerm(term: TermModel): void;
  deleteTerm(term: TermModel): void;
  undeleteTerm(term: TermModel): void;

  readonly importFromEditor: SchoolCalendarImportFromViewModel;
  importFrom(): void;

  saveChanges(): Promise<void>;
  cancelChanges(): Promise<void>;

  updateSchoolDays(): Promise<void>;

  clone(copyChanges?: boolean): SchoolCalendarViewModel;
}

export class AppSchoolCalendarViewModel implements SchoolCalendarViewModel {
  @observable private _errorMessage?: string = undefined;
  @observable private _isUpdating = false;

  @observable private _schoolDays: SchoolDay[];
  @observable private _activeDay: Day;

  private readonly _cycleDaysEditor: ActivatableEditor<
    EditableSchoolYearConfiguration,
    SchoolCalendarEditCycleDaysViewModel
  >;
  private readonly _specialDayEditor: ActivatableEditor<EditableSpecialDay, SchoolCalendarSpecialDayViewModel>;
  private readonly _scheduleEditor: ActivatableEditor<EditableSchedule, SchoolCalendarScheduleViewModel>;
  private readonly _termEditor: ActivatableEditor<EditableTerm, SchoolCalendarTermViewModel>;
  private readonly _importFromEditor: SchoolCalendarImportFromViewModel;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    public readonly editableConfig: EditableSchoolYearConfiguration,
    private readonly _originalSchoolDays: SchoolDay[]
  ) {
    makeObservable(this);
    this._cycleDaysEditor = new AppActivatableEditor(
      this._alertService,
      this._localizationService,
      (viewModel, _, originalModel) => this.applyCycleDaysEdition(viewModel, originalModel)
    );
    this._specialDayEditor = new AppActivatableEditor(
      this._alertService,
      this._localizationService,
      (viewModel, _, originalModel) => this.applySpecialDayEdition(viewModel, originalModel)
    );
    this._scheduleEditor = new AppActivatableEditor(
      this._alertService,
      this._localizationService,
      (viewModel, _, originalModel) => this.applyScheduleEdition(viewModel, originalModel)
    );
    this._termEditor = new AppActivatableEditor(
      this._alertService,
      this._localizationService,
      (viewModel, _, originalModel) => this.applyTermEdition(viewModel, originalModel)
    );
    this._importFromEditor = new AppSchoolCalendarImportFromViewModel(
      _schoolYearConfigurationStore,
      _localizationService,
      editableConfig,
      // No need to wait.
      () => void this.updateSchoolDays()
    );

    this._schoolDays = _originalSchoolDays;
    this._activeDay = this.getStartingDay(_originalSchoolDays);
  }

  get configId() {
    return this.editableConfig.id;
  }

  @computed
  get activeMonth(): Day {
    return this._activeDay;
  }

  @computed
  get weeks(): SchoolCalendarDayViewModel[][] {
    const month = this._activeDay.month;
    const start = this._activeDay.firstDayOfWeek('sunday');
    const end = this._activeDay.lastDayOfMonth().lastDayOfWeek('sunday');

    // This index can be negative or beyond the last.
    const startIndex = this.findRelativeIndex(start);
    const endIndex = this.findRelativeIndex(end);

    // Getting an array item out of bound returns undefined.
    return Array.from(Array((endIndex - startIndex + 1) / 7).keys()).map((weekIndex) =>
      [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const day = this._schoolDays[weekIndex * 7 + dayIndex + startIndex];
        return new AppSchoolCalendarDayViewModel(
          this._navigationService,
          this._localizationService,
          day,
          day != null && day.day.month === month,
          this
        );
      })
    );
  }

  @computed
  get daysOfWeek(): SchoolCalendarDayOfWeekViewModel[] {
    return AllDayOfWeek.map(
      (dayOfWeek) =>
        new AppSchoolCalendarDayOfWeekViewModel(this._navigationService, this._localizationService, dayOfWeek, this)
    );
  }

  @computed
  get cycleDayRows(): SchoolCalendarCycleDayViewModel[][] {
    const daysPerCycle = this.editableConfig.daysPerCycle;
    const titles = this.editableConfig.cycleDayTitles;

    // Each row must have 7 elements. The last one can contain placeholders.
    return _.range(1, Math.ceil(daysPerCycle / 7) * 7 + 1).reduce((rows, cycleDay) => {
      const vm =
        cycleDay > daysPerCycle
          ? new EmptySchoolCalendarCycleDayViewModel(this)
          : new AppSchoolCalendarCycleDayViewModel(
              this._navigationService,
              this._localizationService,
              cycleDay,
              titles[cycleDay - 1] ?? '',
              this
            );

      if (cycleDay % 7 === 1) {
        const row = [vm] as SchoolCalendarCycleDayViewModel[];
        rows.push(row);
      } else {
        rows[rows.length - 1].push(vm);
      }

      return rows;
    }, [] as SchoolCalendarCycleDayViewModel[][]);
  }

  @computed
  get specialDaysById(): Record<string, SpecialDayModel> {
    return _.keyBy(this.editableConfig.specialDays, (s) => s.id);
  }

  @computed
  get schedulesById(): Record<string, ScheduleModel> {
    return _.keyBy(this.editableConfig.schedules, (s) => s.id);
  }

  @computed
  get availableSchedules(): ScheduleModel[] {
    return this.editableConfig.schedules;
  }

  @computed
  get dayConfigurationsByCycleDay(): Record<number, DayConfigurationModel[]> {
    return _.groupBy(
      this.editableConfig.dayConfigurations.filter((dc) => dc.cycleDay != null && dc.cycleDay > 0),
      (dc) => dc.cycleDay!
    );
  }

  @computed
  get dayConfigurationsByDay(): Record<string, DayConfigurationModel[]> {
    return _.groupBy(
      this.editableConfig.dayConfigurations.filter((dc) => dc.day != null),
      (dc) => dc.day!.asString
    );
  }

  @computed
  get dayConfigurationsByDayOfWeek(): Record<string, DayConfigurationModel[]> {
    return _.groupBy(
      this.editableConfig.dayConfigurations.filter((dc) => dc.dayOfWeek != null),
      (dc) => dc.dayOfWeek!
    );
  }

  @computed
  get dayConfigurationsBySpecialDayId() {
    return _.groupBy(
      this.editableConfig.dayConfigurations.filter((dc) => dc.specialDayId.length > 0),
      (dc) => dc.specialDayId
    );
  }

  @computed
  get dayConfigurationsByScheduleId() {
    return _.groupBy(
      this.editableConfig.dayConfigurations.filter((dc) => dc.scheduleId.length > 0),
      (dc) => dc.scheduleId
    );
  }

  @computed
  get daysPerCycle() {
    return this.editableConfig.daysPerCycle;
  }

  @computed
  get startDay() {
    return this.editableConfig.startDay;
  }

  @computed
  get endDay() {
    return this.editableConfig.endDay;
  }

  @computed
  get canEdit() {
    return this._accountService.isAllowed(AdminAuthorizationRoles);
  }

  @computed
  get hasChanges() {
    return this.editableConfig.hasChanges;
  }

  @computed
  get isUpdating() {
    return this._isUpdating;
  }

  @computed
  get errorMessage() {
    return this._errorMessage;
  }

  @computed
  get canGoToPreviousMonth() {
    // Because the active day is always on the first day of the month, it's an easy math.
    return this._schoolDays.length > 0 && this._schoolDays[0].day.isBefore(this._activeDay);
  }

  @computed
  get canGoToNextMonth() {
    const length = this._schoolDays.length;
    const nextMonth = this._activeDay.addMonths(1);

    return length > 0 && this._schoolDays[length - 1].day.isSameOrAfter(nextMonth);
  }

  @action
  goToPreviousMonth() {
    if (this.canGoToPreviousMonth) {
      this._activeDay = this._activeDay.addMonths(-1);
    }
  }

  @action
  goToNextMonth() {
    if (this.canGoToNextMonth) {
      this._activeDay = this._activeDay.addMonths(1);
    }
  }

  @action
  goToCurrentMonth() {
    this._activeDay = this.getStartingDay(this._schoolDays);
  }

  getCycleDay(cycleDayNb: number): SchoolCalendarCycleDayViewModel {
    const cycleDay = _(this.cycleDayRows).flatten().find({ cycleDay: cycleDayNb });

    if (cycleDay == null) {
      throw new Error(`Could not find cycle day ${cycleDayNb}`);
    }

    return cycleDay;
  }

  @action
  async removeSpecialDayFromDay(day: Day, specialDayId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByDay[day.asString], {
      specialDayId
    });
    if (dayConfiguration == null) {
      throw new Error(`Could not find day configuration with specialDayId "${specialDayId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSpecialDaysFromDay(day: Day): Promise<void> {
    (this.dayConfigurationsByDay[day.asString] || [])
      .filter((dc) => dc.specialDayId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addSpecialDayToDay(day: Day, specialDay: SpecialDayModel, skipUpdate?: boolean): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithSpecialDayForDay(day, specialDay);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    if (skipUpdate !== true) {
      await this.updateSchoolDays();
    }
  }

  @action
  async removeSpecialDayFromDayOfWeek(dayOfWeek: DayOfWeek, specialDayId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByDayOfWeek[dayOfWeek], { specialDayId });
    if (dayConfiguration == null) {
      throw new Error(`Could not find day of the week configuration with specialDayId "${specialDayId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSpecialDaysFromDayOfWeek(dayOfWeek: DayOfWeek): Promise<void> {
    (this.dayConfigurationsByDayOfWeek[dayOfWeek] || [])
      .filter((dc) => dc.specialDayId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addSpecialDayToDayOfWeek(dayOfWeek: DayOfWeek, specialDay: SpecialDayModel): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithSpecialDayForDayOfWeek(dayOfWeek, specialDay);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    await this.updateSchoolDays();
  }

  @action
  async removeSpecialDayFromCycleDay(cycleDay: number, specialDayId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByCycleDay[cycleDay], { specialDayId });
    if (dayConfiguration == null) {
      throw new Error(`Could not find cycle day configuration with specialDayId "${specialDayId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSpecialDaysFromCycleDay(cycleDay: number): Promise<void> {
    (this.dayConfigurationsByCycleDay[cycleDay] || [])
      .filter((dc) => dc.specialDayId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addSpecialDayToCycleDay(cycleDay: number, specialDay: SpecialDayModel): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithSpecialDayForCycleDay(cycleDay, specialDay);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    await this.updateSchoolDays();
  }

  @action
  async removeScheduleFromDay(day: Day, scheduleId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByDay[day.asString], {
      scheduleId
    });
    if (dayConfiguration == null) {
      throw new Error(`Could not find day configuration with scheduleId "${scheduleId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSchedulesFromDay(day: Day): Promise<void> {
    (this.dayConfigurationsByDay[day.asString] || [])
      .filter((dc) => dc.scheduleId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addScheduleToDay(day: Day, schedule: ScheduleModel): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithScheduleForDay(day, schedule);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    await this.updateSchoolDays();
  }

  @action
  async removeScheduleFromDayOfWeek(dayOfWeek: DayOfWeek, scheduleId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByDayOfWeek[dayOfWeek], { scheduleId });
    if (dayConfiguration == null) {
      throw new Error(`Could not find day of the week configuration with scheduleId "${scheduleId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSchedulesFromDayOfWeek(dayOfWeek: DayOfWeek): Promise<void> {
    (this.dayConfigurationsByDayOfWeek[dayOfWeek] || [])
      .filter((dc) => dc.scheduleId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addScheduleToDayOfWeek(dayOfWeek: DayOfWeek, schedule: ScheduleModel): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithScheduleForDayOfWeek(dayOfWeek, schedule);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    await this.updateSchoolDays();
  }

  @action
  async removeScheduleFromCycleDay(cycleDay: number, scheduleId: string): Promise<void> {
    const dayConfiguration = _.find(this.dayConfigurationsByCycleDay[cycleDay], { scheduleId });
    if (dayConfiguration == null) {
      throw new Error(`Could not find cycle day configuration with scheduleId "${scheduleId}"`);
    }

    this.editableConfig.getEditableDayConfiguration(dayConfiguration).markAsDeleted();

    await this.updateSchoolDays();
  }

  @action
  async clearSchedulesFromCycleDay(cycleDay: number): Promise<void> {
    (this.dayConfigurationsByCycleDay[cycleDay] || [])
      .filter((dc) => dc.scheduleId.length > 0)
      .forEach((dc) => this.editableConfig.getEditableDayConfiguration(dc).markAsDeleted());

    await this.updateSchoolDays();
  }

  @action
  async addScheduleToCycleDay(cycleDay: number, schedule: ScheduleModel): Promise<void> {
    const dayConfiguration = EditableDayConfiguration.createWithScheduleForCycleDay(cycleDay, schedule);
    this.editableConfig.addDayConfiguration(dayConfiguration);

    await this.updateSchoolDays();
  }

  //
  // Cycle days edition
  //

  @computed
  get cycleDaysEditor() {
    return this._cycleDaysEditor;
  }

  @action
  editCycleDays(): void {
    // We need to apply changes from the original config to make sure deleted day configurations are preserved.
    const copy = this.editableConfig.clone();
    copy.copyChangesFrom(this.editableConfig);

    this._cycleDaysEditor.edit(
      new AppSchoolCalendarEditCycleDaysViewModel(this._localizationService, copy),
      this.editableConfig
    );
  }

  private async applyCycleDaysEdition(
    viewModel: SchoolCalendarEditCycleDaysViewModel,
    originalModel?: EditableSchoolYearConfiguration
  ) {
    if (originalModel == null) {
      throw new Error('Invalid operation. Cannot edit cycle days in a "add" way.');
    }

    originalModel.copyChangesFrom(viewModel.editableConfig);

    // No need to wait.
    void this.updateSchoolDays();
    return Promise.resolve();
  }

  //
  // Special days
  //

  get specialDayEditor() {
    return this._specialDayEditor;
  }

  @action
  addSpecialDay(): void {
    this._specialDayEditor.edit(
      new AppSchoolCalendarSpecialDayViewModel(this._localizationService, this, EditableSpecialDay.createNew())
    );
  }

  editSpecialDay(specialDay: SpecialDayModel): void {
    const editable = this.editableConfig.getEditableSpecialDay(specialDay);
    const occurrences = this.editableConfig.dayConfigurations.filter((c) => c.specialDayId == specialDay.id);

    this._specialDayEditor.edit(
      new AppSchoolCalendarSpecialDayViewModel(this._localizationService, this, editable.clone(), occurrences),
      editable
    );
  }

  @action
  async deleteSpecialDay(specialDay: SpecialDayModel): Promise<void> {
    this.editableConfig.deleteSpecialDay(specialDay);

    await this.updateSchoolDays();
  }

  @action
  async undeleteSpecialDay(specialDay: SpecialDayModel): Promise<void> {
    this.editableConfig.undeleteSpecialDay(specialDay);

    await this.updateSchoolDays();
  }

  private async applySpecialDayEdition(
    viewModel: SchoolCalendarSpecialDayViewModel,
    originalModel?: EditableSpecialDay
  ) {
    if (viewModel.isNew) {
      this.editableConfig.addSpecialDay(viewModel.editableSpecialDay);
    } else {
      if (originalModel == null) {
        throw new Error('Invalid operation: No original model, yet the view-model is not new.');
      }

      originalModel.copyChangesFrom(viewModel.editableSpecialDay);
    }

    // Edition doesn't have to wait for school days! Not awaited.
    void this.updateSchoolDays();
    return Promise.resolve();
  }

  @action
  copySpecialDay(specialDay: SpecialDayModel): void {
    this._specialDayEditor.edit(
      new AppSchoolCalendarSpecialDayViewModel(
        this._localizationService,
        this,
        EditableSpecialDay.cloneAsNew(specialDay, true)
      )
    );
  }

  //
  // Schedules
  //

  get scheduleEditor() {
    return this._scheduleEditor;
  }

  @action
  addSchedule(): void {
    this._scheduleEditor.edit(
      new AppSchoolCalendarScheduleViewModel(
        this._localizationService,
        EditableSchedule.createNew(),
        this.editableConfig
      )
    );
  }

  @action
  editSchedule(schedule: ScheduleModel): void {
    const editable = this.editableConfig.getEditableSchedule(schedule);
    this._scheduleEditor.edit(
      new AppSchoolCalendarScheduleViewModel(this._localizationService, editable.clone(), this.editableConfig),
      editable
    );
  }

  @action
  async deleteSchedule(schedule: ScheduleModel): Promise<void> {
    this.editableConfig.deleteSchedule(schedule);

    await this.updateSchoolDays();
  }

  @action
  async undeleteSchedule(schedule: ScheduleModel): Promise<void> {
    this.editableConfig.undeleteSchedule(schedule);

    await this.updateSchoolDays();
  }

  private async applyScheduleEdition(viewModel: SchoolCalendarScheduleViewModel, originalModel?: EditableSchedule) {
    viewModel.sortPeriods();

    if (viewModel.isNew) {
      this.editableConfig.addSchedule(viewModel.editableSchedule);
    } else {
      if (originalModel == null) {
        throw new Error('Invalid operation: No original model, yet the view-model is not new.');
      }

      originalModel.copyChangesFrom(viewModel.editableSchedule);
    }

    // Any new tag added to a schedule's tags is added to the the school's allowed tags.
    // A message like "Create 'foobar' schedule tag" appears to the user.
    viewModel.tags
      .filter((tag) => !this.editableConfig.allowedScheduleTags.includes(tag))
      .forEach((tag) => this.editableConfig.addAllowedScheduleTag(tag));

    // Edition doesn't have to wait for school days! Not awaited.
    void this.updateSchoolDays();
    return Promise.resolve();
  }

  @action
  copySchedule(schedule: ScheduleModel): void {
    this._scheduleEditor.edit(
      new AppSchoolCalendarScheduleViewModel(
        this._localizationService,
        EditableSchedule.cloneAsNew(schedule, true),
        this.editableConfig
      )
    );
  }

  //
  // Terms
  //

  @computed
  get termEditor() {
    return this._termEditor;
  }

  @action
  addTerm(): void {
    // TODO: Find next logical term dates and tag
    this._termEditor.edit(
      new AppSchoolCalendarTermViewModel(
        this._localizationService,
        EditableTerm.createNew('', this.editableConfig.startDay, this.editableConfig.endDay)
      )
    );
  }

  @action
  editTerm(term: TermModel): void {
    const editable = this.editableConfig.getEditableTerm(term);
    this._termEditor.edit(new AppSchoolCalendarTermViewModel(this._localizationService, editable.clone()), editable);
  }

  @action
  deleteTerm(term: TermModel): void {
    this.editableConfig.deleteTerm(term);
  }

  @action
  undeleteTerm(term: TermModel): void {
    this.editableConfig.undeleteTerm(term);
  }

  private async applyTermEdition(viewModel: SchoolCalendarTermViewModel, originalModel?: EditableTerm) {
    if (viewModel.isNew) {
      // The terms property is calculated.
      this.editableConfig.addTerm(viewModel.editableTerm);
    } else {
      if (originalModel == null) {
        throw new Error('Invalid operation: No original model, yet the view-model is not new.');
      }

      originalModel.copyChangesFrom(viewModel.editableTerm);
    }

    // Terms don't affect school days.
    return Promise.resolve();
  }

  //
  // Import
  //

  get importFromEditor() {
    return this._importFromEditor;
  }

  @action
  importFrom(): void {
    this._importFromEditor.isActive = true;
  }

  @action
  async saveChanges(): Promise<void> {
    this._errorMessage = undefined;

    // TODO: Find a way to stay on the same date. (memory settings service)
    // TODO: This will eventually call invalidate. Would be nice to continue work on returned
    //       config instead. This could be addressed by ConfigurationData (see AccountData).
    try {
      await this._schoolYearConfigurationStore.saveConfig(this.editableConfig);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.calendar;
      runInAction(() => (this._errorMessage = `${strings.unexpectedError}: ${(error as Error).message}`));
    }
  }

  @action
  async cancelChanges(): Promise<void> {
    this.editableConfig.resetChanges();
    this._errorMessage = undefined;

    await this.updateSchoolDays();
  }

  @action
  async updateSchoolDays() {
    this._isUpdating = true;
    this._errorMessage = undefined;

    try {
      const schoolDays = await this._calendarStore.getVolatileSchoolDays(this.editableConfig, []);
      runInAction(() => {
        this._schoolDays = schoolDays;
        this._activeDay = this.boundDay(this._activeDay, schoolDays);
        this._isUpdating = false;
      });
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.calendar;
      console.error((error as Error).message);

      // We expose the system message.
      runInAction(() => {
        this._errorMessage = `${strings.unexpectedError}: ${(error as Error).message}`;
        this._isUpdating = false;
      });
    }
  }

  clone(copyChanges = false): SchoolCalendarViewModel {
    const editableConfig = this.editableConfig.clone();
    if (copyChanges) {
      editableConfig.copyChangesFrom(this.editableConfig);
    }

    return new AppSchoolCalendarViewModel(
      this._accountService,
      this._localizationService,
      this._navigationService,
      this._alertService,
      this._schoolYearConfigurationStore,
      this._calendarStore,
      editableConfig,
      this._originalSchoolDays
    );
  }

  private getStartingDay(schoolDays: SchoolDay[]) {
    return this.boundDay(dateService.today, schoolDays);
  }

  private boundDay(day: Day, schoolDays: SchoolDay[]) {
    if (schoolDays.length != 0) {
      const minDay = schoolDays[0].day;
      const maxDay = schoolDays[schoolDays.length - 1].day;

      if (day.isBefore(minDay)) {
        day = minDay;
      } else if (day.isAfter(maxDay)) {
        day = maxDay;
      }
    }

    return day.firstDayOfMonth();
  }

  private findRelativeIndex(day: Day) {
    // School days returned by the generator are always continuous, without duplicates nor missing days.
    if (this._schoolDays.length == 0) {
      return -1;
    }

    return this._schoolDays[0].day.dayCountUntil(day);
  }
}
