import { AlertService } from '@insights/services';
import { EditableImportSession, EditableTransformation, ImportSession, Schema } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IndexableSource } from './IndexableSource';
import { AppTransformationSchemaViewModel, TargetSchemaAwareViewModel } from './TargetSchemaAwareViewModel';
import {
  AppTransformationIndexedSourcesViewModel,
  TransformationIndexedSourcesViewModel
} from './TransformationIndexedSourcesViewModel';

export interface ImportSessionAddOrEditTransformationDialogViewModel {
  readonly isNew: boolean;
  readonly availableSources: IndexableSource[];

  label: string;
  readonly labelError: string;
  name: string;
  description: string;
  sourceLabel: string;

  readonly indexedSourcesViewModel: TransformationIndexedSourcesViewModel;
  readonly schemaViewModel: TargetSchemaAwareViewModel;

  readonly canSave: boolean;
  readonly isExecuting: boolean;

  saveTransformation(): Promise<void>;
  cancel(): void;
}

export class AppImportSessionAddOrEditTransformationDialogViewModel
  implements ImportSessionAddOrEditTransformationDialogViewModel
{
  private readonly _editableSession: EditableImportSession;
  private readonly _editableTransformation: EditableTransformation;
  @observable private _isExecuting = false;

  private readonly _availableSources: IndexableSource[];
  private readonly _indexedSourcesViewModel: TransformationIndexedSourcesViewModel;
  private readonly _schemaViewModel: TargetSchemaAwareViewModel;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _configId: string,
    session: ImportSession,
    private readonly _existingTransformationLabel: string,
    schemas: Schema[],
    private readonly _onSuccess: (session?: ImportSession) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);

    if (_existingTransformationLabel.length === 0) {
      this._editableTransformation = EditableTransformation.createNew();
      this._editableSession.addTransformation(this._editableTransformation);
    } else {
      const existingTransformation = this._editableSession.transformations.find(
        (t) => t.label === this._existingTransformationLabel
      );

      if (existingTransformation == null) {
        throw new Error('Could not find a transformation to edit with that label.');
      }

      this._editableTransformation = existingTransformation;
    }

    this._availableSources = this.createAvailableSources(session);

    this._indexedSourcesViewModel = new AppTransformationIndexedSourcesViewModel(
      this._editableTransformation,
      this._availableSources
    );
    this._schemaViewModel = new AppTransformationSchemaViewModel(this._editableTransformation, schemas);
  }

  get isNew() {
    return this._existingTransformationLabel.length === 0;
  }

  get availableSources() {
    return this._availableSources;
  }

  @computed
  get label() {
    return this._editableTransformation.label;
  }

  set label(value: string) {
    this._editableTransformation.label = value.toUpperCase();
  }

  @computed
  get labelError() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    if (this._editableSession.expectedFiles.find((f) => f.label === this.label) != null) {
      return strings.labelInUseByFile;
    } else if (this._editableSession.concatenations.find((c) => c.label === this.label) != null) {
      return strings.labelInUseByConcatenation;
    } else if (
      this._editableSession.transformations
        .filter((t) => t !== this._editableTransformation)
        .find((t) => t.label === this.label) != null
    ) {
      return strings.labelInUseByOtherTransformation;
    }

    return '';
  }

  @computed
  get name() {
    return this._editableTransformation.name;
  }

  set name(value: string) {
    this._editableTransformation.name = value;
  }

  @computed
  get description(): string {
    return this._editableTransformation.description;
  }

  set description(value: string) {
    this._editableTransformation.description = value;
  }

  @computed
  get sourceLabel() {
    return this._editableTransformation.sourceLabel;
  }

  set sourceLabel(value: string) {
    this._editableTransformation.sourceLabel = value;
  }

  get schemaViewModel() {
    return this._schemaViewModel;
  }

  get indexedSourcesViewModel() {
    return this._indexedSourcesViewModel;
  }

  @computed
  get canSave() {
    if (this.label.length === 0 || this.name.length === 0 || this.sourceLabel.length === 0) {
      return false;
    }

    return this._schemaViewModel.canSave;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @action
  async saveTransformation(): Promise<void> {
    if (!this.canSave) {
      return;
    }

    this._isExecuting = true;

    try {
      // No need for the data in the origin screen.
      const newSession = await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      this._onSuccess(newSession);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  cancel(): void {
    this._onCancel();
  }

  private createAvailableSources(session: ImportSession): IndexableSource[] {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;

    const fileNames = new Map(session.expectedFiles.map((f) => [f.label, strings.fileName(f.name)]));
    const concatenationNames = new Map(session.concatenations.map((c) => [c.label, strings.concatenationName(c.name)]));
    const transformationNames = new Map(
      session.transformations.map((t) => [t.label, strings.transformationName(t.name)])
    );

    return session.data
      .filter((data) => data.label !== this._existingTransformationLabel)
      .map((data) => ({
        label: data.label,
        name:
          fileNames.get(data.label) ??
          concatenationNames.get(data.label) ??
          transformationNames.get(data.label) ??
          data.label,
        columnCount: data.rows[0]?.columns.length || data.columnNames.length
      }));
  }
}
