import { SectionUtils } from '@insights/utils';
import { AccountEditionViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import AddAutoEnrolledIcon from '@mui/icons-material/AddCircle';
import { Box, SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { SectionName } from '../SectionName';

const TableStateKey = 'AccountSectionPicker';

export interface AccountSectionPickerProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  account: AccountEditionViewModel;
}

export const AccountSectionPicker = observer((props: AccountSectionPickerProps) => {
  const { localizationService } = useInsightsServices();
  const tableStateKey = `${TableStateKey}-${props.account.editableAccount.role}`;
  const { sx = [], className, style, account } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  const table = useInsightsTable(
    tableStateKey,
    account.availableSections,
    strings.availableSections,
    () => [
      {
        header: strings.title,
        accessorFn: (row) =>
          accessorForSearch([
            row.section?.title,
            row.section?.importId,
            AccountUtils.getDisplayLastFirstName(account.teachersById[row.section?.defaultTeacherId ?? ''], '')
          ]),
        id: 'title',
        Cell: ({ row }) => (
          <SectionName
            title={SectionUtils.formatTitle(row.original.section, strings.unknownSection)}
            color={row.original.section?.color}
            subInformation1={row.original.section?.importId}
            subInformation2={AccountUtils.getDisplayLastFirstName(
              account.teachersById[row.original.section?.defaultTeacherId ?? ''],
              ''
            )}
          />
        )
      },
      {
        header: strings.gradeTitle,
        accessorKey: 'section.gradeLevel',
        id: 'gradeLevel',
        sortingFn: 'alphanumeric'
      },
      {
        header: strings.groupTitle,
        accessorKey: 'section.sectionNumber',
        id: 'sectionNumber',
        sortingFn: 'alphanumeric'
      },
      {
        header: strings.associationTitle,
        accessorFn: (row) => row.section?.associatedSectionNumbers.join(','),
        id: 'associatedSectionNumbers',
        sortingFn: 'alphanumeric'
      }
    ],
    {
      getRowActionButtons: ({ row }) => {
        const hasAutoEnrollment =
          row.original.section != null &&
          (row.original.section.autoEnrollRoles.length > 0 || row.original.section.autoEnrollTags.length > 0);

        return [
          {
            icon: () => (hasAutoEnrollment ? <AddAutoEnrolledIcon fontSize="small" /> : <AddIcon fontSize="small" />),
            tooltip: hasAutoEnrollment ? strings.hasAutoEnrollment : '',
            disabled: row.original.section == null,
            onClick: () => account.selectSection(row.original.section?.id ?? '')
          }
        ];
      },
      defaultSorting: { id: 'title', desc: false }
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
    </Box>
  );
});
