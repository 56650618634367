import { AuthorizationRoleCondition, ObservablePresenter, PageHeaderBar } from '@insights/components';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  IconButton,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { AdminAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { SchoolYearConfigurationAssessmentPlanning } from './SchoolYearConfigurationAssessmentPlanning';
import { SchoolYearConfigurationDates } from './SchoolYearConfigurationDates';
import { SchoolYearConfigurationInformation } from './SchoolYearConfigurationInformation';
import { SchoolYearConfigurationLinks } from './SchoolYearConfigurationLinks';
import { SchoolYearConfigurationPowerTools } from './SchoolYearConfigurationPowerTools';
import { SchoolYearConfigurationWorkloadManagerThresholds } from './SchoolYearConfigurationWorkloadManagerThresholds';

export interface SchoolYearConfigurationScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SchoolYearConfigurationScreen = observer((props: SchoolYearConfigurationScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolYearConfigurationScreen(configId), [configId]);

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.errorMessage}
          render={(data) => (
            <Grid2
              container
              sx={{
                alignItems: 'stretch'
              }}
            >
              {/* Information */}
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1, display: 'block' }}>
                    <CardHeader
                      title={strings.informationCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <Tooltip title={strings.editInformationButtonTooltip}>
                            <IconButton onClick={() => void viewModel.editInformation(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <SchoolYearConfigurationInformation
                        data={data}
                        onEditIndividualAccount={(id) => viewModel.editIndividualAccount(id)}
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>

              {/* Dates */}
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1, display: 'block' }}>
                    <CardHeader
                      title={strings.datesCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <Tooltip title={strings.editDatesButtonTooltip}>
                            <IconButton onClick={() => void viewModel.editDates(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <SchoolYearConfigurationDates data={data} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>

              {/* Workload Manager Thresholds */}
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1, display: 'block' }}>
                    <CardHeader
                      title={strings.workloadManagerThresholdsCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <Tooltip title={strings.editWorkloadManagerThresholdsButtonTooltip}>
                            <IconButton onClick={() => void viewModel.editThresholds(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <SchoolYearConfigurationWorkloadManagerThresholds data={data} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>

              {/* Links */}
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1, display: 'block' }}>
                    <CardHeader
                      title={strings.linksCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <Tooltip title={strings.editLinksButtonTooltip}>
                            <IconButton onClick={() => void viewModel.editLinks(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <SchoolYearConfigurationLinks data={data} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>

              {/* Assessment Dates */}
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1, display: 'block' }}>
                    <CardHeader
                      title={strings.assessmentPlanningCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <Tooltip title={strings.editAssessmentPlanningButtonTooltip}>
                            <IconButton
                              onClick={() => {
                                void viewModel.editAssessmentPlanning(data.schoolYearConfiguration);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <SchoolYearConfigurationAssessmentPlanning data={data} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>

              {/* Power tools */}
              <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex'
                    }}
                  >
                    <Card sx={{ flex: 1, display: 'block' }}>
                      <CardHeader title={strings.powerToolsCardTitle} />
                      <Divider />
                      <CardContent>
                        <SchoolYearConfigurationPowerTools
                          viewModel={viewModel}
                          schoolYearConfiguration={data.schoolYearConfiguration}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Grid2>
              </AuthorizationRoleCondition>

              {/* Validation results */}
              <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                <Grid2 size={{ xs: 12 }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex'
                    }}
                  >
                    <Card sx={{ flex: 1, display: 'block' }}>
                      <CardHeader title={strings.validationResultsCardTitle} />
                      <Divider />
                      <CardContent>
                        {data.validationResults == null && strings.validationResultsNotAvailable}
                        {(data.validationResults?.length ?? 0) === 0 && strings.validationResultsNoErrors}
                        {(data.validationResults?.length ?? 0) > 0 && (
                          <ul>
                            {data.validationResults!.map((validationMessage, i) => (
                              <li key={`validation-message-${i}`}>
                                <Typography variant="caption">{validationMessage}</Typography>
                              </li>
                            ))}
                          </ul>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </Grid2>
              </AuthorizationRoleCondition>
            </Grid2>
          )}
        />
      </Box>
    </Box>
  );
});
