import { SectionModel } from '@shared/models/config';
import { AccountInfo } from './AccountInfo';

export interface SectionInfo {
  readonly sectionId: string;
  readonly section: SectionModel | undefined;
  readonly teachers?: AccountInfo[];
  readonly students?: AccountInfo[];
  readonly isAutoEnrolled?: boolean;
  readonly isLockedAsStudent?: boolean;
}

export function sectionInfoFromModel(section: SectionModel): SectionInfo {
  return { sectionId: section.id, section };
}
