import { SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors.ts';
import { Color } from '@shared/models/types';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { SectionNameOneLine } from './SectionNameOneLine.tsx';
import { SectionNameTwoLine } from './SectionNameTwoLine.tsx';
import { Thresholds } from './ThresholdsInformation';

export interface SectionNameProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title: string | ReactNode;
  subInformation1?: string;
  subInformation2?: string;
  thresholds?: Thresholds;
  color?: Color;
  size?: 'big' | 'medium' | 'normal' | 'small';
  mode?: 'two-lines' | 'one-line';
  fontWeight?: string;
  linkTo?: string;
}

export function SectionName(props: SectionNameProps) {
  const { mode, size, color } = props;
  const theme = useTheme();

  const titleStyle: CSSProperties = useMemo(() => {
    const isBig = size && size === 'big';
    const isMedium = size && size === 'medium';
    const isSmall = size && size === 'small';

    return {
      fontSize: isBig
        ? '2em'
        : isMedium
          ? '1.5em'
          : isSmall
            ? theme.typography.body2.fontSize
            : theme.typography.body1.fontSize,
      fontWeight: 300,
      lineHeight: 'normal'
    };
  }, [size, theme]);

  const secondaryInfoStyle: CSSProperties = useMemo(() => {
    const isBig = size && size === 'big';

    return {
      fontSize: isBig ? '1.1em' : theme.typography.body2.fontSize,
      fontWeight: 300,
      lineHeight: 'normal'
    };
  }, [size, theme]);

  const sectionColor = useMemo(() => (color ? (SectionColors.get(color) ?? '#00000000') : '#00000000'), [color]);

  if (mode && mode === 'one-line') {
    return (
      <SectionNameOneLine
        {...props}
        titleStyle={titleStyle}
        subInformationStyle={secondaryInfoStyle}
        sectionColor={sectionColor}
      />
    );
  }

  return (
    <SectionNameTwoLine
      {...props}
      titleStyle={titleStyle}
      subInformationStyle={secondaryInfoStyle}
      sectionColor={sectionColor}
    />
  );
}
