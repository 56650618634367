import { SectionUtils } from '@insights/utils';
import { ExternalAssociationListViewModel } from '@insights/viewmodels';
import { AutoMatchAssociationsDialog } from '@insights/views';
import TestIcon from '@mui/icons-material/Biotech';
import UnmatchAllIcon from '@mui/icons-material/ClearAll';
import ApplyAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import FilterIcon from '@mui/icons-material/FilterList';
import AutoMatchIcon from '@mui/icons-material/FindReplace';
import ErrorNotificationSettingsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThrottleIcon from '@mui/icons-material/Timer';
import ForgetIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Badge,
  Button,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { Day, RootAdminRoles } from '@shared/models/types';
import { MaterialReactTable } from 'material-react-table';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { accessorForSearch, useInsightsTable } from '../InsightsTable.tsx';
import { MoreActionMenu } from '../MoreActionMenu';
import { SectionName } from '../SectionName';
import { Column, LoadingContainer } from '../layout';
import { DateTimePickerLocalizationProvider } from '../utils';
import { EditableExternalSection } from './EditableExternalSection';
import { EditableOwner } from './EditableOwner';
import { ExternalAssociationListItemLastUpdate } from './ExternalAssociationListItemLastUpdate.tsx';

const TableStateKey = 'ExternalAssociationList';

export interface ExternalAssociationListProps {
  sx?: SxProps;
  viewModel: ExternalAssociationListViewModel;
  className?: string;
  style?: CSSProperties;
}

export const ExternalAssociationList = observer((props: ExternalAssociationListProps) => {
  const { accountService, localizationService } = useInsightsServices();
  const { className, style, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  const isRootAdmin = accountService.isRootAdmin;
  const isRootObserver = accountService.isRootObserver;

  const table = useInsightsTable(
    TableStateKey,
    viewModel.associations,
    () => (
      <Observer>
        {() => (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              type="date"
              label={strings.defaultMinDate}
              value={viewModel.defaultMinimumDate?.asDateString}
              onChange={(e) => {
                const day = Day.fromDateString(e.target.value);
                if (day != null) {
                  viewModel.defaultMinimumDate = day;
                }
              }}
              slotProps={{ input: { inputProps: { className: 'dateInput' } }, inputLabel: { shrink: true } }}
            />
            <TextField
              type="date"
              label={strings.defaultMaxDate}
              value={viewModel.defaultMaximumDate?.asDateString}
              onChange={(e) => {
                const day = Day.fromDateString(e.target.value);
                if (day != null) {
                  viewModel.defaultMaximumDate = day;
                }
              }}
              slotProps={{ input: { inputProps: { className: 'dateInput' } }, inputLabel: { shrink: true } }}
            />

            <Tooltip title={strings.applyToAll}>
              <IconButton
                disabled={viewModel.associations.length === 0 || isRootObserver}
                onClick={() => viewModel.applyDatesToAll(viewModel.defaultMinimumDate, viewModel.defaultMaximumDate)}
                style={{ marginRight: 48 }}
              >
                <ApplyAllIcon />
              </IconButton>
            </Tooltip>
            {(viewModel.isDataIncomplete || viewModel.hasFailedAssociations) && (
              <Tooltip
                title={viewModel.isDataIncomplete ? strings.incompleteDataTooltip : strings.failedAssociationsTooltip}
              >
                {viewModel.canContactOwnerAboutBrokenConnection ? (
                  <IconButton
                    style={{ marginLeft: 24 }}
                    onClick={() => void viewModel.contactOwnerAboutBrokenConnection()}
                  >
                    <ErrorIcon color={viewModel.isDataIncomplete ? 'error' : 'warning'} />
                  </IconButton>
                ) : (
                  <ErrorIcon style={{ marginLeft: 24 }} />
                )}
              </Tooltip>
            )}
            <MoreActionMenu
              badgeProps={{
                variant: 'dot',
                invisible: !viewModel.isSnoozed && !viewModel.hasThrottling,
                color: 'primary'
              }}
              disabled={isRootObserver}
            >
              {!viewModel.isLinkOnly && (
                <>
                  <MenuItem onClick={() => viewModel.startAutoMatch()}>
                    <ListItemIcon>
                      <AutoMatchIcon />
                    </ListItemIcon>
                    <ListItemText primary={strings.autoMatchButton} />
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.showScheduledAutoMatchSettings()}>
                    <ListItemIcon>
                      <AutoMatchIcon />
                    </ListItemIcon>
                    <ListItemText primary={strings.scheduledAutoMatchButton} />
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={() => viewModel.unmatchAll()} style={{ marginRight: 48 }}>
                <ListItemIcon>
                  <UnmatchAllIcon />
                </ListItemIcon>
                <ListItemText primary={strings.unmatchAllButton} />
              </MenuItem>
              <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                <MenuItem onClick={() => void viewModel.showEditErrorNotificationSettings()}>
                  <ListItemIcon>
                    <Badge variant="dot" invisible={!viewModel.isSnoozed} color="primary">
                      <ErrorNotificationSettingsIcon />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={strings.errorNotificationSettingsButton} />
                </MenuItem>
                <MenuItem onClick={() => void viewModel.showEditThrottleSettings()}>
                  <ListItemIcon>
                    <Badge variant="dot" invisible={!viewModel.hasThrottling} color="primary">
                      <ThrottleIcon />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={strings.throttleSettingsButton} />
                </MenuItem>
                <MenuItem onClick={() => void viewModel.forgetTasks()}>
                  <ListItemIcon>
                    <ForgetIcon />
                  </ListItemIcon>
                  <ListItemText primary={strings.forgetAboutAllTasks} />
                </MenuItem>
              </AuthorizationRoleCondition>
            </MoreActionMenu>
          </Stack>
        )}
      </Observer>
    ),
    () => [
      {
        header: strings.section,
        accessorFn: (row) => accessorForSearch([row.section?.title, row.section?.importId]),
        id: 'section',
        Cell: ({ row }) =>
          row.original.section == null ? (
            <Typography>{strings.none}</Typography>
          ) : (
            <SectionName
              title={SectionUtils.formatTitle(row.original.section, strings.noTitle)}
              color={row.original.section.color}
              subInformation1={row.original.section.importId}
              subInformation2={row.original.section.sectionNumber}
            />
          )
      },
      {
        header: strings.externalSection,
        accessorFn: (row) =>
          viewModel.isLinkOnly
            ? row.externalLink
            : row.externalSection === 'unknown'
              ? ''
              : (row.externalSection?.title ?? ''),
        id: 'externalSection',
        Cell: ({ row }) =>
          viewModel.isLinkOnly ? (
            <Input
              value={row.original.externalLink}
              onChange={(event) =>
                row.original.setExternalLink(
                  event.target.value,
                  viewModel.defaultMinimumDate,
                  viewModel.defaultMaximumDate
                )
              }
              disabled={isRootObserver}
            />
          ) : (
            <EditableExternalSection
              viewModel={row.original}
              externalSections={viewModel.externalSections}
              onChange={(externalSectionId) =>
                row.original.setExternalSection(
                  externalSectionId,
                  viewModel.defaultMinimumDate,
                  viewModel.defaultMaximumDate
                )
              }
              disabled={isRootObserver}
            />
          )
      },
      {
        header: strings.owner,
        accessorFn: (row) => AccountUtils.getDisplayLastFirstName(row.owner, ''),
        id: 'owner',
        Cell: ({ row }) => (
          <Observer>
            {() =>
              row.original.hasExternalSection ? (
                <EditableOwner viewModel={row.original} disabled={isRootObserver} />
              ) : (
                <></>
              )
            }
          </Observer>
        )
      },
      {
        header: strings.minMaxDates,
        id: 'minMaxDates',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Observer>
            {() =>
              row.original.hasExternalSection ? (
                <Stack direction="row" spacing={1}>
                  <TextField
                    type="date"
                    value={row.original.minimumDate?.asDateString}
                    onChange={(e) => (row.original.minimumDate = Day.fromDateString(e.target.value))}
                    label=""
                    slotProps={{ input: { inputProps: { className: 'dateInput' } } }}
                    disabled={isRootObserver}
                  />
                  <TextField
                    type="date"
                    value={row.original.maximumDate?.asDateString}
                    onChange={(e) => (row.original.maximumDate = Day.fromDateString(e.target.value))}
                    label=""
                    slotProps={{ input: { inputProps: { className: 'dateInput' } } }}
                    disabled={isRootObserver}
                  />
                </Stack>
              ) : (
                <></>
              )
            }
          </Observer>
        )
      },
      {
        header: strings.lastUpdate,
        id: 'lastUpdate',
        enableColumnActions: false,
        Cell: ({ row }) => <ExternalAssociationListItemLastUpdate association={row.original} />
      }
    ],
    {
      getTopActionButtons: () => [
        {
          icon: () => (
            <Badge badgeContent={viewModel.hasFilters ? 1 : 0} color="primary" showZero={false} variant="dot">
              <FilterIcon />
            </Badge>
          ),
          tooltip: strings.filtersTooltip,
          onClick: () => void viewModel.showFilters()
        }
      ],

      getRowActionButtons: ({ row }) => {
        const areDisabled =
          !row.original.hasAssociation || !row.original.hasExternalSection || row.original.isSyncing || isRootObserver;

        return [
          {
            icon: () => <RefreshIcon />,
            tooltip: strings.sync,
            onClick: () => void row.original.syncAssociation(false),
            isDisabled: areDisabled
          },
          row.original.hasAssociation &&
            isRootAdmin && {
              icon: () => <ForgetIcon />,
              tooltip: strings.forgetAboutAssociationTasks,
              onClick: () => void viewModel.forgetTasks(row.original.associationId),
              isDisabled: areDisabled
            },
          row.original.hasAssociation &&
            isRootAdmin && {
              icon: () => <TestIcon />,
              tooltip: strings.testSync,
              onClick: () => void row.original.testAssociation(),
              isDisabled: areDisabled
            }
        ];
      },
      renderCustomTopActions: () => (
        <Observer>
          {() => (
            <Stack direction="row" alignItems="center" spacing={2}>
              {viewModel.warning && (
                <Tooltip title={viewModel.warning}>
                  <WarningIcon color="warning" />
                </Tooltip>
              )}
              <Button
                variant="contained"
                color="secondary"
                disabled={!viewModel.hasChanges || isRootObserver}
                onClick={() => void viewModel.applyChanges()}
              >
                {strings.save}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!viewModel.hasChanges}
                onClick={() => viewModel.resetChanges()}
              >
                {strings.discard}
              </Button>
            </Stack>
          )}
        </Observer>
      ),
      defaultSorting: { id: 'section', desc: false }
    }
  );

  return (
    <LoadingContainer
      sx={sx}
      className={className}
      style={style}
      isOpen={viewModel.hasOperation}
      isBusy={viewModel.isBusy}
      title={strings.savingChanges}
      errorMessage={viewModel.errorMessage}
      onDismissError={() => viewModel.dismissError()}
    >
      <Column>
        <DateTimePickerLocalizationProvider>
          <MaterialReactTable table={table} />
          <AutoMatchAssociationsDialog viewModel={viewModel} />
        </DateTimePickerLocalizationProvider>
      </Column>
    </LoadingContainer>
  );
});
