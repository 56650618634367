import { AccountInfo } from '@insights/models';
import { SectionTeachersEditionViewModel } from '@insights/viewmodels';
import { Add, Delete } from '@mui/icons-material';
import DefaultTeacherIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { colors, Grid2, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';

const SelectedTableStateKey = 'SectionTeachersEditionSelected';
const AvailableTableStateKey = 'SectionTeachersEditionAvailable';

export interface SectionTeachersEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionTeachersEditionViewModel;
}

export const SectionTeachersEdition = observer((props: SectionTeachersEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const insightsStrings = localizationService.localizedStrings.insights;

  const selectedTable = useInsightsTable(
    SelectedTableStateKey,
    viewModel.selectedTeachers,
    strings.selectedTeachers,
    () => [
      {
        header: '',
        id: 'indicators',
        size: 40,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <TeacherIndicatorsColumn
            viewModel={viewModel}
            localizationService={localizationService}
            rowData={row.original}
          />
        )
      },
      {
        header: strings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <Delete />,
          tooltip: strings.removeTeacher,
          onClick: () => viewModel.removeTeacher(row.original.account)
        }
      ],
      disableGlobalFilter: true,
      disablePagination: true,
      defaultSorting: { id: 'name', desc: false }
    }
  );

  const availableTable = useInsightsTable(
    AvailableTableStateKey,
    viewModel.availableTeachers,
    strings.availableTeachers,
    () => [
      {
        header: strings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <Add />,
          tooltip: strings.addTeacher,
          onClick: () => void viewModel.addTeacher(row.original.account)
        }
      ],
      defaultPageSize: 10,
      defaultSorting: { id: 'name', desc: false }
    }
  );

  return (
    <Grid2 sx={sx} className={className} style={style} container spacing={1}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={selectedTable} />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={availableTable} />
      </Grid2>
    </Grid2>
  );
});

function TeacherIndicatorsColumn({
  viewModel,
  localizationService,
  rowData
}: {
  viewModel: SectionTeachersEditionViewModel;
  localizationService: LocalizationService;
  rowData: AccountInfo;
}) {
  const strings = localizationService.localizedStrings.insights.components.sections;
  const isDefaultTeacher = viewModel.defaultTeacher?.id === rowData.account.id;
  const isTeachingAllSchedules = viewModel.allSchedulesTeacherIds.has(rowData.account.id);

  return (
    <>
      {isDefaultTeacher ? (
        <Tooltip title={strings.defaultTeacherTooltip}>
          <DefaultTeacherIcon sx={{ color: colors.green['500'], m: 1.5 }} />
        </Tooltip>
      ) : (
        <IconButton onClick={() => viewModel.setDefaultTeacher(rowData.account.id)}>
          <DefaultTeacherIcon sx={{ color: colors.grey['500'] }} />
        </IconButton>
      )}
      {!isTeachingAllSchedules && (
        <Tooltip title={strings.notTeachingAllSchedulesInfo}>
          <InfoIcon />
        </Tooltip>
      )}
    </>
  );
}
