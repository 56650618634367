import { SectionInfo } from '@insights/models';
import { SectionUtils } from '@insights/utils';
import { SectionsViewModel } from '@insights/viewmodels';
import { Add, ArrowDownward, CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material';
import EnrollmentIcon from '@mui/icons-material/HowToReg';
import { Box, Snackbar, Stack, SxProps, Tooltip } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { AdminAuthorizationRoles, AdminOrTeacherAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import { compact, isArray } from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { SectionListTeachersCell } from '../SectionListTeachersCell';
import { SectionName } from '../SectionName';
import { SectionSchedulesSummary } from './SectionSchedulesSummary';

const TableStateKey = 'EditableSectionList';

export interface EditableSectionListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  sections: SectionsViewModel;
  usePagination?: boolean;
}

export const EditableSectionList = observer((props: EditableSectionListProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { sx = [], configId, className, style, title, sections, usePagination = true } = props;

  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;
  const canCopyIds = accountService.isAllowed(RootAdminRoles);

  const [showToast, setShowToast] = useState(false);
  const [selectionEnabled, setSelectionEnabled] = useState(false);

  const canAdd = accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canMultiEdit = accountService.isAllowed(AdminAuthorizationRoles);
  const canEdit = (section: SectionInfo) =>
    accountService.isAllowed(AdminAuthorizationRoles) ||
    accountService.isAccount([section.section?.defaultTeacherId ?? '']);
  const canEditTeachers = accountService.isAllowed(AdminAuthorizationRoles);
  const canManageConflicts = accountService.isAllowed(AdminAuthorizationRoles);
  const canExportToCsv = accountService.isAllowed(AdminAuthorizationRoles);

  async function copyIdToClipboard(section: SectionModel): Promise<void> {
    await navigator.clipboard.writeText(section.id);
    setShowToast(true);
  }

  function editSections(data: SectionInfo | SectionInfo[]): Promise<void> {
    const sectionsInfo = isArray(data) ? data : [data];
    return sections.editSections(compact(sectionsInfo.map((s) => s.section)));
  }

  function toggleSelectionEnabled() {
    setSelectionEnabled(!selectionEnabled);
    sections.selectedSectionIds = [];
  }

  const table = useInsightsTable(
    TableStateKey,
    sections.sections,
    title ?? '',
    () => [
      {
        header: sectionsStrings.title,
        accessorFn: (row) => accessorForSearch([row.section?.title, row.section?.importId]),
        id: 'title',
        Cell: ({ row }) => {
          const linkTo =
            row.original.section != null
              ? reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                  { name: RouteParamNames.configId, value: configId },
                  { name: RouteParamNames.sectionId, value: row.original.sectionId }
                ])
              : undefined;

          return (
            <SectionName
              sx={{ minHeight: '30px' }}
              title={SectionUtils.formatTitle(row.original.section, sectionsStrings.noTitle)}
              color={row.original.section?.color}
              linkTo={linkTo}
              subInformation1={row.original.section?.importId}
            />
          );
        }
      },
      {
        header: sectionsStrings.teachers,
        // Teachers are sorted in the cell, so we must also sort them when providing searchable/sortable full data.
        accessorFn: (row) =>
          row.teachers
            ?.map((t) => AccountUtils.getDisplayLastFirstName(t.account, '?'))
            .slice()
            .sort((a, b) => a.localeCompare(b, localizationService.currentLocale, { sensitivity: 'base' }))
            .join(' ') ?? '',
        id: 'teachers',
        Cell: ({ row }) => <SectionListTeachersCell configId={configId} sectionInfo={row.original} />
      },
      {
        header: sectionsStrings.grade,
        accessorKey: 'section.gradeLevel',
        sortingFn: 'alphanumeric'
      },
      {
        header: sectionsStrings.group,
        accessorKey: 'section.sectionNumber',
        sortingFn: 'alphanumeric'
      },
      {
        header: sectionsStrings.room,
        accessorKey: 'section.defaultRoomName'
      },
      {
        header: sectionsStrings.studentCount,
        accessorFn: (row) => row.students?.length ?? 0,
        id: 'studentCount',
        sortingFn: 'alphanumeric',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row, renderedCellValue }) => {
          const hasAutoEnrollment =
            row.original.section != null &&
            (row.original.section.autoEnrollRoles.length > 0 || row.original.section.autoEnrollTags.length > 0);

          return (
            <Stack direction="row">
              {hasAutoEnrollment && (
                <Tooltip
                  title={insightsStrings.components.getAutoEnrollmentRolesAndTags(
                    row.original.section!.autoEnrollRoles,
                    row.original.section!.autoEnrollTags
                  )}
                >
                  <EnrollmentIcon fontSize="small" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              {renderedCellValue}
            </Stack>
          );
        }
      },
      {
        header: sectionsStrings.schedules,
        id: 'schedules',
        enableColumnActions: false,
        Cell: ({ row }) =>
          row.original.section != null && <SectionSchedulesSummary schedules={row.original.section.schedules} />
      }
    ],
    {
      getTopActionButtons: ({ table }) => [
        {
          icon: () => <Add />,
          tooltip: sectionsStrings.addSectionTooltip,
          onClick: () => void sections.addSection(),
          isDisabled: !canAdd
        },
        {
          icon: () => (selectionEnabled ? <CheckBox /> : <CheckBoxOutlineBlank />),
          tooltip: selectionEnabled
            ? sectionsStrings.disableSectionSelectionTooltip
            : sectionsStrings.enableSectionSelectionTooltip,
          onClick: toggleSelectionEnabled,
          isDisabled: !canMultiEdit
        },
        selectionEnabled && {
          icon: () => <Edit />,
          tooltip: sectionsStrings.batchEditSections,
          onClick: () => void editSections(table.getSelectedRowModel().rows.map((row) => row.original)),
          isDisabled: !canMultiEdit // Just in case
        },
        canExportToCsv && {
          icon: () => <ArrowDownward />,
          tooltip: sectionsStrings.exportToCsv,
          onClick: () => sections.exportToCsv()
        }
      ],
      getRowActionMenuItems: ({ row }) => {
        const canEditThis = canEdit(row.original);

        return [
          canEditThis && {
            title: sectionsStrings.editDetails,
            onClick: () => void editSections(row.original)
          },
          canEditThis && {
            title: sectionsStrings.editStudents,
            onClick: () => void sections.editStudents(row.original.section!)
          },
          canEditTeachers && {
            title: sectionsStrings.editTeachers,
            onClick: () => void sections.editTeachers(row.original.section!)
          },
          canEditThis && {
            title: sectionsStrings.editSchedules,
            onClick: () => void sections.editSectionSchedules(row.original.section!)
          },
          canManageConflicts && {
            title: sectionsStrings.manageConflicts,
            onClick: () => void sections.manageSectionConflicts(row.original.section!)
          },
          canCopyIds && {
            title: sectionsStrings.copyId,
            onClick: () => void copyIdToClipboard(row.original.section!)
          }
        ];
      },
      selectionOptions: selectionEnabled
        ? {
            getRowId: (row) => row.sectionId,
            selectedRowIds: sections.selectedSectionIds,
            onSelectionChanged: (ids) => (sections.selectedSectionIds = ids),
            canMultiSelect: true
          }
        : undefined,
      defaultSorting: { id: 'title', desc: false },
      disablePagination: !usePagination
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={sectionsStrings.copyIdNotification}
      />
    </Box>
  );
});
