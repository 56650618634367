import {
  InsightsButton,
  TargetSchemaAndOptions,
  TransformationIndexedSources,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SxProps,
  TextField,
  ThemeProvider
} from '@mui/material';
import { ImportSession, Schema } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionAddOrEditTransformationDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  transformationLabel?: string;
  schemas?: Schema[];
}

export const ImportSessionAddOrEditTransformationDialog = observer(
  (props: ImportSessionAddOrEditTransformationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], configId, session, transformationLabel = '', schemas = [], onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditTransformation(
        configId,
        session,
        transformationLabel,
        schemas,
        onSuccess!,
        onCancel!
      )
    );

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <DialogTitle>{strings.addTransformationTitle}</DialogTitle>

          <DialogContent dividers>
            <TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              slotProps={{ input: { inputProps: { maxLength: 1 } } }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.transformationNameLabel}
              slotProps={{ input: { inputProps: { maxLength: 50 } } }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.transformationDescriptionLabel}
              slotProps={{ input: { inputProps: { maxLength: 250 } } }}
              value={viewModel.description}
              onChange={(e) => (viewModel.description = e.target.value)}
            />
            <TextField
              select
              fullWidth
              label={strings.sourceLabelLabel}
              value={viewModel.sourceLabel}
              onChange={(e) => (viewModel.sourceLabel = e.target.value)}
            >
              {viewModel.availableSources.map((source) => (
                <MenuItem key={`source-label-${source.label}`} value={source.label}>
                  {`${source.label} - ${source.name}`}
                </MenuItem>
              ))}
            </TextField>
            <TransformationIndexedSources sx={{ mb: 1 }} viewModel={viewModel.indexedSourcesViewModel} />
            <TargetSchemaAndOptions sx={{ mt: 2, mb: 1 }} viewModel={viewModel.schemaViewModel} />
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canSave}
              onClick={() => void viewModel.saveTransformation()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
);
