import { ThemeOptions } from '@mui/material';
import { red } from '@mui/material/colors';
import { createAppStudyoTheme } from '@studyo/theme';
import { WebInsightsTheme } from './WebInsightsTheme.ts';

const SpacingUnit = 8;

export const LightInsightsMuiTheme: ThemeOptions = {
  spacing: SpacingUnit,
  palette: {
    primary: {
      main: '#415767',
      dark: '#172e3d',
      light: '#6d8495',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#7fcbee',
      dark: '#4b9abb',
      light: '#b3feff',
      contrastText: '#000000'
    }
  },
  typography: {
    body1: {
      fontWeight: 300
    }
  },
  shared: WebInsightsTheme,
  studyo: createAppStudyoTheme('light'),
  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: red[500]
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: SpacingUnit,
          padding: SpacingUnit * 2,
          display: 'flex'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 20
        },

        action: {
          marginRight: -SpacingUnit * 2,
          marginBottom: -SpacingUnit
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: SpacingUnit * 2,
          '&:last-child': {
            paddingBottom: SpacingUnit * 2
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 16
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontWeight: 300
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: {
          fontWeight: 300
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 70
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14
        },
        popper: {
          opacity: 1
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 300
        },
        h2: {
          fontWeight: 300
        },
        h3: {
          fontWeight: 300
        },
        h4: {
          fontWeight: 400
        },
        h5: {
          fontWeight: 400
        },
        h6: {
          fontWeight: 400
        },
        body1: {
          fontWeight: 300
        },
        body2: {
          fontWeight: 300
        },
        subtitle1: {
          fontWeight: 400
        },
        subtitle2: {
          fontWeight: 400
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: 16
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          padding: 0
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        select: {
          fontWeight: 300
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          '&$selected': {
            fontWeight: 500
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 16px'
        }
      }
    }
  }
};
