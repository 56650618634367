import {
  AuthorizationRoleCondition,
  ObservablePresenter,
  OnboardingStepView,
  PageHeaderBar
} from '@insights/components';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import { Box, IconButton, SxProps, Tooltip } from '@mui/material';
import { StudyoStaffOrRootAdminRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { MaxLayoutWidth, MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface OnboardingStepScreenProps {
  sx?: SxProps;

  className?: string;
  style?: CSSProperties;
}

export const OnboardingStepScreen = observer((props: OnboardingStepScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const params = useParams();
  const configId = params.configId ?? '';
  const stepName = params.templateName ?? '';
  const processName = params.processName ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createOnboardingStepScreen(configId, processName, stepName),
    [configId, processName, stepName]
  );

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={StudyoStaffOrRootAdminRoles}>
          <Tooltip title={strings.hideLockedContentTooltip}>
            <IconButton sx={{ mr: 1 }} onClick={() => viewModel.toggleLockedContentVisibility()}>
              {viewModel.isLockedContentVisible ? <HideIcon fontSize="small" /> : <ShowIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.step}
          loadingMessage={strings.loadingStepMessage}
          errorMessage={strings.loadingStepErrorMessage}
          render={(step) => (
            <Box display="flex" flexDirection="column">
              <OnboardingStepView
                viewModel={step}
                sx={{
                  minWidth: MinLayoutWidth,
                  maxWidth: MaxLayoutWidth,
                  width: '100%',
                  height: '100%',
                  display: 'block'
                }}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
