import {
  AuthorizationRoleCondition,
  DateTimePickerLocalizationProvider,
  InsightsButton,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  MaximumCommentsLength,
  MaximumOnboardingCodeLength,
  MaximumSchoolNameLength,
  MaximumSchoolTitleLength
} from '@insights/viewmodels';
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { AllConfigStates, RootAdminRoles } from '@shared/models/types';
import { AllLocales } from '@shared/resources/services';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

const filter = createFilterOptions<string>();

export interface EditSchoolYearConfigurationInformationDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditSchoolYearConfigurationInformationDialog = observer(
  (props: EditSchoolYearConfigurationInformationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { className, sx = [], style, schoolYearConfiguration, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationInformation(schoolYearConfiguration, onSuccess!, onCancel!)
    );

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <DateTimePickerLocalizationProvider>
          <Dialog
            sx={sx}
            open={true}
            onClose={() => void viewModel.cancel()}
            maxWidth="sm"
            fullWidth={true}
            className={className}
            style={style}
          >
            <form
              noValidate
              onSubmit={(e) => {
                // This is to prevent the page from reloading on submit
                e.preventDefault();
                return false;
              }}
            >
              <DialogTitle>{strings.editInformationDialogTitle}</DialogTitle>
              <DialogContent dividers>
                <Grid2 container spacing={2}>
                  {/* School Name */}
                  <Grid2 size={{ xs: 12 }}>
                    <TextField
                      label={strings.schoolNameFieldLabel}
                      value={viewModel.getFieldValue('schoolName')}
                      fullWidth
                      required
                      autoFocus
                      slotProps={{ input: { inputProps: { maxLength: MaximumSchoolNameLength } } }}
                      error={Boolean(viewModel.getFieldError('schoolName'))}
                      helperText={viewModel.getFieldError('schoolName')}
                      onChange={(e) => viewModel.setFieldValue('schoolName', e.target.value)}
                    />
                  </Grid2>

                  {/* School Title */}
                  <Grid2 size={{ xs: 12 }}>
                    <TextField
                      label={strings.schoolTitleFieldLabel}
                      value={viewModel.getFieldValue('schoolTitle')}
                      placeholder={viewModel.schoolDisplayTitle}
                      fullWidth
                      slotProps={{
                        input: { inputProps: { maxLength: MaximumSchoolTitleLength } },
                        inputLabel: { shrink: true }
                      }}
                      error={Boolean(viewModel.getFieldError('schoolTitle'))}
                      helperText={viewModel.getFieldError('schoolTitle')}
                      onChange={(e) => viewModel.setFieldValue('schoolTitle', e.target.value)}
                    />
                  </Grid2>

                  {/* State */}
                  <Grid2 size={{ xs: 12 }}>
                    <FormControl fullWidth error={Boolean(viewModel.getFieldError('state'))}>
                      <InputLabel htmlFor="state-select" shrink>
                        {strings.stateFieldLabel}
                      </InputLabel>
                      <Select
                        value={viewModel.getFieldValue('state')}
                        inputProps={{ id: 'state-select' }}
                        displayEmpty
                        onChange={(e) => viewModel.setFieldValue('state', e.target.value)}
                      >
                        {AllConfigStates.map((state) => (
                          <MenuItem key={`state-${state}`} value={state}>
                            {strings.getConfigStateLabel(state)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>

                  {/* Comments */}
                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <Grid2 size={{ xs: 12 }}>
                      <TextField
                        label={strings.commentsFieldLabel}
                        value={viewModel.getFieldValue('comments')}
                        fullWidth
                        multiline
                        slotProps={{ input: { inputProps: { maxLength: MaximumCommentsLength, maxRows: 5 } } }}
                        error={Boolean(viewModel.getFieldError('comments'))}
                        helperText={viewModel.getFieldError('comments')}
                        onChange={(e) => viewModel.setFieldValue('comments', e.target.value)}
                      />
                    </Grid2>
                  </AuthorizationRoleCondition>

                  {/* Expected student count */}
                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <Grid2 size={{ xs: 12 }}>
                      <TextField
                        fullWidth
                        type="number"
                        slotProps={{ input: { inputProps: { min: 0 } }, inputLabel: { shrink: true } }}
                        label={strings.expectedStudentCountFieldLabel}
                        value={viewModel.getFieldValue('expectedStudentCount')}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          const adjustedValue = Math.max(value, 0);

                          viewModel.setFieldValue('expectedStudentCount', adjustedValue);
                        }}
                      />
                    </Grid2>
                  </AuthorizationRoleCondition>

                  {/* Onboarding Code and required email domain to use it */}
                  <Grid2 size={{ xs: 6 }}>
                    <TextField
                      label={strings.onboardingCodeFieldLabel}
                      value={viewModel.getFieldValue('onboardingCode')}
                      fullWidth
                      slotProps={{ input: { inputProps: { maxLength: MaximumOnboardingCodeLength } } }}
                      error={Boolean(viewModel.getFieldError('onboardingCode'))}
                      helperText={viewModel.getFieldError('onboardingCode')}
                      onChange={(e) => viewModel.setFieldValue('onboardingCode', e.target.value)}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6 }}>
                    <TextField
                      label={strings.onboardingCodeEmailDomainLabel}
                      placeholder={strings.onboardingCodeEmailDomainNonePlaceholder}
                      slotProps={{ inputLabel: { shrink: true } }}
                      value={viewModel.getFieldValue('onboardingCodeEmailDomain')}
                      fullWidth
                      onChange={(e) => viewModel.setFieldValue('onboardingCodeEmailDomain', e.target.value)}
                    />
                  </Grid2>

                  {/* Timezone */}
                  <Grid2 size={{ xs: 12 }}>
                    <FormControl fullWidth error={Boolean(viewModel.getFieldError('timezone'))}>
                      <InputLabel htmlFor="timezone-select" shrink>
                        {strings.timezoneLabel}
                      </InputLabel>
                      <Select
                        value={viewModel.getFieldValue('timezone')}
                        inputProps={{ id: 'timezone-select' }}
                        displayEmpty
                        onChange={(e) => viewModel.setFieldValue('timezone', e.target.value)}
                      >
                        <MenuItem value="">{strings.noTimezone}</MenuItem>
                        {Intl.supportedValuesOf('timeZone').map((name) => (
                          <MenuItem key={`timezone-${name}`} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{viewModel.getFieldError('timezone')}</FormHelperText>
                    </FormControl>
                  </Grid2>

                  {/* Language */}
                  <Grid2 size={{ xs: 12 }}>
                    <FormControl fullWidth error={Boolean(viewModel.getFieldError('language'))}>
                      <InputLabel htmlFor="language-select" shrink>
                        {strings.languageLabel}
                      </InputLabel>
                      <Select
                        value={viewModel.getFieldValue('language')}
                        inputProps={{ id: 'language-select' }}
                        onChange={(e) => viewModel.setFieldValue('language', e.target.value)}
                      >
                        {AllLocales.map((locale) => (
                          <MenuItem value={locale} key={locale}>
                            <Typography>{strings.getLocaleDisplayName(locale)}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>

                  {/* Tags */}
                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <Grid2 size={{ xs: 12 }}>
                      <Autocomplete
                        multiple
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={viewModel.suggestedTags}
                        value={viewModel.getFieldValue('tags') as string[]}
                        onChange={(_, v) => viewModel.setFieldValue('tags', v as string[])}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push(strings.newTagLabel(inputValue));
                          }

                          return filtered;
                        }}
                        renderInput={(p) => <TextField {...p} label={strings.tagsLabel} />}
                      />
                    </Grid2>
                  </AuthorizationRoleCondition>

                  {/* Demo School */}
                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <Grid2 size={{ xs: 6 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(viewModel.getFieldValue('demoSchool'))}
                            color="primary"
                            onChange={(_, isChecked) => viewModel.setFieldValue('demoSchool', isChecked)}
                          />
                        }
                        label={strings.demoSchoolCheckboxCaption}
                      />
                    </Grid2>
                    <Grid2 size={{ xs: 6 }}>
                      <DatePicker
                        disabled={!viewModel.getFieldValue('demoSchool')}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(viewModel.getFieldError('demoDay')),
                            helperText: viewModel.getFieldError('demoDay')
                          },
                          actionBar: {
                            actions: ['clear', 'cancel', 'accept']
                          }
                        }}
                        localeText={{
                          clearButtonLabel: strings.clearButtonCaption,
                          okButtonLabel: strings.okButtonCaption,
                          cancelButtonLabel: strings.cancelButtonCaption
                        }}
                        minDate={viewModel.minDemoDay.asDate}
                        maxDate={viewModel.maxDemoDay.asDate}
                        format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                        label={strings.demoDateFieldLabel}
                        value={(viewModel.getFieldValue('demoDay') as Date | undefined) ?? null}
                        onChange={(e) => viewModel.setFieldValue('demoDay', e ?? undefined)}
                      />
                    </Grid2>
                  </AuthorizationRoleCondition>
                </Grid2>
              </DialogContent>
              <DialogActions>
                <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                  {strings.cancelButtonCaption}
                </InsightsButton>
                <InsightsButton
                  isSubmit
                  showSpinnerOnExecuting
                  isExecuting={viewModel.isSubmitting}
                  onClick={() => void viewModel.save()}
                >
                  {strings.saveButtonCaption}
                </InsightsButton>
              </DialogActions>
            </form>
          </Dialog>
        </DateTimePickerLocalizationProvider>
      </ThemeProvider>
    );
  }
);
