import { AccountInfo, SectionInfo, accountInfoFromModel } from '@insights/models';
import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { isEqual } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppPaginatedViewModel, AppPaginationViewModel, PaginatedViewModel } from '../PaginatedViewModel';
import { AppSectionsViewModel } from '../SectionsViewModel';
import { AppTeacherExportsViewModel, TeacherExportsViewModel } from '../TeacherExportsViewModel';

export interface TeacherData {
  config: SchoolYearConfigurationSummary;
  accountInfo?: AccountInfo;
  displayedSectionIds: string[];
  filterCount: number;
}

export interface TeacherDashboardViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<TeacherData>;

  readonly exports: TeacherExportsViewModel | undefined;

  showFilters: () => Promise<void>;
}

export class AppTeacherDashboardViewModel extends AppPaginatedViewModel implements TeacherDashboardViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _contentStore: ContentStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    public readonly configId: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<TeacherData> {
    return fromPromise(this.loadData());
  }

  @computed
  get exports(): TeacherExportsViewModel | undefined {
    const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

    if (teacherId == null) {
      return undefined;
    }

    return new AppTeacherExportsViewModel(
      this._schoolYearConfigurationStore,
      this._contentStore,
      this._calendarStore,
      this._accountService,
      this._localizationService,
      this._alertService,
      this.configId,
      teacherId
    );
  }

  async showFilters(): Promise<void> {
    const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

    if (teacherId == null) {
      return;
    }

    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const teacherFilters = await configPreferences.getTeacherDashboardFilters(teacherId);

    const result = await this._navigationService.navigateToTeacherDashboardFilters(
      this.configId,
      teacherId,
      teacherFilters
    );

    if (result !== 'cancelled' && !isEqual(result, teacherFilters)) {
      await configPreferences.saveTeacherDashboardFilters(teacherId, result);
    }
  }

  private async loadData(): Promise<TeacherData> {
    const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

    // IMPORTANT: In order to invalidate the data and have MOBX reevaluate the loadData,
    //            we must call this async method before the other ones. This is until we change
    //            approach and use sync stores.
    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const teacherDashboardFilters = await configPreferences.getTeacherDashboardFilters(teacherId ?? '');

    const config = await this._schoolYearConfigurationStore.getConfigSummary(this.configId);

    this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));

    if (teacherId == null) {
      return {
        config,
        accountInfo: undefined,
        displayedSectionIds: [],
        filterCount: 0
      };
    }

    const teacher = await this._schoolYearConfigurationStore.getTeacher(this.configId, teacherId, false);
    const taughtSections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
      this.configId,
      teacherId
    );
    const sectionInfos = await Promise.all(
      taughtSections.map<Promise<SectionInfo>>(async (section) => {
        const [students, teachers] = await Promise.all([
          this._schoolYearConfigurationStore.getStudentsForSection(this.configId, section, false),
          this._schoolYearConfigurationStore.getTeachersForSection(this.configId, section, false)
        ]);

        return {
          sectionId: section.id,
          section: section,
          students: students.map(accountInfoFromModel),
          teachers: teachers.map(accountInfoFromModel)
        };
      })
    );

    return {
      config,
      accountInfo: {
        account: teacher,
        sections: new AppSectionsViewModel(
          this._navigationService,
          this._localizationService,
          this.configId,
          sectionInfos
        )
      },
      displayedSectionIds: taughtSections
        .filter((section) => !teacherDashboardFilters.excludedSectionIds.includes(section.id))
        .map((section) => section.id),
      filterCount: teacherDashboardFilters.excludedSectionIds.length
    };
  }
}
