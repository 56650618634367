import { ExternalAccountListViewModel } from '@insights/viewmodels';
import { Category, Delete, Edit, Settings, Transform, ViewList } from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { ExternalAccount } from '@shared/models/connectors';
import { AdminAuthorizationRoles, ExternalAccountKind, RootAdminRoles } from '@shared/models/types';
import { TintedImage } from '@shared/rxp/tinted-image';
import { useNavigateAsync } from '@shared/utils';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ActionButtonInfo, useInsightsTable } from '../InsightsTable';

const TableStateKey = 'ExternalAccountList';

export interface ExternalAccountListProps {
  viewModel: ExternalAccountListViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
}

export const ExternalAccountList = observer((props: ExternalAccountListProps) => {
  const { accountService, imageService, localizationService } = useInsightsServices();
  const { sx = [], className, style, title, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const navigate = useNavigateAsync();

  function getImage(kind: ExternalAccountKind, isDisabled: boolean) {
    const externalImages = imageService.studyoImages.tasks.external;

    return (
      <Box sx={{ height: 32, width: 32, filter: isDisabled ? `grayscale(1)` : undefined }}>
        <TintedImage
          source={externalImages.getExternalAccountImage(kind)}
          sx={{ position: 'absolute', height: 32, width: 32 }}
        />
      </Box>
    );
  }

  function renderAssociationsStatus(account: ExternalAccount) {
    if (account.associationsCount === 0) {
      return <Typography>&mdash;</Typography>;
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: (theme) =>
            account.failedAssociationsCount > 0 ? theme.palette.error.main : theme.palette.text.primary
        }}
      >
        {strings.localizedAssociationsStatus(account.failedAssociationsCount, account.associationsCount)}
      </Typography>
    );
  }

  const table = useInsightsTable(
    TableStateKey,
    viewModel.accounts,
    title ?? strings.externalAccountListTitle,
    () => [
      {
        header: strings.kind,
        accessorKey: 'account.kind',
        id: 'kind',
        Cell: ({ row }) => <Typography variant="body1">{strings.accountKindName(row.original.kind)}</Typography>
      },
      {
        header: strings.name,
        // Not providing managedIdentifier here, the name is enough.
        accessorFn: (row) => AccountUtils.getDisplayLastFirstName(viewModel.teachersById[row.accountId], row.name),
        id: 'name'
      },
      {
        header: strings.email,
        accessorKey: 'account.email',
        id: 'email'
      },
      {
        header: strings.status,
        id: 'status',
        enableColumnActions: false,
        Cell: ({ row }) => renderAssociationsStatus(row.original)
      }
    ],
    {
      getTopActionButtons: () =>
        viewModel.accountKinds
          .map((kind) => ({ kind, isDisabled: !viewModel.creatableAccountKinds.includes(kind) }))
          .map<ActionButtonInfo | false>(({ kind, isDisabled }) => ({
            icon: () => getImage(kind, isDisabled),
            tooltip: strings.localizedAddAccountTooltip(kind),
            onClick: () => void viewModel.navigateToAddAccount(kind),
            isDisabled
          }))
          .concat([
            accountService.isAllowed(AdminAuthorizationRoles) && {
              icon: () => <Transform />,
              tooltip: strings.externalContentMappingsTooltip,
              onClick: () => void viewModel.navigateToExternalContentMappings(),
              ml: 1
            },
            accountService.isAllowed(RootAdminRoles) && {
              icon: () => <Settings />,
              tooltip: strings.enableIntegrations,
              onClick: () => void viewModel.navigateToChangeIntegrations(),
              ml: 1
            }
          ]),
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <ViewList />,
          tooltip: strings.viewAssociations,
          onClick: () => void viewModel.navigateToAssociations(row.original.id, navigate),
          isDisabled: !viewModel.viewableAccountKinds.includes(row.original.kind)
        },
        {
          icon: () => <Edit />,
          tooltip: strings.editAccount,
          onClick: () => void viewModel.navigateToEditAccount(row.original.id, row.original.kind),
          isDisabled: !viewModel.updatableAccountKinds.includes(row.original.kind)
        },
        {
          icon: () => <Settings />,
          tooltip: strings.editSettings,
          onClick: () => void viewModel.navigateToEditSettings(row.original.id, row.original.kind),
          isDisabled: !viewModel.editableAccountKinds.includes(row.original.kind)
        },
        {
          icon: () => <Category />,
          tooltip: strings.editMappings,
          onClick: () => void viewModel.navigateToEditMappings(row.original.id, row.original.kind),
          isDisabled: !viewModel.mappableAccountKinds.includes(row.original.kind)
        },
        {
          icon: () => <Delete />,
          tooltip: strings.deleteAccount,
          onClick: () => void viewModel.deleteAccount(row.original.id),
          isDisabled: !viewModel.deletableAccountKinds.includes(row.original.kind)
        }
      ]
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
    </Box>
  );
});
