import { Concatenation as PBConcatenation } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { Concatenation } from '../interfaces';

export class GrpcConcatenation extends BaseModel<PBConcatenation> implements Concatenation {
  constructor(pb: PBConcatenation) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get label(): string {
    return this._pb.label;
  }

  get name(): string {
    return this._pb.name;
  }

  get sourceLabels(): string[] {
    return this._pb.sourceLabels;
  }

  get columnCount(): number {
    return this._pb.columnCount;
  }

  get useColumnNames(): boolean {
    return this._pb.useColumnNames;
  }

  get targetSchema(): string {
    return this._pb.targetSchema;
  }

  get suggestedImportOptions(): string[] {
    return this._pb.suggestedImportOptions;
  }
}
