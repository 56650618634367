import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

export interface ConditionalTypographyProps extends TypographyProps {
  readonly text: string | ReactNode;
}

export function ConditionalTypography(props: ConditionalTypographyProps) {
  if (props.content != null) {
    throw new Error('You must use the text property with this component.');
  }

  return typeof props.text === 'string' ? <Typography {...props}>{props.text}</Typography> : props.text;
}
