import { EditableAccountInfo, SectionInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { AppSectionsViewModel } from '../metrics';
import { AppEditableAccountsScreenViewModel } from './EditableAccountsScreenViewModel';

export class AppEditableTeachersScreenViewModel extends AppEditableAccountsScreenViewModel {
  constructor(
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    accountService: AccountService,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    configId: string,
    shouldLimitAccess?: boolean
  ) {
    super(
      schoolYearConfigurationStore,
      accountService,
      navigationService,
      localizationService,
      configId,
      'teacher',
      shouldLimitAccess
    );
  }

  protected async getLimitedAccounts(): Promise<AccountModel[]> {
    if (this._accountService.isAllowed(['studyo-staff', 'school-staff', 'teacher'])) {
      // Teachers, School and Studyo staff can view all teachers. They'll get limited access to changes.
      return await this._schoolYearConfigurationStore.getTeachers(this.configId, false);
    } else {
      return await super.getLimitedAccounts();
    }
  }

  protected async loadAccountInfos(accounts: AccountModel[]): Promise<EditableAccountInfo[]> {
    return await Promise.all(
      accounts.map<Promise<EditableAccountInfo>>(async (account) => {
        const [sections, autoEnrolledSections] = await Promise.all([
          this._schoolYearConfigurationStore.getTaughtSectionsForTeacher(this.configId, account),
          this._schoolYearConfigurationStore.getAutoEnrolledSectionsForTeacher(this.configId, account)
        ]);

        return {
          account,
          sections: new AppSectionsViewModel(
            this._navigationService,
            this._localizationService,
            this.configId,
            sections
              .map<SectionInfo>((section) => ({ sectionId: section.id, section }))
              .concat(
                autoEnrolledSections.map<SectionInfo>((section) => ({
                  sectionId: section.id,
                  section,
                  isAutoEnrolled: true
                }))
              )
          ),
          parents: [],
          children: [],
          pendingChildren: [],
          // When current user is a teacher, make all accounts readonly except their account
          isReadOnly:
            !this._accountService.isAccount([account.id]) && this._accountService.highestUserRole === 'teacher'
        };
      })
    );
  }
}
