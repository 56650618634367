import { Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { Ref } from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router';

interface ReactRouterLinkAdapterProps extends ReactRouterLinkProps {
  ref?: Ref<HTMLAnchorElement>;
}

const ReactRouterLinkAdapter = ({ ref, ...p }: ReactRouterLinkAdapterProps) => <ReactRouterLink {...p} ref={ref} />;

export function RouterLink(props: LinkProps & ReactRouterLinkProps) {
  return <Link {...props} component={ReactRouterLinkAdapter} />;
}

export interface OptionalRouterLinkProps {
  readonly disabled?: boolean;
}

export function OptionalRouterLink(props: LinkProps & ReactRouterLinkProps & OptionalRouterLinkProps) {
  if (props.disabled) {
    return <Typography variant={props.variant}>{props.children}</Typography>;
  }

  return <Link {...props} component={ReactRouterLinkAdapter} />;
}
