import { AccountInfo, SectionInfo, accountInfoFromModel } from '@insights/models';
import { NavigationService } from '@insights/services';
import { getParentInvitationOQValue } from '@insights/utils';
import { LocalizationService } from '@shared/resources/services';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppSectionsViewModel } from './SectionsViewModel';

export interface StudentDetailsViewModel {
  readonly configId: string;
  readonly studentId: string;

  readonly data: IPromiseBasedObservable<AccountInfo>;

  navigateToPlanner: () => Promise<void>;
}

export class AppStudentDetailsViewModel implements StudentDetailsViewModel {
  constructor(
    private readonly _metricsStore: MetricsStore,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    public readonly configId: string,
    public readonly studentId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AccountInfo> {
    return fromPromise(this.loadData());
  }

  navigateToPlanner(): Promise<void> {
    return this._navigationService.navigateToPlannerExternal(this.configId, this.studentId);
  }

  private async loadData(): Promise<AccountInfo> {
    const [student, oqMetrics, parentsByChildId] = await Promise.all([
      this._schoolYearConfigurationStore.getStudent(this.configId, this.studentId, false),
      this._metricsStore.getAccountOQMetrics(this.configId, [this.studentId], false),
      this._schoolYearConfigurationStore.getParentsByChildId(this.configId, false)
    ]);
    const sections = await this._schoolYearConfigurationStore.getSectionsForStudent(this.configId, student);
    const sectionInfos = await Promise.all(
      sections.map<Promise<SectionInfo>>(async (section) => {
        const [students, teachers] = await Promise.all([
          this._schoolYearConfigurationStore.getStudentsForSection(this.configId, section, false),
          this._schoolYearConfigurationStore.getTeachersForSection(this.configId, section, false)
        ]);

        return {
          sectionId: section.id,
          section: section,
          students: students.map(accountInfoFromModel),
          teachers: teachers.map(accountInfoFromModel)
        };
      })
    );

    return {
      account: student,
      sections: new AppSectionsViewModel(
        this._navigationService,
        this._localizationService,
        this.configId,
        sectionInfos
      ),
      oqProfile: _.head(oqMetrics)?.oqProfile,
      invitesParent: getParentInvitationOQValue(student, parentsByChildId)
    };
  }
}
