import { AccountEditionViewModel } from '@insights/viewmodels';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AccountSectionList } from './AccountSectionList';
import { AccountSectionPicker } from './AccountSectionPicker';

export interface AccountSectionsEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: AccountEditionViewModel;
}

export const AccountSectionsEdition = observer((props: AccountSectionsEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return (
    <Stack sx={sx} className={className} style={style} alignContent="stretch">
      <Stack direction="row">
        <Typography variant="h6">{strings.sectionsEditionTitle}</Typography>
        &nbsp;&nbsp;
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {AccountUtils.getDisplayFirstLastName(viewModel.editableAccount)}
        </Typography>
      </Stack>
      {viewModel.editableAccount.role === 'teacher' && (
        <Box
          sx={{
            p: 2,
            backgroundColor: red[500],
            color: (theme) => theme.palette.common.white
          }}
        >
          <Typography variant="h5">{strings.teacherSelectedSectionsWarningTitle}</Typography>
          <Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning}</Typography>
          <Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning2}</Typography>
        </Box>
      )}
      {viewModel.hasMissingSection && (
        <Typography variant="body2" color="error">
          {strings.missingSectionWarning}
        </Typography>
      )}
      <Stack direction="row" spacing={1}>
        <AccountSectionList sx={{ flex: 1 }} account={viewModel} />
        <AccountSectionPicker sx={{ flex: 2 }} account={viewModel} />
      </Stack>
    </Stack>
  );
});
