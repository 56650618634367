import { SectionStudentsEditionViewModel } from '@insights/viewmodels';
import { Add, Delete, Visibility, VisibilityOff, Warning } from '@mui/icons-material';
import { Grid2, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils/models';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';

const SelectedTableStateKey = 'SectionStudentsEditionSelected';
const AvailableTableStateKey = 'SectionStudentsEditionAvailable';

export interface SectionStudentsEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionStudentsEditionViewModel;
}

export const SectionStudentsEdition = observer((props: SectionStudentsEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const insightsStrings = localizationService.localizedStrings.insights;

  const selectedTable = useInsightsTable(
    SelectedTableStateKey,
    viewModel.selectedAccounts,
    viewModel.isViewingTeachers
      ? () => (
          <Stack direction="row" sx={{ width: '100%' }} spacing={1} alignItems="center">
            <Tooltip title={strings.selectedTeachersNotice}>
              <Warning fontSize="small" color="error" />
            </Tooltip>
            <Typography variant="h6">{strings.selectedTeachers}</Typography>
          </Stack>
        )
      : strings.selectedStudents,
    () => [
      {
        header: strings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <Delete />,
          tooltip: viewModel.isViewingTeachers ? strings.removeTeacherAsStudent : strings.removeStudent,
          onClick: () => viewModel.removeAccount(row.original.account)
        }
      ],
      getTopActionButtons: () => [
        {
          icon: () => (viewModel.isViewingTeachers ? <Visibility /> : <VisibilityOff />),
          tooltip: viewModel.isViewingTeachers
            ? strings.toggleSelectStudentsTooltip
            : strings.toggleSelectTeachersTooltip,
          onClick: () => (viewModel.isViewingTeachers = !viewModel.isViewingTeachers)
        }
      ],
      disablePagination: true,
      defaultSorting: { id: 'name', desc: false }
    }
  );

  const availableTable = useInsightsTable(
    AvailableTableStateKey,
    viewModel.availableAccounts,
    viewModel.isViewingTeachers ? strings.availableTeachers : strings.availableStudents,
    () => [
      {
        header: strings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <Add />,
          tooltip: viewModel.isViewingTeachers ? strings.addTeacherAsStudent : strings.addStudent,
          onClick: () => viewModel.addAccount(row.original.account)
        }
      ],
      defaultPageSize: 10,
      defaultSorting: { id: 'name', desc: false }
    }
  );

  return (
    <Grid2 sx={sx} className={className} style={style} container spacing={1}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={selectedTable} />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={availableTable} />
      </Grid2>
    </Grid2>
  );
});
