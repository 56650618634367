import {
  AuthorizationRoleCondition,
  ObservablePresenter,
  PageHeaderBar,
  SchoolConfigurationList
} from '@insights/components';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SxProps,
  Typography,
  styled
} from '@mui/material';
import { RootAdminRoles, RootRoles } from '@shared/models/types';
import { useNavigateAsync } from '@shared/utils';
import { map, orderBy, range } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { MinLayoutWidth } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface SchoolConfigurationsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SchoolConfigurations = observer(({ sx = [], className, style }: SchoolConfigurationsProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views;

  const navigate = useNavigateAsync();
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfigurations(), []);

  function getYears() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // range: "... up to, but not including, end".
    return orderBy(range(2018, currentYear + 2), (year) => [year], ['desc']);
  }

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography>{strings.year}</Typography>

          <Box marginLeft={1}>
            <Select
              className={'yearSelection'}
              value={viewModel.schoolConfigurationsYear}
              onChange={(e) => (viewModel.schoolConfigurationsYear = Number(e.target.value))}
            >
              {map(getYears(), (year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <AuthorizationRoleCondition allowedRoles={RootRoles}>
            <Box marginLeft={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewModel.showDemoSchools}
                    onChange={(event) => (viewModel.showDemoSchools = event.target.checked)}
                  />
                }
                label={strings.showDemoSchools}
              />
            </Box>
          </AuthorizationRoleCondition>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" className={'actionsRow'}>
          <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
            <Button onClick={() => void viewModel.createNewSchool(navigate)}>
              <Typography>{strings.newSchool}</Typography>
            </Button>
          </AuthorizationRoleCondition>
        </Box>
      </PageHeaderBar>

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card className={'card'}>
                <SchoolConfigurationList sx={{ width: '100%', height: '100%' }} schools={data} viewModel={viewModel} />
              </Card>
            </Box>
          )}
        />
      </Box>
    </Root>
  );
});

const Root = styled(Box)(() => ({
  '.card': {
    minWidth: MinLayoutWidth
  },
  '.yearSelection': {
    minWidth: 100
  },
  '.actionsRow': {
    marginLeft: 50
  }
}));
