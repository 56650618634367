import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, InputAdornment, Link, SxProps, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { LastAnswerTooltip } from './LastAnswerTooltip';

export interface TextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
}

export const TextFieldQuestion = observer((props: TextFieldQuestionProps) => {
  const { className, style, sx = [], answer, lastYearAnswer, disabled = false } = props;

  // We only support these links.
  const isLink = answer.simpleTextValue.startsWith('https://');
  // We work with styles because we want the input to be read-only
  // but not disabled, to allow text selection.
  const inputStyle: CSSProperties = disabled
    ? {
        fontWeight: 400,
        color: grey[500]
      }
    : {
        fontWeight: 400
      };

  return isLink && disabled ? (
    <Box sx={sx} display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body2" noWrap>
        <Link href={answer.simpleTextValue} target="_blank" rel="noreferrer">
          {answer.simpleTextValue}
        </Link>
      </Typography>
      <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
    </Box>
  ) : (
    <TextField
      sx={sx}
      className={className}
      style={style}
      slotProps={{
        input: {
          readOnly: disabled,
          style: inputStyle,
          endAdornment: (
            <InputAdornment position="end">
              {lastYearAnswer != null && lastYearAnswer.simpleTextValue.length > 0 && (
                <LastAnswerTooltip
                  answer={lastYearAnswer.simpleTextValue}
                  onCopy={!disabled ? () => (answer.simpleTextValue = lastYearAnswer.simpleTextValue) : undefined}
                />
              )}
            </InputAdornment>
          )
        }
      }}
      fullWidth
      value={answer.simpleTextValue}
      onChange={(e) => (answer.simpleTextValue = e.target.value)}
    />
  );
});
