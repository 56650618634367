import { AutomatedImportListViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/NoteAdd';
import { Box, CardContent, CardHeader, IconButton, SxProps, Tooltip } from '@mui/material';
import { RootAdminRoles } from '@shared/models/types/AuthorizationRole.ts';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { AutomatedImportItem } from './AutomatedImportItem.tsx';

export interface AutomatedImportListProps {
  viewModel: AutomatedImportListViewModel;
  sx?: SxProps;
  className?: string;
}

export const AutomatedImportList = observer((props: AutomatedImportListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <Box sx={sx} className={className} flexDirection="column">
      <CardHeader
        title={strings.automatedImportsTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
            <Tooltip title={strings.addAutomatedImportTooltip}>
              <IconButton onClick={() => void viewModel.addAutomatedImport()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <CardContent>
        <Box sx={{ width: '100%' }}>
          {viewModel.automatedImports.map((imp, index) => (
            <AutomatedImportItem
              key={`auto-import-row-${imp.id}`}
              viewModel={viewModel}
              automatedImport={imp}
              showActions
              hasDivider={index < viewModel.automatedImports.length - 1}
            />
          ))}
        </Box>
      </CardContent>
    </Box>
  );
});
