import { ImportSessionFileListViewModel } from '@insights/viewmodels';
import LinkSelfIcon from '@mui/icons-material/AllInclusive';
import ManualLinkIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/NoteAdd';
import UploadIcon from '@mui/icons-material/Publish';
import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { AdminAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface ImportSessionFileListProps {
  viewModel: ImportSessionFileListViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionFileList = observer((props: ImportSessionFileListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <Box sx={sx} className={className} style={style} flexDirection="column">
      <CardHeader
        title={strings.expectedFiles}
        action={
          <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
            <Tooltip title={strings.addExpectedFileTooltip}>
              <IconButton onClick={() => void viewModel.addExpectedFile()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <CardContent>
        {viewModel.expectedFiles.map((file, index) => (
          <Fragment key={`file-row-${file.name}`}>
            <Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
              <Typography fontWeight="700" mr={2} variant="subtitle1">
                {file.label}
              </Typography>

              <Box flex={1} flexDirection="column" px={1}>
                <Typography variant="subtitle1">{file.name}</Typography>
                <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {file.description}
                </Typography>

                {file.url ? (
                  <Typography
                    variant="subtitle2"
                    noWrap
                    color="primary"
                    style={{ direction: 'rtl', textAlign: 'left' }}
                  >
                    <Link href={file.url}>{file.url}</Link>
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" noWrap sx={{ color: (theme) => theme.palette.primary.main }}>
                    {strings.noUrlPlaceholder}
                  </Typography>
                )}
              </Box>

              <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                <MoreActionMenu>
                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <MenuItem onClick={() => void viewModel.uploadFile(file)}>
                      <ListItemIcon>
                        <UploadIcon />
                      </ListItemIcon>
                      <ListItemText>{strings.editFileUrl}</ListItemText>
                    </MenuItem>
                  </AuthorizationRoleCondition>

                  <MenuItem onClick={() => void viewModel.linkFileToProcess(file)}>
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.linkFileToQuestion}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.linkFileToConfiguration(file)}>
                    <ListItemIcon>
                      <LinkSelfIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.linkFileToConfiguration}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.linkFileManually(file)}>
                    <ListItemIcon>
                      <ManualLinkIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.linkFileManually}</ListItemText>
                  </MenuItem>

                  <AuthorizationRoleCondition allowedRoles={RootAdminRoles}>
                    <MenuItem onClick={() => void viewModel.editFileOptions(file)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText>{strings.editFileOptions}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => void viewModel.removeFile(file)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>{strings.removeFile}</ListItemText>
                    </MenuItem>
                  </AuthorizationRoleCondition>
                </MoreActionMenu>
              </AuthorizationRoleCondition>
            </Box>

            {index < viewModel.expectedFiles.length - 1 && <Divider />}
          </Fragment>
        ))}
      </CardContent>
    </Box>
  );
});
