import { ManageableRole } from '@insights/enums';
import { AccountInfo } from '@insights/models';
import { ExportableViewModel } from '@insights/viewmodels';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { ArrowDownward } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Link, Snackbar, Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminOrTeacherAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviourSummary } from './BehaviourSummary';
import { BehaviourSupplement } from './BehaviourSupplement';
import { accessorForSearch, useInsightsTable } from './InsightsTable';
import { OptionalRouterLink } from './RouterLink';

const AccountListTableKey = 'AccountListTable';

export interface AccountListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  accounts: AccountInfo[];
  exportableViewModel?: ExportableViewModel;
  accountType: ManageableRole;
}

export const AccountList = observer((props: AccountListProps) => {
  const { localizationService, accountService, reactRouterRouteService, navigationService } = useInsightsServices();
  const { className, sx = [], style, title, configId, accounts, accountType, exportableViewModel } = props;
  const insightsStrings = localizationService.localizedStrings.insights;
  const accountStrings = insightsStrings.components.accounts;
  const theme = useTheme();
  const [showToast, setShowToast] = useState(false);

  const tableStateKey = `${AccountListTableKey}-${accountType}`;
  const isStudent = accountType === 'student';
  const renderBehaviourSummary = isStudent;
  const renderGradeLevel = isStudent;

  // We can't use AuthorizationRoleCondition in a MoreActionMenu.
  const canViewTeacherPlanner = accountType === 'teacher' && accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canViewStudentPlanner = accountType === 'student' && accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canCopyIds = accountService.isAllowed(RootAdminRoles);

  async function copyIdToClipboard(account: AccountInfo): Promise<void> {
    await navigator.clipboard.writeText(account.account.id);
    setShowToast(true);
  }

  const detailsRouteTemplate =
    accountType === 'student'
      ? RouteTemplates.studentDetails
      : accountType === 'teacher'
        ? RouteTemplates.teacherDetails
        : undefined;
  const detailsAccountIdParamName =
    accountType === 'student'
      ? RouteParamNames.studentId
      : accountType === 'teacher'
        ? RouteParamNames.teacherId
        : undefined;

  const table = useInsightsTable(
    tableStateKey,
    accounts,
    title ?? '',
    () => [
      renderBehaviourSummary && {
        header: accountStrings.behavioursTitle,
        id: 'behavior',
        enableSorting: false,
        enableColumnActions: false,
        size: 40,
        maxSize: 40,
        grow: false,
        Cell: ({ row }) => (
          <Box
            sx={{
              marginY: 0.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '30px',
              maxWidth: '72px'
            }}
          >
            <BehaviourSummary
              size="small"
              appOpenValue={row.original.oqProfile?.opensTheApp}
              taskCompletionValue={row.original.oqProfile?.marksTasksAsDone}
            />
            {row.original.invitesParent != null && (
              <BehaviourSupplement sx={{ ml: 1 }} size="small" invitesParent={row.original.invitesParent} />
            )}
          </Box>
        )
      },
      {
        header: '',
        id: 'user',
        enableSorting: false,
        enableColumnActions: false,
        size: 40,
        grow: false,
        Cell: ({ row }) =>
          row.original.account.userId.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Tooltip title={accountStrings.connectedUserTooltip}>
                <Icon path={mdiAccount} size={1} color={theme.palette.secondary.main} />
              </Tooltip>
            </Box>
          )
      },
      {
        header: accountStrings.name,
        id: 'name',
        accessorFn: (row) =>
          accessorForSearch([AccountUtils.getDisplayLastFirstName(row.account, ''), row.account.managedIdentifier]),
        size: 300,
        Cell: ({ row }) => {
          const name = AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName);

          return detailsRouteTemplate != null ? (
            <OptionalRouterLink
              variant="body1"
              to={reactRouterRouteService.resolveLocation(detailsRouteTemplate, [
                { name: RouteParamNames.configId, value: configId },
                { name: detailsAccountIdParamName!, value: row.original.account.id }
              ])}
            >
              {name}
            </OptionalRouterLink>
          ) : (
            <Typography>{name}</Typography>
          );
        }
      },
      {
        header: accountStrings.email,
        id: 'email',
        accessorKey: 'account.email',
        size: 300,
        Cell: ({ row, renderedCellValue }) => (
          <Stack>
            <Link variant="body1" target="_top" href={`mailto:${row.original.account.email}`}>
              {renderedCellValue}
            </Link>
            {row.original.account.profile.publicEmail.length > 0 && (
              <Link variant="body2" target="_top" href={`mailto:${row.original.account.profile.publicEmail}`}>
                ✉️&nbsp;{row.original.account.profile.publicEmail}
              </Link>
            )}
          </Stack>
        )
      },
      renderGradeLevel && {
        header: accountStrings.gradeTitle,
        id: 'gradeLevel',
        accessorKey: 'account.gradeLevel',
        sortingFn: 'alphanumeric',
        size: 100,
        Cell: ({ renderedCellValue }) => <Typography variant="body1">{renderedCellValue}</Typography>
      }
    ],
    {
      getTopActionButtons: () => [
        {
          icon: () => <ArrowDownward />,
          tooltip: exportableViewModel?.exportTooltip ?? '',
          onClick: () => void exportableViewModel?.exportToCsv(),
          isDisabled: exportableViewModel?.isExporting,
          isHidden: exportableViewModel?.canExport !== true
        }
      ],
      getRowActionMenuItems: ({ row }) => [
        (canViewTeacherPlanner || canViewStudentPlanner) && {
          title: accountStrings.viewPlanner,
          endAdornment: () => <OpenInNewIcon />,
          onClick: () => void navigationService.navigateToPlannerExternal(configId, row.original.account.id)
        },
        canCopyIds && {
          title: accountStrings.copyId,
          onClick: () => void copyIdToClipboard(row.original)
        }
      ],
      defaultSorting: { id: 'name', desc: false }
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={accountStrings.copyIdNotification}
      />
    </Box>
  );
});
