import {
  EditableConcatenation,
  EditableTransformation,
  Schema,
  SchemaImportOption,
  SchemaSuffixGroup
} from '@shared/models/import';
import { action, computed, makeObservable, observable } from 'mobx';

export interface TargetSchemaAwareViewModel {
  readonly availableSchemas: Schema[];
  targetRootSchema: string;
  readonly availableSuffixes: SchemaSuffixGroup[];
  readonly targetSuffixes: string[];
  addSuffix(value: string): void;
  toggleSuffix(value: string): void;
  readonly availableOptions: SchemaImportOption[];
  selectedOptionNames: string[];

  readonly canSave: boolean;
}

abstract class BaseTargetSchemaAwareViewModel implements TargetSchemaAwareViewModel {
  @observable private _targetRootSchema = '';
  @observable private _targetSuffixes: string[] = [];

  constructor(
    initialSchema: string,
    readonly availableSchemas: Schema[]
  ) {
    makeObservable(this);
    // A rogue comma could have been added by mistake (fixed in Story 17385).
    const schemaParts = initialSchema
      .split(':')
      .map((p) => (p.endsWith(',') ? p.substring(0, p.length - 1) : p))
      .filter((p) => p.length > 0);

    if (schemaParts.length > 0) {
      this._targetRootSchema = schemaParts[0];
      this._targetSuffixes = schemaParts.slice(1).map((s) => `:${s}`);
    }
  }

  protected abstract get targetSchema(): string;
  protected abstract set targetSchema(value: string);

  protected abstract get suggestedImportOptions(): string[];
  protected abstract set suggestedImportOptions(values: string[]);

  @computed
  get targetRootSchema() {
    return this._targetRootSchema;
  }

  set targetRootSchema(value: string) {
    this._targetRootSchema = value;
    this._targetSuffixes = [];
    this.targetSchema = value;
    this.suggestedImportOptions = [];
  }

  @computed
  get availableSuffixes(): SchemaSuffixGroup[] {
    const schema = this.availableSchemas.find((s) => s.name == this._targetRootSchema);

    return schema?.suffixGroups ?? [];
  }

  @computed
  get targetSuffixes() {
    return this._targetSuffixes;
  }

  @action
  addSuffix(value: string) {
    const group = this.availableSuffixes.find((g) => g.suffixes.find((s) => s.name == value) != null);

    if (group == null) {
      throw new Error('Unknown schema suffix');
    }

    this._targetSuffixes = this._targetSuffixes
      .filter((sn) => group.suffixes.find((s) => s.name == sn) == null)
      .concat(value);
    this.targetSchema = this.targetRootSchema + this._targetSuffixes.join('');
  }

  @action
  toggleSuffix(value: string): void {
    // For toggles, we don't need to remove others from same group.
    if (this._targetSuffixes.includes(value)) {
      this._targetSuffixes = this._targetSuffixes.filter((s) => s != value);
    } else {
      this._targetSuffixes.push(value);
    }
    this.targetSchema = this.targetRootSchema + this._targetSuffixes.join('');
  }

  @computed
  get availableOptions(): SchemaImportOption[] {
    const schema =
      this.availableSchemas.find((s) => s.name == this.targetSchema) ??
      this.availableSchemas.find((s) => s.name == this.targetRootSchema);

    return schema?.importOptions ?? [];
  }

  @computed
  get selectedOptionNames(): string[] {
    return this.suggestedImportOptions;
  }

  set selectedOptionNames(values: string[]) {
    this.suggestedImportOptions = values;
  }

  readonly canSave = true;
}

export class AppTransformationSchemaViewModel extends BaseTargetSchemaAwareViewModel {
  constructor(
    private readonly _editableTransformation: EditableTransformation,
    readonly availableSchemas: Schema[]
  ) {
    super(_editableTransformation.targetSchema, availableSchemas);
  }

  @computed
  get targetSchema() {
    return this._editableTransformation.targetSchema;
  }

  set targetSchema(value: string) {
    this._editableTransformation.targetSchema = value;
  }

  @computed
  get suggestedImportOptions() {
    return this._editableTransformation.suggestedImportOptions;
  }

  set suggestedImportOptions(values: string[]) {
    this._editableTransformation.suggestedImportOptions = values;
  }
}

export class AppConcatenationSchemaViewModel extends BaseTargetSchemaAwareViewModel {
  constructor(
    private readonly _editableConcatenation: EditableConcatenation,
    readonly availableSchemas: Schema[]
  ) {
    super(_editableConcatenation.targetSchema, availableSchemas);
  }

  @computed
  get targetSchema() {
    return this._editableConcatenation.targetSchema;
  }

  set targetSchema(value: string) {
    this._editableConcatenation.targetSchema = value;
  }

  @computed
  get suggestedImportOptions() {
    return this._editableConcatenation.suggestedImportOptions;
  }

  set suggestedImportOptions(values: string[]) {
    this._editableConcatenation.suggestedImportOptions = values;
  }
}
