import { Concatenation as PBConcatenation } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../../editables';
import { Concatenation } from '../interfaces';
import { GrpcConcatenation } from './GrpcConcatenation';

export class EditableConcatenation extends EditableModelEx<PBConcatenation> implements Concatenation {
  private readonly _label: EditableStringProperty<PBConcatenation>;
  private readonly _name: EditableStringProperty<PBConcatenation>;
  private readonly _sourceLabels: EditableStringArrayProperty<PBConcatenation>;
  private readonly _columnCount: EditableValuePropertyEx<number, PBConcatenation>;
  private readonly _useColumnNames: EditableValuePropertyEx<boolean, PBConcatenation>;
  private readonly _targetSchema: EditableStringProperty<PBConcatenation>;
  private readonly _suggestedImportOptions: EditableStringArrayProperty<PBConcatenation>;

  static createNew() {
    const pb = new PBConcatenation();

    return new EditableConcatenation(new GrpcConcatenation(pb), true);
  }

  constructor(
    private readonly _originalConcatenation: Concatenation,
    isNew = false
  ) {
    super(_originalConcatenation.toProtobuf(), isNew);

    makeObservable(this);

    this._label = this.addStringField(_originalConcatenation.label, (host, value) => (host.label = value), {
      trim: true
    });
    this._name = this.addStringField(_originalConcatenation.name, (host, value) => (host.name = value), {
      trim: true
    });
    this._sourceLabels = this.addStringArrayField(
      _originalConcatenation.sourceLabels,
      (host, values) => (host.sourceLabels = values)
    );
    this._columnCount = this.addValueField<number>(
      _originalConcatenation.columnCount,
      (host, value) => (host.columnCount = value)
    );
    this._useColumnNames = this.addValueField<boolean>(
      _originalConcatenation.useColumnNames,
      (host, value) => (host.useColumnNames = value)
    );
    this._targetSchema = this.addStringField(
      _originalConcatenation.targetSchema,
      (host, value) => (host.targetSchema = value)
    );
    this._suggestedImportOptions = this.addStringArrayField(
      _originalConcatenation.suggestedImportOptions,
      (host, values) => (host.suggestedImportOptions = values)
    );
  }

  @computed
  get label() {
    return this._label.value;
  }

  set label(value: string) {
    this._label.value = value;
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get sourceLabels() {
    return this._sourceLabels.value;
  }

  set sourceLabels(values: string[]) {
    this._sourceLabels.value = values;
  }

  @computed
  get columnCount() {
    return this._columnCount.value;
  }

  set columnCount(value: number) {
    this._columnCount.value = value;
  }

  @computed
  get useColumnNames() {
    return this._useColumnNames.value;
  }

  set useColumnNames(value: boolean) {
    this._useColumnNames.value = value;
  }

  @computed
  get targetSchema() {
    return this._targetSchema.value;
  }

  set targetSchema(value: string) {
    this._targetSchema.value = value;
  }

  @computed
  get suggestedImportOptions(): string[] {
    return this._suggestedImportOptions.value;
  }

  set suggestedImportOptions(value: string[]) {
    this._suggestedImportOptions.value = value;
  }
}
