import {
  Concatenation,
  EditableAutomatedTransformationImport,
  EditableIncidentTolerance,
  ImportSession,
  Incident,
  Transformation
} from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface AutomatedTransformationImportViewModel {
  readonly transformationImport: EditableAutomatedTransformationImport;
  readonly availableLabels: (Transformation | Concatenation)[];
  readonly incidents: Incident[];
  readonly canApply: boolean;

  addIncidentTolerance(): void;
  deleteIncidentTolerance(tolerance: EditableIncidentTolerance): void;

  apply(): void;
  cancel(): void;
}

export interface AutomatedTransformationImportDialogViewModel {
  readonly isCreating: boolean;
  readonly data: IPromiseBasedObservable<AutomatedTransformationImportViewModel>;
  cancel(): void;
}

export class AppAutomatedTransformationImportViewModel implements AutomatedTransformationImportViewModel {
  constructor(
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _importSession: ImportSession,
    public readonly transformationImport: EditableAutomatedTransformationImport,
    public readonly incidents: Incident[]
  ) {
    makeObservable(this);
  }

  @computed
  get availableLabels(): (Transformation | Concatenation)[] {
    const transformations = this._importSession.transformations.filter((t) => t.targetSchema.length > 0);
    const concatenations = this._importSession.concatenations.filter((c) => c.targetSchema.length > 0);
    return [...transformations, ...concatenations];
  }

  @computed
  get canApply() {
    return (
      this.transformationImport.label.length === 1 &&
      this.transformationImport.incidentTolerances.filter((t) => t.code.length === 0).length === 0
    );
  }

  addIncidentTolerance() {
    this.transformationImport.addIncidentTolerance(EditableIncidentTolerance.createNew());
  }

  deleteIncidentTolerance(tolerance: EditableIncidentTolerance) {
    tolerance.markAsDeleted();
  }

  apply(): void {
    this._onSuccess();
  }

  cancel(): void {
    this._onCancel();
  }
}

export class AppAutomatedTransformationImportDialogViewModel implements AutomatedTransformationImportDialogViewModel {
  constructor(
    private readonly _importerStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _importSession: ImportSession,
    private readonly _transformationImport: EditableAutomatedTransformationImport
  ) {}

  get isCreating() {
    return this._transformationImport.shouldBeCreated;
  }

  get data() {
    return fromPromise(this.loadData());
  }

  cancel(): void {
    this._onCancel();
  }

  private async loadData(): Promise<AutomatedTransformationImportViewModel> {
    let incidents: Incident[] = [];

    try {
      incidents = await this._importerStore.getIncidentCodes(this._localizationService.currentLocale);
    } catch {
      // Do not suggest codes on errors.
    }

    return new AppAutomatedTransformationImportViewModel(
      this._onSuccess,
      this._onCancel,
      this._importSession,
      this._transformationImport,
      incidents.filter((i) => i.severity === 'warning' || i.severity === 'error')
    );
  }
}
