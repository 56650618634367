import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { EditableOnboardingAnswer } from '@shared/models/onboarding/implementations';
import { AdminAuthorizationRoles, RootAdminRoles } from '@shared/models/types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../../AuthorizationRoleCondition';
import { InsightsButton } from '../../InsightsButton';

export interface SensitiveTextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  answer: EditableOnboardingAnswer;
  onViewAnswer: () => Promise<void>;
  disabled?: boolean;
  clientId?: string;
}

export const SensitiveTextFieldQuestion = observer((props: SensitiveTextFieldQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], answer, onViewAnswer, disabled, clientId } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const label = answer.hasAnswer ? strings.answered : strings.notAnswered;
  const buttonLabel = answer.hasAnswer ? strings.changeSensitiveAnswerButton : strings.setSensitiveAnswerButton;

  const [localAnswer, setLocalAnswer] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const saveAnswer = () => {
    // Close before assigning.
    const answerCopy = localAnswer;

    setIsOpen(false);
    setLocalAnswer('');

    answer.sensitiveSimpleTextValue = answerCopy;
  };

  const cancel = () => {
    setIsOpen(false);
  };

  const allowedAccountIds = clientId != null ? [clientId] : [];

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
      <Typography mr={2} sx={{ color: (theme) => theme.palette.primary.main }}>
        {label}
      </Typography>
      {/* Any assigned client can modify the answer, as long as they're not school staff. */}
      <AuthorizationRoleCondition
        allowedRoles={RootAdminRoles}
        allowedAccountIds={allowedAccountIds}
        blockedRoles={['school-staff']}
      >
        <Button
          sx={{ mr: 2 }}
          variant="contained"
          disabled={disabled}
          onClick={() => runInAction(() => setIsOpen(true))}
        >
          {buttonLabel}
        </Button>
      </AuthorizationRoleCondition>
      {answer.hasAnswer && (
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles} allowedAccountIds={allowedAccountIds}>
          <Button title={strings.viewSensitiveAnswer} variant="contained" onClick={() => void onViewAnswer()}>
            {strings.viewSensitiveAnswer}
          </Button>
        </AuthorizationRoleCondition>
      )}

      <Dialog maxWidth="sm" fullWidth open={isOpen} onAbort={cancel} onClose={cancel}>
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.sensitiveAnswerTitle}</DialogTitle>
          <DialogContent>
            <TextField
              type="password"
              label={strings.sensitiveAnswerLabel}
              autoFocus
              fullWidth
              value={localAnswer}
              onChange={(e) => runInAction(() => setLocalAnswer(e.target.value))}
              onSubmit={saveAnswer}
            />
          </DialogContent>
          <DialogActions>
            <InsightsButton onClick={cancel}>{strings.cancelLabel}</InsightsButton>
            <InsightsButton isSubmit onClick={saveAnswer}>
              {strings.saveLabel}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
});
