import { Box, SxProps, Typography } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { ConditionalTypography } from './ConditionalTypography.tsx';
import { RouterLink } from './RouterLink.tsx';
import { Thresholds, ThresholdsInformation } from './ThresholdsInformation.tsx';

export interface SectionNameOneLineProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title: string | ReactNode;
  titleStyle?: CSSProperties;
  subInformation1?: string;
  subInformation2?: string;
  subInformationStyle?: CSSProperties;
  thresholds?: Thresholds;
  sectionColor?: string;
  fontWeight?: string;
  linkTo?: string;
}

export function SectionNameOneLine({
  sx = [],
  className,
  style,
  title,
  titleStyle,
  subInformation1,
  subInformation2,
  subInformationStyle,
  thresholds,
  sectionColor,
  fontWeight,
  linkTo
}: SectionNameOneLineProps) {
  return (
    <Box
      sx={{ ...sx, minHeight: 30 }}
      className={className}
      style={style}
      display="flex"
      flexDirection="row"
      flex={1}
      paddingLeft={1}
      borderLeft={`4px solid ${sectionColor}`}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        {/* Display the title as a link if `linkTo` was specified */}
        {linkTo != null && (
          <RouterLink style={titleStyle} to={linkTo}>
            <ConditionalTypography
              sx={{ color: (theme) => theme.palette.primary.main }}
              style={titleStyle}
              text={title}
              noWrap
              fontWeight={`${fontWeight} !important`}
            ></ConditionalTypography>
          </RouterLink>
        )}

        {/* Display the title as a text if `linkTo` was not specified */}
        {linkTo == null && (
          <Typography style={titleStyle} noWrap fontWeight={`${fontWeight} !important`}>
            {title}
          </Typography>
        )}

        {(subInformation1 != null || subInformation2 != null) && (
          <Box marginLeft={3}>
            {subInformation1 != null && (
              <Typography
                display="inline"
                sx={{ color: (theme) => theme.palette.text.secondary }}
                style={subInformationStyle}
                noWrap
                fontWeight={`${fontWeight} !important`}
              >
                {subInformation1}
              </Typography>
            )}

            {subInformation2 != null && (
              <>
                {subInformation1 != null && (
                  <Typography
                    display="inline"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                    style={subInformationStyle}
                    noWrap
                    fontWeight={`${fontWeight} !important`}
                  >
                    {' – '}
                  </Typography>
                )}

                <Typography
                  display="inline"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  style={subInformationStyle}
                  noWrap
                  fontWeight={`${fontWeight} !important`}
                >
                  {subInformation2}
                </Typography>
              </>
            )}
          </Box>
        )}

        {thresholds != null && (
          <Box marginLeft={subInformation1 != null || subInformation2 != null ? 1 : undefined} minWidth="min-content">
            <ThresholdsInformation thresholds={thresholds} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
