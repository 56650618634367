import { SectionInfo } from '@insights/models';
import { SectionUtils } from '@insights/utils';
import { SectionsViewModel } from '@insights/viewmodels';
import EnrollmentIcon from '@mui/icons-material/HowToReg';
import { Box, Snackbar, Stack, SxProps, Tooltip } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { RootAdminRoles } from '@shared/models/types';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from './InsightsTable';
import { SectionListTeachersCell } from './SectionListTeachersCell';
import { SectionName } from './SectionName';

const TableStateKey = 'SectionList';

export interface SectionListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  sections: SectionsViewModel;
  usePagination?: boolean;
}

export const SectionList = observer((props: SectionListProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { sx = [], configId, className, style, title, sections, usePagination = true } = props;
  const strings = localizationService.localizedStrings.insights.components;
  const sectionsStrings = strings.sections;
  const canCopyIds = accountService.isAllowed(RootAdminRoles);
  const [showToast, setShowToast] = useState(false);

  async function copyIdToClipboard(section: SectionInfo): Promise<void> {
    if (section.section == null) {
      return;
    }

    await navigator.clipboard.writeText(section.section.id);
    setShowToast(true);
  }

  const table = useInsightsTable(
    TableStateKey,
    sections.sections,
    title ?? '',
    () => [
      {
        header: sectionsStrings.title,
        accessorFn: (row) => accessorForSearch([row.section?.title, row.section?.importId]),
        id: 'title',
        size: 300,
        Cell: ({ row }) => {
          const linkTo =
            row.original.section != null
              ? reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                  { name: RouteParamNames.configId, value: configId },
                  { name: RouteParamNames.sectionId, value: row.original.section.id }
                ])
              : undefined;

          return (
            <SectionName
              sx={{ minHeight: '30px' }}
              title={SectionUtils.formatTitle(row.original.section, sectionsStrings.noTitle)}
              color={row.original.section?.color}
              linkTo={linkTo}
            />
          );
        }
      },
      {
        header: sectionsStrings.teachers,
        // Teachers are sorted in the cell, so we must also sort them when providing searchable/sortable full data.
        accessorFn: (row) =>
          _.flatMap(
            row.teachers
              ?.map((t) => [
                AccountUtils.getDisplayLastFirstName(t.account, '?'),
                t.account.email,
                t.account.managedIdentifier
              ])
              .slice()
              .sort((a, b) => a[0].localeCompare(b[0], localizationService.currentLocale, { sensitivity: 'base' })) ??
              []
          ).join(' '),
        id: 'teachers',
        Cell: ({ row }) => <SectionListTeachersCell configId={configId} sectionInfo={row.original} />
      },
      {
        header: sectionsStrings.grade,
        accessorKey: 'section.gradeLevel',
        sortingFn: 'alphanumeric'
      },
      {
        header: sectionsStrings.group,
        accessorKey: 'section.sectionNumber',
        sortingFn: 'alphanumeric'
      },
      {
        header: sectionsStrings.room,
        accessorKey: 'section.defaultRoomName'
      },
      {
        header: sectionsStrings.studentCount,
        accessorFn: (row) => row.students?.length ?? 0,
        id: 'studentCount',
        sortingFn: 'alphanumeric',
        size: 100,
        enableColumnFilter: false,
        Cell: ({ row, renderedCellValue }) => {
          const hasAutoEnrollment =
            row.original.section != null &&
            (row.original.section.autoEnrollRoles.length > 0 || row.original.section.autoEnrollTags.length > 0);

          return (
            <Stack direction="row">
              {hasAutoEnrollment && (
                <Tooltip
                  title={strings.getAutoEnrollmentRolesAndTags(
                    row.original.section!.autoEnrollRoles,
                    row.original.section!.autoEnrollTags
                  )}
                >
                  <EnrollmentIcon fontSize="small" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              {renderedCellValue}
            </Stack>
          );
        }
      }
    ],
    {
      getRowActionMenuItems: ({ row }) => [
        canCopyIds && {
          title: sectionsStrings.copyId,
          onClick: () => void copyIdToClipboard(row.original)
        }
      ],
      defaultSorting: { id: 'title', desc: false },
      disablePagination: !usePagination
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={sectionsStrings.copyIdNotification}
      />
    </Box>
  );
});
