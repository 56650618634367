import {
  AuthorizationRoleCondition,
  ErrorIndicator,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import { SettingsStore } from '@insights/services';
import { SectionUtils } from '@insights/utils';
import {
  PublishedTasksBySectionInfo,
  PublishedTasksBySectionPageInfo,
  PublishedTasksBySectionViewModel
} from '@insights/viewmodels';
import { mdiCalendarEdit } from '@mdi/js';
import Icon from '@mdi/react';
import ExportIcon from '@mui/icons-material/ArrowDownward';
import {
  Box,
  IconButton,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ReactRouterRouteService } from '@shared/web/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinLayoutWidth, MinTinyChartHeight } from '../../../Constants';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface PublishedTasksBySectionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  displaySectionName: boolean;
  displayWeekNavigation?: boolean;
  viewModel: PublishedTasksBySectionViewModel;
}

export const PublishedTasksBySection = observer((props: PublishedTasksBySectionProps) => {
  const { localizationService, reactRouterRouteService, settingsStore } = useInsightsServices();
  const { className, sx = [], style, viewModel, displaySectionName, displayWeekNavigation = true } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const theme = useTheme();

  return (
    <Box sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="all-published-tasks-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.publishedTasksTitle,
          titleVariant: 'h6'
        }}
      >
        <ObservablePresenter
          sx={{ minHeight: MinTinyChartHeight, minWidth: MinLayoutWidth }}
          data={viewModel.data}
          indicatorsSize="normal"
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={(configData) => (
            <Box display="flex" flexDirection="column">
              <Box marginLeft={3} marginBottom={2} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h6">{strings.publishedTasksTitle}</Typography>

                <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                  <Tooltip title={strings.editAssessmentPlanningDatesButtonTooltip}>
                    <IconButton onClick={() => void viewModel.editAssessmentPlanningDates()}>
                      <Icon path={mdiCalendarEdit} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                  </Tooltip>
                </AuthorizationRoleCondition>

                <Box flex={1} />

                <Typography variant="body1">{strings.showOnlyImportantTasks}</Typography>

                <Switch
                  checked={viewModel.importantTasksOnly}
                  onChange={(_, checked) => (viewModel.importantTasksOnly = checked)}
                />

                <Tooltip title={strings.csvExportStudentTasksTooltip}>
                  <IconButton onClick={() => void viewModel.exportTasksByStudentToCsv()}>
                    {viewModel.isExporting ? <LoadingIndicator size="tiny" /> : <ExportIcon />}
                  </IconButton>
                </Tooltip>
              </Box>

              <Box flex={1}>
                {viewModel.pageData.case({
                  pending: () =>
                    renderEmptyTable(
                      configData,
                      displaySectionName,
                      viewModel,
                      localizationService,
                      reactRouterRouteService,
                      settingsStore,
                      theme
                    ),
                  rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
                  fulfilled: (data) =>
                    renderTable(
                      configData,
                      data,
                      displaySectionName,
                      viewModel,
                      localizationService,
                      reactRouterRouteService,
                      settingsStore,
                      theme
                    )
                })}
              </Box>

              <Box
                sx={{ minHeight: 30 }}
                marginTop={2}
                marginLeft={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box flex={1} />

                {/* Show a loading indicator when changing the current page */}
                {viewModel.pageData.state === 'pending' && <LoadingIndicator size="tiny" />}

                {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
              </Box>
            </Box>
          )}
        />
      </RequiresFeatureCondition>
    </Box>
  );
});

function renderEmptyTable(
  configData: PublishedTasksBySectionInfo,
  displaySectionName: boolean,
  viewModel: PublishedTasksBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      configData,
      {
        schoolDays: configData.schoolDays.filter((schoolDay) =>
          schoolDay.day.isWithin(pageRange.startDay, pageRange.endDay)
        ),
        dayInfos: [],
        weekInfos: []
      },
      displaySectionName,
      viewModel,
      localizationService,
      reactRouterRouteService,
      settingsStore,
      theme
    );
  }

  return null;
}

function renderTable(
  configData: PublishedTasksBySectionInfo,
  pageData: PublishedTasksBySectionPageInfo,
  displaySectionName: boolean,
  viewModel: PublishedTasksBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      {renderTableHeader(configData, pageData, displaySectionName, localizationService, theme)}
      {renderTableBody(
        configData,
        pageData,
        displaySectionName,
        viewModel,
        localizationService,
        reactRouterRouteService,
        settingsStore,
        theme
      )}
    </Table>
  );
}

function renderTableHeader(
  _configData: PublishedTasksBySectionInfo,
  pageData: PublishedTasksBySectionPageInfo,
  displaySectionName: boolean,
  localizationService: LocalizationService,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <TableHead>
      <TableRow sx={{ verticalAlign: 'center', height: '50px' }}>
        {displaySectionName && (
          <TableCell sx={{ width: '20%', cursor: 'default' }}>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.text.secondary, fontWeight: 500, lineHeight: 'normal' }}
            >
              {strings.section}
            </Typography>
          </TableCell>
        )}

        {pageData.schoolDays
          .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
          .map((schoolDay) => (
            <TableCell
              key={schoolDay.day.asDateString}
              sx={{ textAlign: 'center', paddingRight: theme.spacing(2), width: 'calc(80%/6)', cursor: 'default' }}
            >
              <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
            </TableCell>
          ))}

        <TableCell
          sx={{
            textAlign: 'center',
            paddingRight: theme.spacing(2),
            width: 'calc(80%/6)',
            cursor: 'default',
            borderLeft: `1px solid ${grey[300]}`
          }}
          className={'weekCell'}
        >
          <SchoolWeekHeader schoolDay={pageData.schoolDays[0]} displayType="column-header" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function renderTableBody(
  configData: PublishedTasksBySectionInfo,
  pageData: PublishedTasksBySectionPageInfo,
  displaySectionName: boolean,
  viewModel: PublishedTasksBySectionViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <TableBody>
      {configData.sections.length === 0 && (
        <TableRow>
          <TableCell colSpan={99} align="center">
            <Typography variant="body2">{strings.emptyTable}</Typography>
          </TableCell>
        </TableRow>
      )}
      {configData.sections.length > 0 &&
        configData.sections.map((sectionInfo) => {
          const weekInfo = pageData.weekInfos.find(
            (i) => i.schoolDay == null && i.sectionId === sectionInfo.section.id
          );

          const publishedTasksForWeekCount = weekInfo?.publishedTasks.length ?? 0;
          const isWeekValueCellStyle = publishedTasksForWeekCount > 0;

          return (
            <TableRow key={sectionInfo.section.id} sx={{ verticalAlign: 'center', height: '50px' }}>
              {displaySectionName && (
                <TableCell sx={{ width: '20%', cursor: 'default' }}>
                  <SectionName
                    color={sectionInfo.section.color}
                    title={SectionUtils.formatTitle(sectionInfo.section)}
                    subInformation1={sectionInfo.section.sectionNumber}
                    subInformation2={strings.localizedNumberOfStudents(sectionInfo.students.size)}
                    linkTo={
                      settingsStore.isEmbedded
                        ? undefined
                        : reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                            {
                              name: RouteParamNames.configId,
                              value: viewModel.configId
                            },
                            {
                              name: RouteParamNames.sectionId,
                              value: sectionInfo.section.id
                            }
                          ])
                    }
                  />
                </TableCell>
              )}

              {pageData.schoolDays
                .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
                .map((schoolDay) => {
                  const dayInfo = pageData.dayInfos.find(
                    (d) => d.schoolDay!.day.isSame(schoolDay.day) && d.sectionId === sectionInfo.section.id
                  );
                  const publishedTasksCount = dayInfo?.publishedTasks.length ?? 0;

                  return (
                    <TableCell
                      key={`${sectionInfo.section.id} - ${schoolDay.day.asDateString}`}
                      sx={{
                        textAlign: 'center',
                        paddingRight: theme.spacing(2),
                        width: 'calc(80%/6)',
                        cursor: isWeekValueCellStyle ? 'pointer' : 'default',
                        '&:hover': {
                          backgroundColor: isWeekValueCellStyle ? grey[50] : undefined
                        }
                      }}
                      onClick={() =>
                        dayInfo &&
                        publishedTasksCount > 0 &&
                        void showDetail(
                          viewModel,
                          sectionInfo.section,
                          sectionInfo.students.size,
                          dayInfo.schoolDay!,
                          dayInfo.schoolDay!,
                          dayInfo.publishedTasks
                        )
                      }
                    >
                      {publishedTasksCount > 0 && <Typography variant="body1">{publishedTasksCount}</Typography>}
                    </TableCell>
                  );
                })}

              <TableCell
                sx={{
                  textAlign: 'center',
                  paddingRight: theme.spacing(2),
                  width: 'calc(80%/6)',
                  cursor: isWeekValueCellStyle ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: isWeekValueCellStyle ? grey[50] : undefined
                  }
                }}
                className={clsx('weekCell')}
                onClick={() =>
                  weekInfo &&
                  publishedTasksForWeekCount > 0 &&
                  void showDetail(
                    viewModel,
                    sectionInfo.section,
                    sectionInfo.students.size,
                    pageData.schoolDays[0],
                    pageData.schoolDays[pageData.schoolDays.length - 1],
                    weekInfo.publishedTasks
                  )
                }
              >
                {publishedTasksForWeekCount > 0 && (
                  <Typography variant="body1">{publishedTasksForWeekCount}</Typography>
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
}

async function showDetail(
  viewModel: PublishedTasksBySectionViewModel,
  section: SectionModel,
  sectionStudentCount: number,
  fromDay: SchoolDay,
  toDay: SchoolDay,
  tasks: ContentDefinitionModel[]
) {
  await viewModel.showDetail(viewModel.configId, section, sectionStudentCount, fromDay, toDay, tasks);
}
