import { ParentChildrenEditionViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import ConfirmIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid2, SxProps, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils/models';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';

const SelectedTableStateKey = 'ParentChildrenEditionSelected';
const AvailableTableStateKey = 'ParentChildrenEditionAvailable';

export interface ParentChildrenEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ParentChildrenEditionViewModel;
}

export const ParentChildrenEdition = observer((props: ParentChildrenEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const insightsStrings = localizationService.localizedStrings.insights;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  const selectedChildrenTable = useInsightsTable(
    SelectedTableStateKey,
    viewModel.selectedChildren,
    strings.selectedChildren,
    () => [
      {
        header: '',
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account),
            row.account?.email,
            row.account?.managedIdentifier
          ]),
        id: 'name',
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1" style={{ fontStyle: row.original.isPending ? 'italic' : 'normal' }}>
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <DeleteIcon fontSize="small" />,
          tooltip: strings.removeChildTooltip,
          onClick: () => viewModel.removeAccount(row.original.accountId)
        },
        row.original.isPending && {
          icon: () => <ConfirmIcon fontSize="small" />,
          tooltip: strings.confirmChildTooltip,
          onClick: () => viewModel.confirmAccount(row.original.accountId)
        }
      ],
      disablePagination: true,
      disableGlobalFilter: true
    }
  );

  const availableStudentsTable = useInsightsTable(
    AvailableTableStateKey,
    viewModel.availableStudents,
    strings.availableStudents,
    () => [
      {
        header: '',
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(row.original.account, insightsStrings.noName)}
          </Typography>
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <AddIcon fontSize="small" />,
          tooltip: strings.addChildTooltip,
          onClick: () => viewModel.addAccount(row.original.account.id, false)
        }
      ]
    }
  );

  return (
    <Grid2 sx={sx} className={className} style={style} container spacing={2}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={selectedChildrenTable} />
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <MaterialReactTable table={availableStudentsTable} />
      </Grid2>
    </Grid2>
  );
});
