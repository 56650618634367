import { Storage } from '@shared/services';
import { AppBaseStore, BaseStore } from '@shared/services/stores';
import { Dictionary } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

// Why "2": We used to persist a dictionary of dictionaries of numbers, for no apparent reason
//          (hypothesys: We got confused with the full list of available page sizes?)
const PageSizeStorageKey = 'TablePreferencesPageSize2';
// Why "2": MaterialReactTable has different sorting keys and we must not get conflicts
//          with previously saved sorting keys.
const SortingStorageKey = 'TablePreferencesSorting2';

// This interface is identical to MRT_PaginationState from material-react-table.
export interface TableStatePagination {
  pageIndex: number;
  pageSize: number;
}

export interface TableStateSorting {
  id: string;
  desc: boolean;
}

export interface TablePreferences extends BaseStore {
  initialize: () => Promise<void>;

  getPageSize: (tableKey: string, defaultValue?: number) => number | undefined;
  savePageSize: (tableKey: string, pageSize: number) => Promise<void>;

  getCurrentPage: (tableKey: string) => number | undefined;
  saveCurrentPage: (tableKey: string, currentPage: number) => void;

  // Only one sort order supported by default (for cleaner code).
  getSorting: (tableKey: string, defaultValue?: TableStateSorting) => TableStateSorting[] | undefined;
  saveSorting: (tableKey: string, sorting: TableStateSorting[]) => Promise<void>;

  getSearchText: (tableKey: string) => string | undefined;
  saveSearchText: (tableKey: string, searchText: string) => void;
}

export class AppTablePreferences extends AppBaseStore implements TablePreferences {
  @observable private _tableStateCurrentPageMap = new Map<string, number>();
  @observable private _tableStatePageSizeMap = new Map<string, number>();
  @observable private _tableStateSortingMap = new Map<string, TableStateSorting[]>();
  @observable private _tableStateSearchTextMap = new Map<string, string>();
  private _persistedPageSizes: Dictionary<number> = {};
  private _persistedSortings: Dictionary<TableStateSorting[]> = {};

  constructor(private readonly _storage: Storage) {
    super(`AppTablePrefences`);
    makeObservable(this);
  }

  async initialize(): Promise<void> {
    await this.loadFromStorage();
  }

  getPageSize(tableKey: string, defaultValue?: number): number | undefined {
    return this._tableStatePageSizeMap.get(tableKey) ?? defaultValue;
  }

  async savePageSize(tableKey: string, pageSize: number): Promise<void> {
    runInAction(() => this._tableStatePageSizeMap.set(tableKey, pageSize));

    this._persistedPageSizes[tableKey] = pageSize;
    await this._storage.set(PageSizeStorageKey, this._persistedPageSizes);
  }

  getCurrentPage(tableKey: string): number | undefined {
    return this._tableStateCurrentPageMap.get(tableKey);
  }

  @action
  saveCurrentPage(tableKey: string, currentPage: number): void {
    this._tableStateCurrentPageMap.set(tableKey, currentPage);
  }

  getSorting(tableKey: string, defaultValue?: TableStateSorting): TableStateSorting[] | undefined {
    const stored = this._tableStateSortingMap.get(tableKey);

    if ((stored == null || stored.length === 0) && defaultValue != null) {
      return [defaultValue];
    }

    return stored;
  }

  async saveSorting(tableKey: string, sorting: TableStateSorting[]): Promise<void> {
    runInAction(() => this._tableStateSortingMap.set(tableKey, sorting));

    this._persistedSortings[tableKey] = sorting;
    await this._storage.set(SortingStorageKey, this._persistedSortings);
  }

  getSearchText = computedFn((tableKey: string): string | undefined => {
    return this._tableStateSearchTextMap.get(tableKey);
  });

  @action
  saveSearchText(tableKey: string, searchText: string): void {
    this._tableStateSearchTextMap.set(tableKey, searchText);
  }

  async clear() {
    this.clearTableStateSearchTexts();
    this.clearTableStateCurrentPages();

    await Promise.all([this.clearTableStatePageSizes(), this.clearTableStateSortings()]);

    await super.clear();
  }

  private async clearTableStatePageSizes(): Promise<void> {
    runInAction(() => this._tableStatePageSizeMap.clear());
    await this._storage.delete(PageSizeStorageKey);
  }

  @action
  private clearTableStateCurrentPages(): void {
    this._tableStateCurrentPageMap.clear();
  }

  private async clearTableStateSortings(): Promise<void> {
    runInAction(() => this._tableStateSortingMap.clear());
    await this._storage.delete(SortingStorageKey);
  }

  @action
  private clearTableStateSearchTexts(): void {
    this._tableStateSearchTextMap.clear();
  }

  private async loadFromStorage(): Promise<void> {
    this._persistedPageSizes = (await this._storage.get<Dictionary<number>>(PageSizeStorageKey)) ?? {};
    this._persistedSortings = (await this._storage.get<Dictionary<TableStateSorting[]>>(SortingStorageKey)) ?? {};

    runInAction(() => {
      this._tableStatePageSizeMap = new Map(Object.entries(this._persistedPageSizes));
      this._tableStateSortingMap = new Map(Object.entries(this._persistedSortings));
    });
  }
}
