import { AlertService } from '@insights/services';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ExternalAccount, ExternalAssociation, ExternalSection } from '@shared/models/connectors';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { format, intlFormatDistance } from 'date-fns';
import _, { Dictionary } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export type UnknownExternalSection = 'unknown';

export interface ExternalAssociationViewModel {
  readonly associationId?: string;
  // The section is optional to display orphan associations.
  readonly section?: SectionModel;
  readonly owner?: AccountModel;
  setOwnerId(id: string): void;
  readonly isLinkOnly: boolean;
  readonly externalSection?: ExternalSection | UnknownExternalSection;
  readonly externalSectionId?: string;
  readonly externalLink: string;
  minimumDate?: Day;
  maximumDate?: Day;
  readonly lastUpdate?: Date;
  readonly lastUpdateReadable?: string;
  readonly lastUpdateTooltip: string | undefined;
  readonly lastUpdateResult?: boolean;
  readonly lastUpdateMessage: string;
  readonly lastSuccessfulUpdate?: Date;

  // A broken association can only be unset.
  readonly isBroken: boolean;

  readonly hasChanges: boolean;
  readonly hasAssociation: boolean;
  readonly hasExternalSection: boolean;
  readonly isSyncing: boolean;

  readonly teachers: AccountModel[];

  setExternalSection(externalSectionId: string | undefined, defaultMinDate?: Day, defaultMaxDate?: Day): void;
  setExternalLink(externalLink: string, defaultMinDate?: Day, defaultMaxDate?: Day): void;

  applyChanges(): Promise<void>;
  confirmChanges(): void;
  resetChanges(): void;

  syncAssociation(forceRefresh: boolean): Promise<void>;
  testAssociation(): Promise<void>;
}

export class AppExternalAssociationViewModel implements ExternalAssociationViewModel {
  @observable private _association?: ExternalAssociation;
  @observable private _owner?: AccountModel;
  @observable private _minimumDate?: Day;
  @observable private _maximumDate?: Day;
  @observable private _externalSection?: ExternalSection | UnknownExternalSection;
  @observable private _externalLink = '';
  @observable private _hasChanges = false;
  @observable private _isSyncing = false;
  @observable private _syncSuccessMessage?: string;
  @observable private _syncErrorMessage?: string;
  private readonly _isBroken: boolean;
  private _latestAssociation?: ExternalAssociation;
  private _hasPendingUpdate = false;

  constructor(
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _externalAccount: ExternalAccount,
    originalAssociation: ExternalAssociation | undefined,
    private readonly _configId: string,
    public readonly section: SectionModel | undefined,
    private readonly _externalSectionsById: Dictionary<ExternalSection>,
    private readonly _accountsById: Dictionary<AccountModel>
  ) {
    makeObservable(this);
    // Orphan associations can be created from a null section. But we need one of them.
    if (originalAssociation == null && section == null) {
      throw new Error('Invalid argument: Both the association and the section are null.');
    }

    this._association = this._latestAssociation = originalAssociation;
    // These values can be set to "none", so we keep the original in the observable right away.
    this._owner = originalAssociation && _accountsById[originalAssociation.ownerId];

    if (this.isLinkOnly) {
      this._externalLink = originalAssociation?.externalSectionId ?? '';
    } else {
      this._externalSection =
        originalAssociation && (_externalSectionsById[originalAssociation.externalSectionId] ?? 'unknown');
    }

    this._isBroken = section == null && originalAssociation != null;
  }

  @computed
  get associationId() {
    return this._association?.id;
  }

  get isLinkOnly() {
    return this._externalAccount.kind === 'calendars';
  }

  @computed
  get externalSection() {
    return this._externalSection;
  }

  @computed
  get externalSectionId() {
    return this._externalSection === 'unknown' ? undefined : this._externalSection?.id;
  }

  @computed
  get externalLink() {
    return this._externalLink;
  }

  @action
  setExternalSection(externalSectionId: string | undefined, defaultMinDate: Day, defaultMaxDate: Day) {
    if (this.section == null && externalSectionId != null && externalSectionId.length > 0) {
      throw new Error('Invalid operation. Orphan associations must not be editable, only deletable.');
    }

    if (externalSectionId != null && externalSectionId.length > 0) {
      this._externalSection = this._externalSectionsById[externalSectionId];

      if (this._externalSection == null) {
        console.error('Unknown external section id assigned to association.');
      }
    } else {
      this._externalSection = undefined;
    }

    if (this._owner == null && this.section != null && this.section.teacherIds.length > 0) {
      this.setOwnerId(this.section.teacherIds[0]);
    }

    if (this._minimumDate == null) {
      this._minimumDate = defaultMinDate;
    }

    if (this._maximumDate == null) {
      this._maximumDate = defaultMaxDate;
    }

    this._hasChanges = true;
  }

  @action
  setExternalLink(externalLink: string, defaultMinDate: Day, defaultMaxDate: Day) {
    if (this.section == null) {
      throw new Error('Invalid operation. Orphan associations must not be editable, only deletable.');
    }

    this._externalLink = externalLink;

    if (this._owner == null && this.section != null && this.section.teacherIds.length > 0) {
      this.setOwnerId(this.section.teacherIds[0]);
    }

    if (this._minimumDate == null) {
      this._minimumDate = defaultMinDate;
    }

    if (this._maximumDate == null) {
      this._maximumDate = defaultMaxDate;
    }

    this._hasChanges = true;
  }

  @computed
  get owner() {
    return this._owner;
  }

  @action
  setOwnerId(id: string) {
    if (id.length === 0) {
      this._owner = undefined;
    } else {
      this._owner = this._accountsById[id];
    }

    this._hasChanges = true;
  }

  get teachers(): AccountModel[] {
    if (this.section == null) {
      return [];
    }

    return this.section.teacherIds.map((id) => this._accountsById[id]).filter((t) => t != null);
  }

  @computed
  get minimumDate() {
    return this._minimumDate ?? this._association?.minimumDate;
  }

  set minimumDate(day: Day | undefined) {
    this._hasChanges = true;
    this._minimumDate = day;
  }

  @computed
  get maximumDate() {
    return this._maximumDate ?? this._association?.maximumDate;
  }

  set maximumDate(day: Day | undefined) {
    this._hasChanges = true;
    this._maximumDate = day;
  }

  @computed
  get lastUpdate() {
    return this._association?.lastUpdate;
  }

  @computed
  get lastUpdateReadable() {
    if (this._syncSuccessMessage != null || this._syncErrorMessage != null) {
      return undefined;
    }

    return this._association?.lastUpdate != null
      ? intlFormatDistance(this._association.lastUpdate, new Date(), {
          locale: this._localizationService.currentLocale
        })
      : undefined;
  }

  @computed
  get lastUpdateTooltip() {
    if (this._syncSuccessMessage != null || this._syncErrorMessage != null) {
      // When a sync was made manually, we don't need a tooltip.
      return undefined;
    }

    const association = this._association;
    const mediumDateFormat = this._localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime;

    let tooltip = association?.lastUpdate != null ? format(association.lastUpdate, mediumDateFormat) : '';

    if (association?.lastUpdateResult === false) {
      tooltip = association.lastUpdateMessage + '\n' + tooltip;

      if (association.lastSuccessfulUpdate != null) {
        tooltip +=
          `\n` +
          this._localizationService.localizedStrings.insights.viewModels.connectors.lastSuccessfullUpdate +
          format(association.lastSuccessfulUpdate, mediumDateFormat);
      }
    }

    return tooltip;
  }

  @computed
  get lastSuccessfulUpdate() {
    return this._association?.lastSuccessfulUpdate;
  }

  @computed
  get lastUpdateResult() {
    if (this._syncSuccessMessage != null) {
      return true;
    } else if (this._syncErrorMessage != null) {
      return false;
    }

    return this._association?.lastUpdateResult;
  }

  @computed
  get lastUpdateMessage() {
    if (this._syncSuccessMessage != null) {
      return this._syncSuccessMessage;
    } else if (this._syncErrorMessage != null) {
      return this._syncErrorMessage;
    }

    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;
    const status = this._association?.queueStatus;
    const line1 = this._association?.lastUpdateMessage;
    const line2 = status?.position != null ? strings.getQueueMessage(status.position) : null;
    let line3: string | undefined = undefined;

    if (status?.time != null) {
      line3 = format(
        status.time,
        this._localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime
      );
    }

    return _.compact([line1, line2, line3]).join(' - ');
  }

  @computed
  get isBroken() {
    return this._isBroken;
  }

  @computed
  get hasChanges() {
    return this._hasChanges;
  }

  @computed
  get hasAssociation() {
    return this._association != null;
  }

  @computed
  get hasExternalSection() {
    return this._externalSection != null;
  }

  @computed
  get isSyncing() {
    return this._isSyncing;
  }

  @action
  async applyChanges(): Promise<void> {
    if (this.hasChanges) {
      if (this._association == null) {
        await this.applyCreated();
      } else if (this._externalSection == null) {
        await this.applyDeleted();
      } else if (this._externalSection === 'unknown') {
        await this.applyUpdated();
      } else if (this._association.externalSectionId !== this._externalSection.id) {
        await this.applyReplaced();
      } else {
        await this.applyUpdated();
      }
    }
  }

  @computed
  private get safeExternalSection(): ExternalSection | undefined {
    if (this.externalSection == null || this.externalSection === 'unknown') {
      return undefined;
    }
    return this.externalSection;
  }

  private async applyCreated(): Promise<void> {
    if (this.section == null) {
      throw new Error('Invalid operation. Orphan associations can only be deleted.');
    }

    if (
      (this.isLinkOnly ? this._externalLink.length === 0 : this.safeExternalSection == null) ||
      this.minimumDate == null ||
      this.maximumDate == null
    ) {
      console.error('Unexpected state for created association.');
      runInAction(() => this.resetChanges());
    } else {
      const externalSectionId = this.isLinkOnly ? this._externalLink : this.safeExternalSection!.id;

      const association = await this._connectorsStore.createExternalAssociation(
        this._configId,
        this.section.id,
        this._owner?.id ?? '',
        this._externalAccount.kind,
        externalSectionId,
        this._externalAccount.id,
        this.minimumDate,
        this.maximumDate
      );

      this.prepareChanges(association);
    }
  }

  private async applyDeleted(): Promise<void> {
    await this._connectorsStore.deleteExternalAssociation(this._association!.id);

    this.prepareChanges(undefined);
  }

  private async applyReplaced(): Promise<void> {
    if (this.section == null) {
      throw new Error('Invalid operation. Orphan associations can only be deleted.');
    }

    // Create the new one first.
    const association = await this._connectorsStore.createExternalAssociation(
      this._configId,
      this.section.id,
      this._owner?.id ?? '',
      this._externalAccount.kind,
      this.safeExternalSection!.id,
      this._externalAccount.id,
      this.minimumDate!,
      this.maximumDate!
    );

    // At this point, if deletion fails, we're kinda stuck. Too bad.
    await this._connectorsStore.deleteExternalAssociation(this._association!.id);

    this.prepareChanges(association);
  }

  private async applyUpdated(): Promise<void> {
    const ownerId = this._owner?.id ?? '';

    if (this.minimumDate == null || this.maximumDate == null) {
      console.error('Unexpected state for updated association.');
      runInAction(() => this.resetChanges());
    } else {
      if (
        this.minimumDate === this._association!.minimumDate &&
        this.maximumDate === this._association!.maximumDate &&
        ownerId === this._association!.ownerId
      ) {
        // Our "hasChanges" management does not handle "returning to the original state by user action".
        runInAction(() => this.resetChanges());
      } else {
        const association = await this._connectorsStore.updateExternalAssociation(
          this._association!.id,
          ownerId,
          this.minimumDate,
          this.maximumDate
        );

        this.prepareChanges(association);
      }
    }
  }

  @action
  confirmChanges(): void {
    if (this._hasPendingUpdate) {
      this._hasPendingUpdate = false;
      this._association = this._latestAssociation;
      this._syncSuccessMessage = this._syncErrorMessage = undefined;
      this.resetChanges();
    }
  }

  @action
  resetChanges() {
    this._minimumDate = undefined;
    this._maximumDate = undefined;
    // See ctor.
    this._externalSection =
      this._association && (this._externalSectionsById[this._association.externalSectionId] ?? 'unknown');
    this._owner = this._association && this._accountsById[this._association.ownerId];
    this._hasChanges = false;
  }

  private prepareChanges(association: ExternalAssociation | undefined) {
    this._hasPendingUpdate = true;
    this._latestAssociation = association;
  }

  @action
  async syncAssociation(forceRefresh: boolean) {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._association == null) {
      console.error('Unexpected state for syncAssociation. The UI should have prevented this.');
      return;
    }

    this._isSyncing = true;
    this._syncSuccessMessage = this._syncErrorMessage = undefined;

    try {
      const result = await this._connectorsStore.syncExternalAssociation(this._association.id, forceRefresh);
      runInAction(() => (this._syncSuccessMessage = strings.getSyncMessage(result)));
    } catch (error) {
      // Do not use error for message, too long. Queuing the sync should rarely fail.
      console.error((error as Error).message);
      runInAction(() => (this._syncErrorMessage = strings.failedToSync));
    } finally {
      runInAction(() => (this._isSyncing = false));
    }
  }

  async testAssociation() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._association == null) {
      console.error('Unexpected state for syncAssociation. The UI should have prevented this.');
      return;
    }

    this._isSyncing = true;

    try {
      const result = await this._connectorsStore.testExternalAssociation(this._association.id);
      await this._alertService.showMessage({
        title: strings.testAssociationResultsTitle,
        message: strings.testAssociationResultsMessage(result.updated, result.removed, result.total, result.results)
      });
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + '\n' + (error as Error).message
      });
    } finally {
      runInAction(() => {
        this._isSyncing = false;
      });
    }
  }
}
