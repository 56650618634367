import { AccountSectionsEditionCopyViewModel } from '@insights/viewmodels';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, SxProps, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { MaterialReactTable } from 'material-react-table';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { accessorForSearch, useInsightsTable } from '../InsightsTable';
import { SectionsInlineList } from '../SectionsInlineList';

const TableStateKey = 'AccountSectionCopy';

export interface AccountSectionsEditionCopyProps {
  sx?: SxProps;
  style?: CSSProperties;
  className?: string;
  viewModel: AccountSectionsEditionCopyViewModel;
}

export const AccountSectionsEditionCopy = observer((props: AccountSectionsEditionCopyProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], viewModel, style, className } = props;
  const strings = localizationService.localizedStrings.insights.views.account;
  const compStrings = localizationService.localizedStrings.insights.components.accounts;

  const table = useInsightsTable(
    TableStateKey,
    viewModel.students,
    '',
    () => [
      {
        header: compStrings.name,
        accessorFn: (row) =>
          accessorForSearch([
            AccountUtils.getDisplayLastFirstName(row.account, ''),
            row.account.email,
            row.account.managedIdentifier
          ]),
        id: 'name',
        Cell: ({ row }) => <Typography>{AccountUtils.getDisplayLastFirstName(row.original.account)}</Typography>
      },
      {
        header: compStrings.sections,
        id: 'sections',
        enableSorting: false,
        enableColumnActions: false,
        size: 300,
        Cell: ({ row }) => (
          <SectionsInlineList
            sections={row.original.sections?.sections ?? []}
            configId={viewModel.configId}
            linkTo={false}
            noneLabel={compStrings.noneLabel}
          />
        )
      }
    ],
    {
      getRowActionButtons: ({ row }) => [
        {
          icon: () => <FileCopyIcon color="action" />,
          tooltip: strings.copySelectedSectionsTooltip,
          onClick: () => {
            if (row.original != null) {
              void viewModel.copy(row.original);
            }
          }
        }
      ]
    }
  );

  return (
    <Box sx={sx} className={className} style={style}>
      <MaterialReactTable table={table} />
    </Box>
  );
});
